import { styled } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ReactComponent as ArrowSvg } from './doublearrow.svg'

const orientationAngle = {
    right: '180',
    down: '270',
    left: '0',
    up: '90',
}

const DoubleArrowSmall = styled(ArrowSvg)(({ theme, color, direction }) => ({
    transform: `rotate(${orientationAngle[direction]}deg)`,
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}))

DoubleArrowSmall.propTypes = {
    direction: PropTypes.oneOf(['left', 'right', 'up', 'down']),
    fill: PropTypes.string,
}

DoubleArrowSmall.defaultProps = {
    color: '#01AE8F',
    direction: 'down',
    fill: 'none',
}

export default DoubleArrowSmall
