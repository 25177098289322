import React from 'react';
import { ReactComponent as ErrorIconSvg } from 'icons/field-error-icon.svg';
import { makeStyles } from '@material-ui/core/styles';
import SelectTimeZone from './SelectTimeZone';
import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  InputLabel,
  Typography,
  Select,
  InputBase,
  IconButton,
  withStyles,
  MenuItem,
} from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  root: props => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    position: 'relative',
    height: 40,
    backgroundColor: theme.palette.common.white,
    border: 'solid 1px #ebebed',
    fontSize: 16,
    padding: '10px 0px',
    '&:focus-within': {
      backgroundColor: theme.palette.common.white,
      borderRadius: 5,
      boxShadow: ` 0 2px 4px 0 rgba(48, 127, 193, 0.15)`,
      border: 'solid 1px #307fc1',
    },
  }),
  input: props => ({
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flex: 1,
    width: props.style && props.style.width ? props.style.width : 'auto',
    '&:before': {
      borderBottom: '0px !important',
      borderColor: 'transparent !important',
    },
  }),
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  label: {
    color: '#b4b4b4',
    padding: '0 10px 10px 10px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}));

const MySelect = withStyles({
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
})(Select);

const MyKeyboardDatePicker = withStyles({
  root: {
    maxWidth: '205px',
    padding: '0px 0px 5px',
  },
})(KeyboardDatePicker);

export default function CustomizedInputBase({
  label,
  name,
  value,
  error,
  single,
  select,
  selectTimeZone,
  disabled,
  date,
  onChange,
  style,
  options = [],
}) {
  const classes = useStyles({ error, style });
  const InputField = (
    <Box
      style={{ border: error && '1px solid #ff0000' }}
      className={classes.root}
    >
      {select && (
        <MySelect
          autoComplete="off"
          onChange={onChange}
          value={value}
          name={name}
          placeholder={label}
          input={<InputBase className={classes.input} />}
          disabled={disabled}
        >
          {options.map(item => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </MySelect>
      )}
      {single && (
        <InputBase
          autoComplete="off"
          className={classes.input}
          name={name}
          value={value ? value : ''}
          onChange={onChange}
          inputProps={{ 'aria-label': 'search google maps' }}
          placeholder={label}
          fullWidth={style.width ? false : true}
          disabled={disabled}
        />
      )}
      {selectTimeZone && (<SelectTimeZone
        onChange={onChange}
        value={value}
      />)}
      {date && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MyKeyboardDatePicker
            autoOk
            disableToolbar
            inputVariant="standard"
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            maxDate={Date.parse('01-01-2200')}
            value={value ? Date.parse(value) : null}
            onChange={onChange}
            InputProps={{ className: classes.input }}
            disabled={disabled}
          />
        </MuiPickersUtilsProvider>
      )}

      {error && (
        <IconButton
          color="primary"
          className={classes.iconButton}
          aria-label="directions"
        >
          <ErrorIconSvg></ErrorIconSvg>
        </IconButton>
      )}
    </Box>
  );

  return (
    <Box style={{ width: style && style.width ? style.width + 'px' : 'auto' }}>
      <Box>
        <InputLabel>
          <Typography variant="subtitle2" className={classes.label}>
            {label}
          </Typography>
        </InputLabel>
      </Box>
      {InputField}
    </Box>
  );
}
