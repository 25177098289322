import { withStyles, Tabs as MuiTabs } from '@material-ui/core'

export default withStyles((theme) => ({
    root: {
        flexGrow: 1,
        borderRadius: '5px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
        display: 'flex',
        minHeight: '40px',
    },
    flexContainer: {
        flexDirection: 'column',
    },
    indicator: {
        backgroundColor: '#307fc1',
        left: 0,
    },
}))(MuiTabs)
