import { Box, makeStyles } from '@material-ui/core'
import { ToggleSwitch } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { mobileScreenWidth, smallScreenWidth } from 'utils'
import { useState, useEffect } from 'react'

export const useStyles = makeStyles({
    pageContainer: {
        padding: '20px',
        [`@media (max-width:${smallScreenWidth})`]: {
            padding: '20px 4px',
        },
    },
    container: {
        borderRadius: ' 5px',
        boxShadow: ' 0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        border: ' solid 1px #ebebed',
        backgroundColor: ' #ffffff',
    },
    digestNotificationContainer: {
        borderRadius: ' 5px',
        boxShadow: ' 0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        border: ' solid 1px #ebebed',
        backgroundColor: ' #ffffff',
        '& .Label': {
            width: '100%',
            [`@media (max-width:${mobileScreenWidth})`]: {
                paddingRight: '14px',
            },
        },
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #d8d8dd',
        width: '100%',
        justifyContent: 'space-between',
        padding: '16px',
        '&:last-child': {
            borderBottom: 'none',
        },
        overflow: 'hidden',
    },
    sectionTitle: {
        color: '#5d6870',
        fontSize: '18px',
        fontWeight: 'bold',
    },
    pageTitleContainer: {
        padding: '0px 16px ',
        color: '#5d6870',
        fontSize: '27px',
        fontWeight: 'bold',
        [`@media (max-width:${smallScreenWidth})`]: {
            fontSize: '16px',
        },
    },
    firstColumn: {
        display: 'flex',
        flexWrap: 'wrap',
        width: 'calc(100% - 120px)',
    },
    label: {
        fontSize: '16px',
        color: '#5d6870',
        paddingRight: '30px',
        [`@media (min-width:${smallScreenWidth})`]: {
            width: '35%',
        },
    },

    description: {
        color: '#848992',
        fontSize: '13px',
        display: 'flex',
        alignItems: 'cenetr',
        justifyContent: 'flex-start',
        [`@media (min-width:${smallScreenWidth})`]: {
            width: '65%',
        },
    },
    switchConatiner: {
        width: '15%',
        minWidth: '115px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    divider: {
        margin: '16px 0px 24px 0px',
    },
    resetButtonContainer: {
        width: '100%',
        margin: '-8px 0px 0px -8px',
        display: 'flex',
        alignItems: 'center',

        [`@media (max-width:${smallScreenWidth})`]: {
            margin: '-8px 0px 0px -8px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            '& >button': {
                marginLeft: 0,
                marginBottom: '10px',
            },
        },

        [`@media (min-width:${smallScreenWidth})`]: {
            '& >button': {
                marginRight: '50px',
            },
        },
    },
    subHeaderContent: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolue',
        left: 0,
    },
    backButton: {
        cursor: 'pointer',
    },
})

export const Row = (props) => {
    const { t } = useTranslation()
    const { label, description, active, loading, onChange, actionComponent } =
        props
    const classes = useStyles()

    const [isActive, setIsActive] = useState(active)
    const handleChange = () => {
        setIsActive((pre) => !pre)
    }
    useEffect(() => {
        if (active !== isActive) {
            onChange(isActive)
        }
    }, [isActive])
    return (
        <Box classes={{ root: classes.row }}>
            <Box className={classes.firstColumn}>
                <Box className="Label" classes={{ root: classes.label }}>
                    {label}
                </Box>

                <Box className={classes.description}>{description}</Box>
            </Box>

            <Box className={classes.switchConatiner}>
                {actionComponent ? (
                    actionComponent
                ) : (
                    <>
                        <ToggleSwitch
                            value={isActive}
                            onChange={handleChange}
                            // disabled={loading} Note: We might need this in future
                        />
                        <span>
                            {isActive
                                ? t('MyIdentity_Yes')
                                : t('MyIdentity_No')}
                        </span>
                    </>
                )}
            </Box>
        </Box>
    )
}
