import React from 'react'
import { Icon } from '@material-ui/core'
import { Divider } from 'packages/eid-ui'
import { LocationIcon } from 'packages/eid-icons'
import GenericCard from './Cards/GenericCard'
import GenericCardHeader from './Cards/GenericCardHeader'
import GenericCardContent from './Cards/GenericCardContent'
import CardFieldGroup from './Cards/CardFieldGroup'
import CardField from './Cards/CardField'
import { useTranslation } from 'react-i18next'

const LocationCard = ({ locationInfo, handleModalOpen, config }) => {
    
    const { t } = useTranslation()
    return (
        <GenericCard
            onClick={config.editable ? handleModalOpen : undefined}
            editable={config.editable}
        >
            <GenericCardHeader
                backgroundColor="#fbfbfd"
                color="#b4b4b4"
                icon={
                    <Icon style={{ margin: '0.75em' }}>
                        <LocationIcon />
                    </Icon>
                }
                heading={t('MyIdentity_Location')}
                height={60}
            />

            <GenericCardContent>
                <CardFieldGroup borderBelow>
                     <CardField
                        label={t('MyIdentity_Country')}
                        value={locationInfo.country}
                    /> 

                    <Divider vertical width={23} />

                    <CardField
                    label={t('MyIdentity_City')}
                    value={locationInfo.city}
                    />
                    

                    
                    <Divider vertical width={23} />
                    <CardField
                    label={t('MyIdentity_Site')}
                    value={locationInfo.siteName}
                    />
                       
                    
                </CardFieldGroup>
                <CardFieldGroup borderBelow>
                    
                    <CardField
                        label={t('MyIdentity_Building')}
                        value={locationInfo.building}
                    />
                    
                    <Divider vertical width={23} /> 

                    <CardField
                    label={t('MyIdentity_Office')}
                    value={locationInfo.office}
                    />
                  

                   <Divider vertical width={23} />

                    <CardField
                    label={t('MyIdentity_PostalCode')}
                    value={locationInfo.postalCode}
                    />
                     
                </CardFieldGroup>
                
                <CardField
                    label={t('MyIdentity_Street')}
                    value={locationInfo.streetAddress}
                    singleField
                />
                
            </GenericCardContent>
        </GenericCard>
    )
}

export default LocationCard
