import React from 'react'
import { Box, styled } from '@material-ui/core'

const filtersColumnWidth = 300

export const TableContainer = styled(({ openFilters, ...rest }) => (
    <Box {...rest} />
))(({ openFilters }) => ({
    position: 'relative',
    marginRight: openFilters ? 20 : '0px',
    transition: `all 0.5s ease-out`,

    width: openFilters ? 'auto' : '100%',
    [`@media (min-width:${1300}px)`]: {
        flex: 1,
    },
}))

export const FiltersColumn = styled(({ width, openFilters, ...rest }) => (
    <Box {...rest} />
))(({ width, openFilters }) => ({
    transition: `all 0.5s ease-out`,
    width: width ? width : `${filtersColumnWidth}px`,
    minHeight: '300px',
    height: '100%',
}))
