import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Avatar as MuiAvatar } from '@material-ui/core'

const spacing = {
    small: 3,
    medium: 4,
    large: 5,
}

const useStyles = makeStyles((theme) => ({
    root: ({ size }) => ({
        width: theme.spacing(spacing[size]),
        height: theme.spacing(spacing[size]),
    }),
}))

const Avatar = ({ size, ...rest }) => {
    const classes = useStyles({ size })
    return <MuiAvatar classes={classes} {...rest} />
}

Avatar.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
}

Avatar.defaultProps = {
    size: 'small',
}

export default Avatar
