import { useEffect, useRef } from 'react'
import { UserManager } from 'oidc-client'
import createUserManager from './createUserManager'
import { useAuthState } from './context'
import { refreshAccessToken } from './refreshAccessToken'

const useUserManager = () => {
    const [{ settings }]: any = useAuthState()

    const userManagerRef = useRef<UserManager>()

    if (userManagerRef.current === undefined) {
        userManagerRef.current = createUserManager(settings)
    }

    const userManager: UserManager = userManagerRef.current

    const [, dispatch]: any = useAuthState()

    const userSignedOutEventHandler = () => {
        window.location.replace(`${window.location.origin}/signOut`)
    }

    const accessTokenExpiringEventHandler = async () => {
        await refreshAccessToken(userManager, dispatch)
    }

    useEffect(() => {
        userManager.events.addUserSignedOut(userSignedOutEventHandler)
        userManager.events.addAccessTokenExpiring(() => {
            accessTokenExpiringEventHandler()
        })

        return () => {
            userManager.events.removeUserSignedOut(userSignedOutEventHandler)
            userManager.events.removeAccessTokenExpiring(
                accessTokenExpiringEventHandler,
            )
        }
    }, [])

    return userManager
}

export default useUserManager
