import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Box, createStyles, makeStyles } from '@material-ui/core'
import { TypeSelector } from './TypeSelector'

const useStyles = makeStyles(
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            height: '40px',
            width: '100%',
            color: '#5d6870',
        },
        lShapedArrow: {
            height: '20px',
            width: '20px',
            borderLeft: '1px solid #aab0b4',
            borderBottom: '1px solid #aab0b4',
        },
    }),
)

const frequencyMap: any = {
    Daily: 1,
    Weekly: 7,
    Monthly: 30,
    Quarterly: 120,
    Yearly: 365,
}

export const TimeRangeSelector = (props: any) => {
    const { noOfDays, handleChange: handleSubmit, loading }: any = props

    const { t } = useTranslation()
    const classes = useStyles()

    const [selectionType, setSelectionTypeType]: any = useState(null)

    useEffect(() => {
        if (noOfDays === 365) {
            setSelectionTypeType('Yearly')
        } else if (noOfDays === 120) {
            setSelectionTypeType('Quarterly')
        } else if (noOfDays === 30) {
            setSelectionTypeType('Monthly')
        } else if (noOfDays === 7) {
            setSelectionTypeType('Weekly')
        } else {
            setSelectionTypeType('Daily')
        }
    }, [noOfDays])

    const handleTypeChange = (value: any) => {
        setSelectionTypeType(value)
        handleSubmit(frequencyMap[value])
    }

    return (
        <Box className={classes.root}>
            <Box display="flex" alignItems="flex-start" height="100%">
                <Box className={classes.lShapedArrow} />
            </Box>
            <Box paddingX="8px">{t('MyIdentity_SendReminder')}</Box>

            <Box width="100px" marginX="8px">
                <TypeSelector
                    value={selectionType}
                    handleChange={handleTypeChange}
                    loading={loading}
                />
            </Box>
        </Box>
    )
}
