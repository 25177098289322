import { styled } from '@material-ui/core'
import { ReactComponent as SuggestedRolesSvg } from './suggested-roles.svg'

const SuggestedRoles = styled(SuggestedRolesSvg)((props) => ({
    '& path': {
        fill: props.color,
    },
}))

SuggestedRoles.defaultProps = {
    color: '#000000',
}

export default SuggestedRoles
