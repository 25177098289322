import { styled } from '@material-ui/core'
import { ReactComponent as CheckSvg } from './check.svg'

const CheckIcon = styled(CheckSvg)((props) => ({
    '& path': {
        stroke: props.color,
        fill: props.color,
    },
}))

CheckIcon.defaultProps = {
    color: '#000000',
}

export default CheckIcon
