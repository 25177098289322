import { FC, forwardRef } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

export interface IAttributeProps {
    className?: string
    orientation?: 'horizontal' | 'vertical'
}

const useStyles = makeStyles(
    createStyles({
        root: {
            display: 'flex',
            padding: '8px 0px',
        },
        vertical: {
            flexDirection: 'column',
        },
        horizontal: {
            flexDirection: 'row',
        },
    }),
)

export const Attribute: FC<IAttributeProps> = forwardRef((props, ref: any) => {
    const classes = useStyles()

    return (
        <div
            className={classNames(classes.root, props.className, {
                [classes.vertical]: props.orientation === 'vertical',
                [classes.horizontal]: props.orientation === 'horizontal',
            })}
            ref={ref}
        >
            {props.children}
        </div>
    )
})

Attribute.defaultProps = {
    orientation: 'vertical',
}

export * from './AttributeLabel'
export * from './AttributeValue'
