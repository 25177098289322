import React from 'react'
import PropTypes from 'prop-types'
import { Divider as MuiDivider } from '@material-ui/core'

const Divider = ({ side }) => {
    const style = {
        position: 'absolute',
        color: '#e8e8e8',
        height: 26,
        bottom: 0,
        top: 0,
        marginTop: 'auto',
        marginBottom: 'auto',
    }
    if (side === 'right') {
        style.right = 0
    } else style.left = 0

    return (
        <MuiDivider orientation="vertical" variant="fullWidth" style={style} />
    )
}

Divider.propTypes = {
    side: PropTypes.oneOf(['left', 'right']),
}

Divider.defaultProps = {
    side: 'right',
}

export default Divider
