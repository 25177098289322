import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Avatar, Tooltip } from '../../eid-ui'

const tooltipStyles = {
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '8px',
    maxWidth: '800px', // some arbitrary width
}

const PersonOption = ({
    option,
    labelProp = 'friendlyName',
    loginProp = 'login',
    imageUrlProp = 'imageThumbUrl',
    cdnUrl,
}) => (
    <Box display="flex" alignItems="center" padding="8px">
        <Box component="span" paddingRight="20px">
            <Avatar src={`${cdnUrl}${option[imageUrlProp]}`} />
        </Box>
        <Tooltip
            color="#5D6870"
            fontColor="#ffffff"
            enterDelay={500}
            enterNextDelay={500}
            tooltipStyles={tooltipStyles}
            title={
                <>
                    <Typography
                        style={{
                            fontSize: '14px',
                            fontWeight: 600,
                        }}
                    >
                        {option[labelProp]}
                    </Typography>
                    <Typography
                        style={{
                            fontSize: '12px',
                        }}
                    >
                        {option[loginProp]}
                    </Typography>
                </>
            }
        >
            <Box>
                <Typography
                    display="block"
                    style={{
                        fontSize: '14px',
                        fontWeight: 600,
                        width: '220px',
                    }}
                    noWrap={true}
                >
                    {option[labelProp]}
                </Typography>
                <Typography
                    display="block"
                    style={{
                        fontSize: '12px',
                        width: '220px',
                    }}
                    noWrap={true}
                >
                    {option[loginProp]}
                </Typography>
            </Box>
        </Tooltip>
    </Box>
)

const LabelOnlyOption = ({ option, labelProp }) => (
    <Typography style={{ wordBreak: 'break-all' }}>
        {option[labelProp]}
    </Typography>
)

const LabelWithSubLabelOption = ({ option, labelProp, subLabelProp }) => (
    <div
        style={{
            display: 'flex',
            padding: '8px 10px',
        }}
    >
        <div>
            <span style={{ color: '#000' }}>{option[labelProp]}</span>
            <div>{option[subLabelProp]}</div>
        </div>
    </div>
)

const optionComponents = {
    label: LabelOnlyOption,
    labelWithSubLabel: LabelWithSubLabelOption,
    person: PersonOption,
}

export default optionComponents
