import React from 'react'
import { isNilOrEmpty } from 'packages/core'
import { EidAdvancedSearch } from 'packages/eid-controls'
import FormsFilter from './FormsFilter'

const AdvancedFilter = ({ forms, tags }) => {
    const props = {}
    if (!isNilOrEmpty(forms)) {
        props.forms = {
            colorCode: forms.colorCode,
            filters: forms.filters,
        }
    }

    return (
        <EidAdvancedSearch
            formsFilter={<FormsFilter {...props.forms} />}
            {...props}
        />
    )
}

export default AdvancedFilter
