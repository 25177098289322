import {
    Box,
    Checkbox as MuiCheckbox,
    CheckboxProps,
    makeStyles,
} from '@material-ui/core'
import { CheckBoxIcon } from '../../eid-icons'
import { CheckedBoxIcon } from '../../eid-icons'
import React, { forwardRef } from 'react'

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
})

const Checkbox = forwardRef<any, CheckboxProps>((props, ref) => {
    const classes = useStyles()

    return (
        <MuiCheckbox
            edge="start"
            color="primary"
            classes={classes}
            icon={
                <Box display="flex" alignItems="center">
                    <CheckBoxIcon />
                </Box>
            }
            checkedIcon={
                <Box display="flex" alignItems="center">
                    <CheckedBoxIcon />
                </Box>
            }
            disableRipple
            disableFocusRipple
            ref={ref}
            {...props}
        />
    )
})

export default Checkbox
