import React from 'react'
import {
    styled,
    Box,
    Typography,
    InputBase,
    withStyles,
} from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { DatePicker } from '@material-ui/pickers'
import { isNilOrEmpty } from 'packages/core'
import { useTranslation } from 'react-i18next'

const Container = styled(Box)({
    height: '40px',
    width: '100%',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
    border: 'solid 1px #d4d4d8',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    padding: '0px 15px',
    position: 'relative',
    cursor: 'pointer',
    '&:focus-within': {
        boxShadow: ` 0 2px 4px 0 rgba(48, 127, 193, 0.15)`,
        borderColor: '#307fc1',
    },
    '&:hover': {
        borderColor: '#307fc1',
    },
})

const StyledInput = withStyles((theme) => ({
    root: {
        width: '100% !important',
        borderRadius: '5px',
        margin: '0px 6px',
        'label + &': {
            marginTop: theme.spacing(3),
        },
        cursor: 'pointer',
    },
    input: {
        position: 'relative',
        backgroundColor: '#ffffff !important',
        boxShadow: 'none',
        border: 'none',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
        cursor: 'pointer',
        fontSize: 16,
        color: '#5d6870',
    },

    focused: {
        backgroundColor: theme.palette.common.white,
    },
}))(InputBase)

const InputWithLabel = (props) => {
    const {
        label,
        value,
        onClick,
        helperText,
        showIcon = true,
        handleClear,
    } = props

    return (
        <Box>
            <Typography
                style={{
                    color: '#b4b4b4',
                    fontSize: '11px',
                    textTransform: 'uppercase',
                    minWidth: '60px',
                    padding: '10px 10px 6px 8px',
                }}
            >
                {label}
            </Typography>

            <Container
                padding="0px 5px !important"
                boxShadow="none !important"
                onClick={onClick}
            >
                {showIcon && (
                    <Box
                        style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0 5px',
                        }}
                    >
                        <Icon name="Calendar" color="#959598" />
                    </Box>
                )}
                <StyledInput value={value} placeholder={helperText} />
                {!isNilOrEmpty(value) && (
                    <Box
                        style={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            padding: '0 9px',
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                            handleClear()
                        }}
                    >
                        <Icon name="Close" color="#959598" />
                    </Box>
                )}
            </Container>
        </Box>
    )
}

const InputComponent = (props) => {
    const { label, value, onClick } = props
    return (
        <Container onClick={onClick}>
            <Box>
                <Typography
                    style={{
                        color: '#b4b4b4',
                        fontSize: '10px',
                        textTransform: 'uppercase',
                        minWidth: '60px',
                    }}
                >
                    {label}
                </Typography>
            </Box>

            <StyledInput value={value} />

            <Box
                style={{
                    //position: 'absolute',
                    //right: 0,
                    //top: 0,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    paddingRight: '15px',
                }}
            >
                <Icon name="Tasks" color="#D8D8DD" />
            </Box>
        </Container>
    )
}

const DateTimeFilter = ({
    label,
    showLabelWithin,
    value,
    placeholder,
    onChange,
    ...rest
}) => {
    const { t } = useTranslation()

    return (
        <DatePicker
            label={label}
            format="D MMMM YYYY"
            TextFieldComponent={
                showLabelWithin ? InputComponent : InputWithLabel
            }
            onChange={onChange}
            value={value}
            okLabel={t('MyIdentity_Ok')}
            cancelLabel={t('MyIdentity_Cancel')}
            helperText={placeholder}
            {...rest}
        />
    )
}

export default DateTimeFilter
