import React from 'react'
import PropTypes from 'prop-types'
import { Link, Box } from '@material-ui/core'
import { CardViewIcon, ListViewIcon } from '../../eid-icons'

const ViewsIndicator = ({ view, onCardViewClick, onListViewClick }) => {
    const getColor = (active) => {
        return active ? '#307fc1' : '#959598'
    }
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Link component="button" onClick={onListViewClick}>
                <Box padding="0 8px">
                    <ListViewIcon color={getColor(view === 'list')} />
                </Box>
            </Link>
            <Link component="button" onClick={onCardViewClick}>
                <Box padding="0 8px">
                    <CardViewIcon color={getColor(view === 'card')} />
                </Box>
            </Link>
        </div>
    )
}

ViewsIndicator.propTypes = {
    view: PropTypes.oneOf(['list', 'card']),
    onCardViewClick: PropTypes.func.isRequired,
    onListViewClick: PropTypes.func.isRequired,
}

ViewsIndicator.defaultProps = {
    view: 'list',
}

export default ViewsIndicator
