import React, { useRef, useState } from 'react'
import { Link, makeStyles, Popover } from '@material-ui/core'
import { Backdrop } from '../Backdrop'

const centerStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const useStyles = makeStyles(() => ({
    dropdownAnchor: ({ anchorPosition }) => ({
        position: 'relative',
        top: '54px',
        ...anchorPosition,
    }),
}))

const PopupMenu = ({
    displayIcon,
    style,
    onOpen,
    onClose,
    handleItemSelection,
    menuHeight,
    children,
    anchorPosition,
    showCone = true,
    ...rest
}) => {
    const [open, setOpen] = useState(false)
    const classes = useStyles({ anchorPosition })
    const anchorRef = useRef(null)

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
        onClose && onClose()
    }

    return (
        <>
            <div style={{ position: 'relative', ...centerStyles, ...style }}>
                <Link
                    underline="none"
                    component="button"
                    onClick={() => {
                        onOpen && onOpen()
                        setOpen((prev) => !prev)
                    }}
                    style={{ ...centerStyles }}
                >
                    {displayIcon}
                </Link>
                <span ref={anchorRef} className={classes.dropdownAnchor} />
            </div>

            <Popover
                open={open}
                anchorEl={anchorRef.current}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                disableScrollLock={true}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                BackdropComponent={Backdrop}
            >
                {children}
            </Popover>
        </>
    )
}

export default PopupMenu
