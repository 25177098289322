import { Box, styled, makeStyles } from '@material-ui/core'
import { mobileScreenWidth } from 'utils'

export const styles = {
    peopleSearch: {
        '& > div': {
            border: 'solid 1px #ebebed',
            '&:hover': {
                borderColor: '#307fc1',
            },
            padding: '1.5px !important',
            '& > div': {
                right: '0px !important',
                pointerEvents: 'none',
            },
            '& > input': {
                height: '35px',
                cursor: 'pointer',
                width: 'auto !important',
            },
        },
    },

    cancelButton: {
        backgroundColor: '#ffffff !important',
        color: '#307fc1 !important',
        boxShadow: 'none',
        border: '1px solid #307fc1',
    },
}

export const useStyles = makeStyles(() => ({
    formLabelMargin: {
        marginRight: '30px',
    },

    radio: {
        padding: '0px 12px 0px 0px',
    },

    checked: {
        '&, & + $label': {
            color: '#000000',
        },
    },
    label: {
        color: '#767676',
    },

    dateFilterContainer: {
        [`@media (max-width:${mobileScreenWidth})`]: {
            width: '100%',
        },
    },
}))

export const ItemContainer = styled(Box)({
    padding: '15px 0px',
    [`@media (max-width:${mobileScreenWidth})`]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
})

export const reducers = (state, { type, payload }) => {
    switch (type) {
        case 'SET_START_DATE':
            return { ...state, startDate: payload }

        case 'SET_END_DATE':
            return { ...state, endDate: payload }

        case 'SET_DELEGATE_FOREVER':
            return {
                ...state,
                endDate: null,
                activeOnlyIfOutOfOffice: false,
                delegateForever: payload,
            }

        case 'SET_OUT_OF_OFFICE':
            return {
                ...state,
                delegateForever: false,
                activeOnlyIfOutOfOffice: payload,
            }

        case 'CLEAR_STATE':
            return {
                ...payload,
            }
        default:
            return state
    }
}
