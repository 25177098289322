import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
    List,
    ListItem as MuiListItem,
    withStyles,
    styled,
} from '@material-ui/core'

const ListItem = withStyles({
    root: {
        padding: '5px 15px',
        margin: '0px 0px',
        border: 'solid 1px transparent',

        '&:hover': {
            backgroundColor: '#f8f8f8',
            borderRadius: '5px',
            border: 'solid 1px #d6d6d6',
        },
    },
})(MuiListItem)

const LinkWrapper = styled(({ active, ...rest }) => <RouterLink {...rest} />)(
    ({ active, styles }) => ({
        textDecoration: 'none',
        color: active ? '#000000' : '#919193',
        fontSize: '12px',
        textTransform: 'capitalize',
        ...styles,
    }),
)

const ContentList = ({ data, handleMenuClose, paddingTop }) => {
    return (
        <div style={{ paddingTop: paddingTop ? paddingTop : '25px' }}>
            <List>
                {data.map((item, index) => {
                    return (
                        <LinkWrapper
                            key={`${item.name}` + index}
                            active={item.active}
                            to={item.route}
                            onClick={handleMenuClose}
                        >
                            <ListItem key={item.name}>{item.name}</ListItem>
                            {item.submenu?.length > 0 && (
                                <div style={{ marginLeft: '15px' }}>
                                    <ContentList
                                        data={item.submenu}
                                        handleMenuClose={handleMenuClose}
                                        paddingTop={'0px'}
                                    />
                                </div>
                            )}
                        </LinkWrapper>
                    )
                })}
            </List>
        </div>
    )
}

export default ContentList
