import React, { FC } from 'react'
import { styled, Box, createStyles } from '@material-ui/core'
import { Icon } from '../../eid-icons'
import { IRiskIndicatorProps } from '../types'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

const useStyles = makeStyles(
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '11px',
            opacity: 0.76,
            overflow: 'hidden !important',
            overflowX: 'hidden',
        },
        riskChip: ({ colors }: any) => ({
            backgroundColor: colors && colors.backgroundColor,
            color: colors && colors.color,
            border:
                colors && colors.border
                    ? colors.border
                    : ` solid 1px ${colors && colors.color}`,
            borderRadius: '5px',
            minWidth: '80px',
            padding: '4px',
            opacity: 1,
        }),
    }),
)

const NoRiskIcon = styled('div')({
    minWidth: '8px',
    minHeight: '2px',
    backgroundColor: '#029435',
})

const risks = {
    Low: {
        colors: {
            backgroundColor: 'rgb(2, 148,53,0.07)',
            color: '#029435',
        },
        filterColors: {
            color: '#029435',
        },
        icon: <NoRiskIcon />,
        filterIcon: <NoRiskIcon />,
    },
    Medium: {
        colors: {
            backgroundColor: 'rgba(252, 165, 0, 0.07)',
            color: '#fca500',
        },
        filterColors: {
            color: '#fca500',
        },
        icon: <Icon name="LowRisk" color="#fca500" />,
        filterIcon: <Icon name="LowRisk" color="#fca500" />,
    },
    High: {
        colors: {
            backgroundColor: '#rgba(208, 2, 27, 0.2)',
            color: '#d0021b',
        },
        filterColors: {
            color: '#d0021b',
        },
        icon: <Icon name="HighRisk" color="#d0021b" />,
        filterIcon: <Icon name="HighRisk" color="#d0021b" />,
    },
    Critical: {
        colors: {
            backgroundColor: '#d0021b',
            color: '#fff',
            border: 'none',
        },
        filterColors: {
            color: '#d0021b',
        },
        icon: <Icon name="CriticalRisk" color="#fff" />,
        filterIcon: <Icon name="CriticalRisk" color="#d0021b" />,
    },
}

export const RiskIndicator: FC<IRiskIndicatorProps> = (props) => {
    const risk = risks[props.variant]
    const { forFitler } = props

    const classes = useStyles({
        colors: forFitler ? risk?.filterColors : risk?.colors,
    })

    return (
        <div
            className={classNames(classes.root, props.className, {
                [classes.riskChip]: !props.renderAsText,
            })}
        >
            <Box display="flex">
                {forFitler ? risk?.filterIcon : risk?.icon}
            </Box>
            <Box
                marginLeft="7px"
                fontSize="11px"
                lineHeight={1.2}
                color={
                    forFitler ? risk?.filterColors.color : risk?.colors.color
                }
            >
                {props.label}
            </Box>
        </div>
    )
}
