import { useEffect } from 'react'
import { useAuthState } from '../../core/auth/context'
import { Loader } from '../../eid-ui'

const getIframeStyles = (height?: string) => ({
    border: 'none',
    width: '100%',
    height: height ? height : '78vh',
    margin: 0,
    padding: 0,
})

export type WorkflowIFrameProps = {
    baseEidUrl: string
    workflowName: string
    workflowParams?: string[]
    onComplete: () => void
    height?: string
}

export const WorkflowIFrame = (props: WorkflowIFrameProps) => {
    const [{ isWorkflowIRenderable }, dispatch]: any = useAuthState()

    useEffect(() => {
        const eventListener = (e: WindowEventMap['message']) => {
            if (e.origin === props.baseEidUrl) {
                if (e.data.type === 'WorkflowEnded') {
                    setTimeout(props.onComplete, 3000)
                } else if (e.data.type === 'WorkflowCanceled') {
                    props.onComplete()
                }
            }
        }

        window.addEventListener('message', eventListener)

        return () => {
            window.removeEventListener('message', eventListener)
        }
    }, [])

    if (!isWorkflowIRenderable) {
        return (
            <div style={{ width: '100%' }}>
                <Loader />
            </div>
        )
    }

    const fullWorkflowUrl = constructApprovalWorkflowUrl(
        props.baseEidUrl,
        props.workflowName,
        props.workflowParams,
    )

    return (
        <iframe
            title={`EmpowerID ${props.workflowName}`}
            style={getIframeStyles(props.height)}
            src={fullWorkflowUrl}
        />
    )
}

const constructApprovalWorkflowUrl = (
    baseEidUrl: string,
    workflowName: string,
    workflowParams?: string[],
) => {
    let queryParams = ['popupNav=true']

    if (workflowParams) {
        queryParams = queryParams.concat(workflowParams)
    }

    const queryString = queryParams.join('&')
    return `${baseEidUrl}/ui?hideHeader=true&wfMsContext=true&wfMsUrl=${window.location.origin}#w/${workflowName}?${queryString}`
}
