import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, makeStyles } from '@material-ui/core'
import WarningPrompt from '../WarningPrompt'

const useStyles = makeStyles({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
    },
})

const WarningModal = ({
    title,
    description,
    open,
    proceed,
    proceeding,
    cancel,
    yesLabel,
    noLabel,
}) => {
    const classes = useStyles()

    return (
        <Dialog
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={cancel}
            closeAfterTransition
        >
            <WarningPrompt
                title={title}
                description={description}
                cancel={cancel}
                proceed={proceed}
                proceeding={proceeding}
                yesLabel={yesLabel}
                noLabel={noLabel}
            />
        </Dialog>
    )
}

WarningModal.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    proceed: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
}

export default WarningModal
