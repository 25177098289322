import React from 'react'
import { isNilOrEmpty } from 'packages/core'
import { EidAutocomplete } from 'packages/eid-controls'
import config from 'config'
import { Icon } from 'packages/eid-icons'

const styles = {
    '& > div': {
        boxShadow: 'none !important',
        borderColor: '#d4d4d8 !important',

        '&:hover': {
            borderColor: '#307fc1 !important',
        },
    },
}

const getOptionComponent = (optionConfig) => {
    return EidAutocomplete.getOptionComponent({
        ...optionConfig,
        cdnUrl: config.BASE_EID_URL,
    })
}

const AutocompleteFilter = ({
    value,
    onChange,
    optionConfig,
    selectedValueIndicatorProp,
    requireAccess,
    queryParam,
    queryParams,
    basedOn,
    url,
    supportsServerSideSearch = true,
    state,
    ...rest
}) => {
    let urlToUse
    let queryParamsToUse = {
        ...queryParams,
    }
    if (!isNilOrEmpty(basedOn)) {
        const basedOnValue = state[basedOn.contextProp]

        switch (basedOn.type) {
            case 'required':
                if (!isNilOrEmpty(basedOnValue)) {
                    queryParamsToUse[basedOn.queryParamName] =
                        basedOnValue[basedOn.valueProp]
                    urlToUse = url
                }
                break
            case 'optional':
            default:
                if (!isNilOrEmpty(basedOnValue)) {
                    queryParamsToUse[basedOn.queryParamName] =
                        basedOnValue[basedOn.valueProp]
                    urlToUse = url
                } else {
                    urlToUse = url
                }
                break
        }
    } else {
        urlToUse = url
    }

    const clearIcon = (
        <Icon name="Close" color="#959598" onClick={() => onChange(null)} />
    )

    return (
        <EidAutocomplete
            getOptionLabel={(option) => option[optionConfig.labelProp]}
            optionComponent={getOptionComponent(optionConfig)}
            value={value}
            onChange={(_, value) => {
                const valueToDispatch = value ? value : null
                onChange(valueToDispatch)
            }}
            shouldTriggerApiCall={!isNilOrEmpty(urlToUse)}
            url={urlToUse}
            queryParams={queryParamsToUse}
            filterOptions={
                supportsServerSideSearch ? (options) => options : undefined
            }
            supportsServerSideSearch={supportsServerSideSearch}
            rootStyleProps={styles}
            clearIcon={!isNilOrEmpty(value) && clearIcon}
            {...rest}
        />
    )
}

export default AutocompleteFilter
