import React from 'react'
import HeaderSearch from '../HeaderSearch'
import {
    Toolbar as MuiToolbar,
    Container,
    Box,
    Divider,
    styled,
    makeStyles,
} from '@material-ui/core'

const Toolbar = (props) => {
    const classes = makeStyles(() => ({
        root: {
            alignItems: 'stretch',
        },
    }))()
    return <MuiToolbar disableGutters={false} classes={classes} {...props} />
}

const HeaderContentContainer = styled(Box)({
    display: 'flex',
    minHeight: 'inherit',
    alignItems: 'center',
})

const Header = ({
    mainLogo,
    appLogo,
    rightSideContent,
    genericSearch,
    searchStyleData,
    searchKey,
    handleSearch,
    searchPlaceholder,
    searchComponent,
    mainHeaderContentPadding,
}) => {
    return (
        <Container maxWidth="xl">
            <Toolbar style={{ padding: 0 }}>
                <HeaderContentContainer
                    flex="5%"
                    justifyContent="center"
                    position="relative"
                >
                    <Box
                        width="60px"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {mainLogo}
                        <Divider
                            orientation="vertical"
                            variant="fullWidth"
                            style={{
                                position: 'absolute',
                                color: '#e8e8e8',
                                height: 26,
                                right: 0,
                            }}
                        />
                    </Box>
                </HeaderContentContainer>
                <HeaderContentContainer
                    flex="95%"
                    padding={mainHeaderContentPadding}
                >
                    <Box flex="17%" display="flex">
                        {appLogo}
                    </Box>
                    <Box flex="48%">
                        {genericSearch ? (
                            <HeaderSearch
                                styleData={searchStyleData}
                                value={searchKey}
                                handleSearch={handleSearch}
                                placeholder={searchPlaceholder}
                            />
                        ) : (
                            searchComponent
                        )}
                    </Box>
                    <Box flex="35%" display="flex" justifyContent="flex-end">
                        {rightSideContent.map((item, key) => (
                            <span key={key}>{item}</span>
                        ))}
                    </Box>
                </HeaderContentContainer>
            </Toolbar>
        </Container>
    )
}

export default Header
