import {
    isNilOrEmpty,
    useGetPagesAccess,
    useGetControlsAccess,
} from 'packages/core'

import { IConfigurationOption } from 'models'
import { whitePagesSubOptions } from './whitePagesSubOptions'
import appConfig from 'config'

export const configuration: IConfigurationOption[] = [
    {
        name: 'MyIdentity_WhitePages',
        title: 'MyIdentity_WhitePages',
        route: `${appConfig.APP_SUBPATH}/whitePages`,
        icon: 'WhitePages',
        requireAccess: {
            page: 'MyIdentity-WhitePages-Page',
        },
        tabbedOptions: whitePagesSubOptions,
    },
    {
        name: 'MyIdentity_PermanentDelegations',
        title: 'MyIdentity_PermanentDelegations',
        route: `${appConfig.APP_SUBPATH}/permanentDelegations`,
        icon: 'TeamChat',
        requireAccess: {
            page: 'MyIdentity-PermanentDelegations-Page',
        },
    },
    {
        name: 'MyIdentity_Workflowss',
        title: 'MyIdentity_Workflows',
        route: `${appConfig.APP_SUBPATH}/workflows`,
        icon: 'Workflows',
        requireAccess: {
            page: 'MyIdentity-Workflows-Page',
        },
    },
    {
        name: 'MyIdentity_EmailNotifications',
        title: 'MyIdentity_EmailNotifications',
        route: `${appConfig.APP_SUBPATH}/notifications`,
        icon: 'EmailNotification',
        requireAccess: {
            page: 'MyIdentity-NotificationsSettings-Page',
        },
    },
    {
        name: 'ViewProfile',
        title: 'MyIdentity_ViewProfile',
        route: `${appConfig.APP_SUBPATH}/myProfile`,
        icon: 'ViewProfile',
    },
]

export const useConfiguration = () => {
    const pages = useGetPagesAccess()
    const controls = useGetControlsAccess().map((c: any) => c.name)

    let filteredOptions = configuration.filter(
        (option) =>
            isNilOrEmpty(option.requireAccess?.page) ||
            pages.findIndex((x: any) => x.name === option.requireAccess?.page) >
                -1,
    )

    filteredOptions = filteredOptions.map((option) => {
        if (option.tabbedOptions) {
            option.tabbedOptions = option.tabbedOptions.filter(
                (option) =>
                    isNilOrEmpty(option.requireAccess?.page) ||
                    pages.findIndex(
                        (x: any) => x.name === option.requireAccess?.page,
                    ) > -1,
            )
        }
        return option
    })

    const hasAccessToNotificationSettings = pages
        .map((p: any) => p.name)
        .includes('MyIdentity-NotificationsSettings-Page')

    const hasAccessToDetailedNotificationSettings = controls.includes(
        'MyIdentity-DetailedNotificationsSettings-Control',
    )

    const canSeeDigestEmailNotificationSettings = controls.includes(
        'MyIdentity-DigestEmailNotificationsSettings-Control',
    )

    const canSeeFunctionsAndRisksNotificationSettings = controls.includes(
        'MyIdentity-FunctionsAndRisksNotificationsSettings-Control',
    )

    return {
        dropDownOptions: filteredOptions,
        hasAccessToNotificationSettings,
        hasAccessToDetailedNotificationSettings,
        canSeeDigestEmailNotificationSettings,
        canSeeFunctionsAndRisksNotificationSettings,
    }
}
