import React from 'react'
import { makeStyles, Fab } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        margin: theme.spacing(1),
        textTransform: 'capitalize',
        fontSize: '14px',
        height: '31px !important',
        margin: '8px 0px !important',
        width: '100% !important',
        backgroundColor: '#f7f8fa ',
        color: '#ff4d5f',
        boxShadow: 'none',
        overflowWrap: 'anywhere',
        lineHeight: 1.2,
        border: '1px solid #ff4d5f',
        '& svg': {
            position: 'absolute',
            left: '12px',
            color: '#ff4d5f',
            top: '50%',
            transform: ' translateY(-50%) rotate(-180deg)',
        },
        '&:hover': {
            backgroundColor: '#f7f8fa',
        },

        ...props.styles,
    }),

    disabled: {
        backgroundColor: '#f7f8fa !important',
        border: '1px solid #8b909a !important',

        color: '#8b909a !important',
        '& svg': {
            color: '#8b909a !important',
        },
    },
}))
const RemoveAllButton = ({
    styles,
    active,
    iconName,
    startIcon,
    children,
    activeStyle,
    ...rest
}) => {
    const classes = useStyles({ styles })
    return (
        <Fab variant="extended" size="small" classes={classes} {...rest}>
            {startIcon && (
                <Icon
                    name={iconName ? iconName : 'SmallArrow'}
                    color={styles?.color ? styles.color : '#ffffff'}
                    className={classes.icon}
                ></Icon>
            )}
            {children}

            {!startIcon && (
                <Icon
                    name={iconName ? iconName : 'SmallArrow'}
                    color={styles?.color ? styles.color : '#ffffff'}
                    className={classes.icon}
                ></Icon>
            )}
        </Fab>
    )
}

export default RemoveAllButton
