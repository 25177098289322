import React from 'react'
import { withStyles, Link, Badge, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = {
    actionLink: ({ active, height }) => ({
        height: height,
        border: active ? 'solid 2px #307fc1' : 'solid 2px #d2d2d9',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
        cursor: 'pointer',
        position: 'relative',
        '& span.MuiBadge-root': {
            position: 'absolute',
            width: '100%',
            top: '5px',
            right: '5px',
            transform: 'none',
        },
        '& span.MuiBadge-badge': {
            backgroundColor: !active ? '#848992' : '#307fc1',
            transform: 'none',
        },
        '&:hover': {
            border: 'solid 2px #307fc1',
            '& span.MuiBadge-badge': {
                backgroundColor: '#307fc1',
            },
            '& svg': {
                color: '#848992',
            },
        },
    }),
    label: ({ showLabelWithin }) => ({
        wordBreak: 'break-word',
        marginTop: '15px',
        fontSize: showLabelWithin ? '16px' : '20px',
        maxWidth: showLabelWithin && '100%',
        padding: '0px 6px',
        color: '#848992',
        textAlign: 'center',
        lineHeight: 'normal',
    }),
}

const LinkTile = withStyles(styles)(
    ({ classes, icon, onClick, label, showLabelWithin, count }) => {
        return (
            <>
                <Link
                    className={classes.actionLink}
                    underline="none"
                    onClick={onClick}
                >
                    {count && (
                        <Badge
                            color="primary"
                            max={9999}
                            badgeContent={count}
                        />
                    )}
                    {icon}
                    {showLabelWithin && (
                        <Typography variant="body1" className={classes.label}>
                            {label}
                        </Typography>
                    )}
                </Link>
                {!showLabelWithin && (
                    <Typography variant="body1" className={classes.label}>
                        {label}
                    </Typography>
                )}
            </>
        )
    },
)

LinkTile.propTypes = {
    active: PropTypes.bool,
    showLabelWithin: PropTypes.bool,
    icon: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
}

LinkTile.defaultProps = {
    active: false,
    showLabelWithin: false,
    height: '180px',
}

export default LinkTile
