import { useAuthState } from 'packages/core'

export default () => {
    const [
        {
            access: { pages },
        },
    ] = useAuthState()

    return pages
}
