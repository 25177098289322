import React, { useState } from 'react'
import {
    Box,
    createStyles,
    InputBase,
    MenuItem,
    withStyles,
    Select,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const options = [
    { value: 'ooOffice', label: 'MyIdentity_OoO' },
    { value: 'inOffice', label: 'MyIdentity_InOffice' },
]

const StyledInput = withStyles((theme) =>
    createStyles({
        root: {
            marginTop:'30px',
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.1)',
            border: 'solid 1px #d8d8dd',
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            fontSize: 16,
            padding: '6px 8px 6px 12px',
            minWidth: '120px',
            lineHeight:'1.2',
            color:'#3b454d',

            '&:focus': {
                borderRadius: 4,
                borderColor: 'transparent',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
        },
    }),
)(InputBase)

export const OutOfOffceSelector = (props: any) => {
    const { t } = useTranslation()

    const { handleChange, value }: any = props

    const [open, setOpen] = useState(false)

    const handleClose = (event: any) => {
        setOpen(false)
    }

    const handleOpen = (event: any) => {
        setOpen(true)
    }

    const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        handleChange(event.target.value)
    }

    const menuProps: any = {
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    }

    return (
        <>

            <Select
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={value}
                onChange={onChange}
                MenuProps={{
                    ...menuProps,
                }}
                input={<StyledInput />}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {t(option.label)}
                    </MenuItem>
                ))}
            </Select>


        </>
    )
}
