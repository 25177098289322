import { Card, makeStyles } from '@material-ui/core/'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        borderRadius: '5px',
        backgroundColor: '#ffffff',
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
        border: 'none',
    },
    disabled: {
        pointerEvents: 'none',
        opacity: 0.66,
    },
}))

const CustomCard = ({ children, disabled }) => {
    const classes = useStyles()

    return (
        <Card
            className={classNames(classes.root, {
                [classes.disabled]: disabled,
            })}
        >
            {children}
        </Card>
    )
}

export default CustomCard
