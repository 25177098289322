import React from 'react';
import PropTypes from 'prop-types';
import { Box, Fab, makeStyles, withStyles } from '@material-ui/core';

import { ReactComponent as FillSvg } from 'images/fill-1.svg';
import { Spinner } from 'packages/eid-ui';

const activeColor = '#307fc1';
const whiteColor = '#ffffff';
const disabledColor = '#8b909a';

const useStyles = makeStyles(theme => ({
  normal: props => ({
    width: 'auto',
    height: props.height,
    borderRadius: '30px',
    border: `solid 1px ${activeColor}`,
    backgroundColor: 'transparent',
    boxShadow: '0 0 0 0',
    color: activeColor,
    textTransform: 'capitalize !important',

    '&:hover': {
      backgroundColor: `${activeColor} !important`,
      boxShadow: '0 5px 5px -2px rgba(48, 127, 193, 0.55) !important',
      color: 'white !important',
      '& path': {
        fill: whiteColor,
      },
    },
  }),
  active: props => ({
    width: 'auto',
    height: props.height,
    borderRadius: '30px',
    border: `solid 1px ${activeColor}`,
    backgroundColor: activeColor,
    boxShadow: '0 0 0 0',
    color: whiteColor,
    textTransform: 'capitalize !important',
    boxShadow: '0 5px 5px -2px rgba(48, 127, 193, 0.55)',
    '& path': {
      fill: whiteColor,
    },
    '&:hover': {
      backgroundColor: activeColor,
    },
  }),
  loader: {
    marginRight: '7px',
  },
}));

const MyFab = withStyles({
  root: {},
  disabled: {
    backgroundColor: 'transparent !important',
    color: `${disabledColor} !important`,
    borderColor: disabledColor,
    '& path': {
      fill: disabledColor,
    },
  },
})(Fab);

const CustomButton = ({
  onClick,
  label,
  width,
  height,
  active,
  loading,
  disabled,
}) => {
  const classes = useStyles({ width, height });
  return (
    <MyFab
      size="medium"
      classes={{ root: active ? classes.active : classes.normal }}
      onClick={onClick}
      disabled={disabled}
    >
      <Box
        style={{ padding: '0 15px' }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {loading ? (
          <>
            <Box className={classes.loader}>
              <Spinner size={0.8} />
            </Box>
            {label}
          </>
        ) : (
          label
        )}
        {!loading && (
          <Box padding="0px 5px 0 5px" display="flex" alignItems="center">
            <FillSvg></FillSvg>
          </Box>
        )}
      </Box>
    </MyFab>
  );
};
CustomButton.propTypes = {
  label: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
};

CustomButton.defaultProps = {
  label: 'Custom Button',
  height: 33,
};

export default CustomButton;
