export function encodeQueryParams(queryData) {
    const encodedQueryData = []
    for (var d in queryData) {
        if (
            queryData[d] !== undefined &&
            queryData[d] !== null &&
            queryData[d] !== ''
        ) {
            encodedQueryData.push(
                encodeURIComponent(d) + '=' + encodeURIComponent(queryData[d]),
            )
        }
    }
    return encodedQueryData.join('&')
}

export function buildUrlWithQueryParams(url, queryData) {
    const queryString = encodeQueryParams(queryData)
    return url + (queryString ? `?${queryString}` : '')
}

export function formatDate(date, format) {
    switch (format) {
        case 'MM/dd/yyyy': {
            return (
                date.getMonth() +
                1 +
                '/' +
                date.getDate() +
                '/' +
                date.getFullYear()
            )
        }
        default:
            return date
    }
}

export function MIDPathToImage(cdnUrl, pathToImage) {
    return cdnUrl + pathToImage + '&timeStamp=' + Date.now()
}
