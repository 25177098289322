export const config = [
    {
        name: 'basicInfo',
        type: 'profile',
        editable: true,
        fields: [
            {
                name: 'firstName',
                type: 'InputField',
                label: 'MyIdentity_FirstName',
                required: false,
                style: {
                    fontWeight: 'bold',
                    color: '',
                    fontSize: '24px',
                    textDecoration: '',
                    width: 180,
                },
            },
            {
                name: 'lastName',
                type: 'InputField',
                label: 'MyIdentity_LastName',
                required: false,
                style: {
                    fontWeight: '',
                    color: '',
                    textDecoration: '',
                    fontSize: '24px',
                    width: 180,
                },
            },
            {
                name: 'jobTitle',
                type: 'InputField',
                label: 'MyIdentity_JobTitle',
                required: false,
                style: { width: 180 },
            },
            {
                name: 'department',
                type: 'SelectField',
                label: 'MyIdentity_Department',
                required: false,
                disabled: true,
                options: ['Marketing', 'HR', 'Finance', 'IT'],
                style: { width: 180 },
            },
        ],
    },

    {
        name: 'contactInfo',
        type: 'general',
        editable: true,
        fields: [
            {
                name: 'email',
                type: 'InputField',
                label: 'MyIdentity_Email',
                required: false,
                cardFieldType: 'singleField',
                borderBelow: true,
                link: true,
                style: {
                    textDecoration: '',
                    fontSize: '',
                    width: 180,
                },
            },
            {
                name: 'contactEmail',
                type: 'InputField',
                label: 'MyIdentity_EmailContact',
                required: false,
                cardFieldType: 'singleField',
                borderBelow: true,
                style: {
                    textDecoration: 'underline',
                    fontSize: '',
                    width: 180,
                },
            },
            {
                name: 'telephone',
                type: 'InputField',
                label: 'MyIdentity_Telephone',
                required: false,
                cardFieldType: 'inLineField',
                divider: true,
                style: { width: 180 },
            },
            {
                name: 'mobilePhone',
                type: 'InputField',
                label: 'MyIdentity_MobilePhone',
                required: false,
                cardFieldType: 'inLineField',
                divider: false,
                style: { width: 180 },
            },
        ],
    },
    {
        name: 'generalInfo',
        type: 'general',
        editable: true,
        fields: [
            {
                name: 'firstName',
                type: 'InputField',
                label: 'MyIdentity_FirstName',
                required: false,
                style: { fontWeight: 'bold', fontSize: '', width: 180 },
            },
            {
                name: 'lastName',
                type: 'InputField',
                label: 'MyIdentity_LastName',
                required: false,
                style: { fontWeight: 'bold', fontSize: '', width: 180 },
            },
            {
                name: 'corporateId',
                type: 'InputField',
                label: 'MyIdentity_CorporateId',
                required: false,
                disabled: true,
                style: { width: 180 },
            },
            {
                name: 'globalTimeZone',
                type: 'SelectTimeZoneField',
                label:'MyIdentity_YourTimeZone',
                required: 'false',
            },
            {   
                name: 'globalTimeZoneID',
            }
        ],
    },
    {
        name: 'locationInfo',
        type: 'general',
        editable: true,
        fields: [
            {
                name: 'country',
                type: 'InputField',
                label: 'MyIdentity_Country',
                required: false,
                disabled: true,
                style: { width: 260 },
            },
            {
                name: 'city',
                type: 'InputField',
                label: 'MyIdentity_City',
                required: false,
                disabled: true,
                style: { width: 210 },
            },
            {
                name: 'siteName',
                type: 'InputField',
                label: 'MyIdentity_Site',
                disabled: true,
                required: false,
                style: { width: 180 },
            },
            {
                name: 'building',
                type: 'InputField',
                label: 'MyIdentity_Building',
                required: false,
                disabled: true,
                style: { width: 180 },
            },
            {
                name: 'office',
                type: 'InputField',
                label: 'MyIdentity_Office',
                required: false,
                style: { width: 180 },
            },
            {
                name: 'streetAddress',
                type: 'InputField',
                label: 'MyIdentity_Street',
                required: false,
                style: { width: 290 },
            },
            {
                name: 'postalCode',
                type: 'InputField',
                label: 'MyIdentity_PostalCode',
                required: false,
                style: { width: 130 },
            },
        ],
    },
    {
        name: 'orgInfo',
        type: 'general',
        editable: true,
        fields: [
            {
                name: 'company',
                type: 'InputField',
                label: 'MyIdentity_Company',
                required: false,
                disabled: true,
                style: { fontWeight: 'bold', width: 230 },
            },
            {
                name: 'jobTitle',
                type: 'InputField',
                label: 'MyIdentity_JobTitle',
                required: false,
                style: { width: 180 },
            },
            {
                name: 'department',
                type: 'InputField',
                label: 'MyIdentity_Department',
                required: false,
                disabled: true,
                cardFieldType: 'inLineField',
                style: { width: 185 },
            },
            {
                name: 'login',
                type: 'InputField',
                label: 'MyIdentity_Login',
                required: false,
                cardFieldType: 'inLineField',
                style: { color: '#307fc1', width: 161 },
            },
            {
                name: 'endOfContract',
                type: 'DatePicker',
                label: 'MyIdentity_EndOfContract',
                required: false,
                cardFieldType: 'inLineField',
                disabled: true,
                style: { width: 152 },
            },
            {
                name: 'costCenter',
                type: 'InputField',
                label: 'MyIdentity_CostCenter',
                required: false,
                disabled: true,
                cardFieldType: 'inLineField',
                style: { width: 152 },
            },
            {
                name: 'poBox',
                type: 'InputField',
                label: 'MyIdentity_InternalPoBox',
                required: false,
                disabled: true,
                cardFieldType: 'inLineField',
                style: { width: 120 },
            },
            {
                name: 'hrNumber',
                type: 'InputField',
                label: 'MyIdentity_HrNumber',
                required: false,
                disabled: true,
                cardFieldType: 'inLineField',
                style: { width: 100 },
            },
            {
                name: 'preferredLanguage',
                type: 'InputField',
                label: 'MyIdentity_PreferredLanguage',
                required: false,
                disabled: true,
                cardFieldType: 'inLineField',
                style: { width: 185 },
            },
            {
                name: 'manager',
                type: 'InputField',
                label: 'MyIdentity_Manager',
                required: false,
                cardFieldType: 'inLineField',
                disabled: true,
                style: { width: 185 },
            },
        ],
    },
    {
        name: 'outOfOffice',
        type: 'general',
        editable: true,
        fields: [
            {
                name: 'oofStartDate',
            },
            {
                name: 'oofEndDate',
            },
            {
                name: 'oofInternalMsg',
            },
            {
                name: 'oofAudience',
            },
            {
                name: 'oofStatus',
            },
            {
                name: 'isOutOfOffice',
            },
            {
                name: 'oofExternalMsg',
            },
        ],
    },
]
