import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withStyles } from '@material-ui/core'

const primaryPurpleColor = '#453fbb'

const colors = {
    primary: '#453fbb',
    red: 'red',
    green: 'green',
}

const getColor = (props) => {
    return props.themeColor !== undefined
        ? colors[props.themeColor]
        : props.themeColor || primaryPurpleColor
}

const styles = (theme) => ({
    root: {
        width: '246px',
        display: 'inline-block',
        position: 'relative',
        '&:active': {
            outline: 'none',
        },
    },
    input: {
        width: '246px',
        height: '40px',
        display: 'inline',
        borderRadius: '5px',
        position: 'absolute',
        left: '-34px',
        bottom: '24%',
        boxShadow: 'inset 0 1px 3px 0 rgba(126, 132, 255, 0.4)',
        border: (props) => `solid 1px ${getColor(props)}`,
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
        padding: '5px 12px',
        '&:active': {
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
        '@media (max-width:678px)': {
            left: '-30px',
        },
        '&::placeholder': {
            color: (props) => getColor(props),
        },
    },
    label: {
        position: 'absolute',
        top: '-52px',
        left: '-23px',
        color: '#919193',
        marginBottom: '3px',
        fontSize: '12px',
    },
    imageIcon: {
        position: 'absolute',
        right: '44px',
        top: '-20px',
        zIndex: '3',
        background: '#fff',
        paddingLeft: '3px',
    },
})

function Input({
    classes,
    placeholder = '',
    label = '',
    icon = '',
    className,
}) {
    return (
        <div className={clsx(classes.root, className)}>
            <input className={classes.input} placeholder={placeholder} />
            <span className={classes.label}>{label}</span>
            <img className={classes.imageIcon} src={icon} alt={''} />
        </div>
    )
}

Input.propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    theme: PropTypes.string,
    themeColor: PropTypes.string,
}

export default withStyles(styles)(Input)
