import React from 'react'
import { Button as MuiButton, makeStyles } from '@material-ui/core'
import { Loader } from 'packages/eid-ui'
import EditButton from './EditButton'
import DeleteButton from './DeleteButton'
import ViewProfileButton from './ViewProfileButton'
import NewDelegationButton from './NewDelegationButton'
import RoundedButton from './RoundedButton'
import AddAllButton from './AddAllButton'
import RemoveAllButton from './RemoveAllButton'
import AddButton from './AddButton'
import RemoveButton from './RemoveButton'

const styles = {
    small: {
        padding: '3.25px 20px',
        minHeight: '35px',
    },
    medium: {
        padding: '5.25px 15px',
        minHeight: '39px',
    },
    large: {
        padding: '9.25px 30px',
        minHeight: '47px',
    },
}

const useStyles = makeStyles(() => ({
    root: (props) => ({
        textTransform: 'capitalize',
        fontSize: '16px',
        border: '1px solid transparent',
        borderRadius: '5px',
        ...props.variantStyles,
        ...props.style,
    }),
    startIcon: (props) => ({
        marginRight: props.size === 'large' ? '14px' : '10px',
    }),

    disabled: (props) => ({
        ...props.disabledStyles,
    }),
}))

const Button = ({
    size,
    children,
    style,
    disabledStyles,
    loading,
    loaderColor,
    startIcon,
    endIcon,
    onClick,
    ...rest
}) => {
    const variantStyles = styles[size]

    const classes = useStyles({ style, variantStyles, size, disabledStyles })

    return (
        <MuiButton
            startIcon={!loading && startIcon}
            endIcon={!loading && endIcon}
            size={size}
            classes={classes}
            onClick={!loading ? onClick : undefined}
            {...rest}
        >
            {!loading ? (
                children
            ) : (
                <Loader
                    color={loaderColor ? loaderColor : '#ffffff'}
                    height="1inh"
                />
            )}
        </MuiButton>
    )
}

Button.Edit = EditButton
Button.Delete = DeleteButton
Button.ViewProfile = ViewProfileButton
Button.NewDelegation = NewDelegationButton
Button.Rounded = RoundedButton
Button.Add = AddButton
Button.AddAll = AddAllButton
Button.RemoveAll = RemoveAllButton
Button.Remove = RemoveButton

export default Button
