import { FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
    MenuItem,
    Select as MuiSelect,
    SelectProps as MuiSelectProps,
    styled,
    FormControl,
    Box,
} from '@material-ui/core'
import { Icon } from '../../eid-icons'
import { Input } from './Input'
import { Skeleton } from '@material-ui/lab'
import { isNilOrEmpty } from 'packages/core'

const StyledMenuItem = styled(MenuItem)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#919193',
    fontSize: '16px',
    borderRadius: '4px',
    padding: '5px 12px',
    wordBreak: 'break-word',
    '&:hover': {
        backgroundColor: '#f5f6f8 !important',
    },
})

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiSelect-iconOpen': {
            transform: 'rotate(180deg) !important',
        },
    },
    iconOpen: {
        transform: 'rotate(180deg) !important',
    },
    formControl: {
        width: '100%',
        position: 'relative',
    },
    placeholder: {
        position: 'absolute',
        left: '16px',
        color: '#b4b4b4',
        fontSize: '14px',
        top: '12px',
        zIndex: 1,
        pointerEvents: 'none',
    },
    label: {
        color: '#b4b4b4',
        fontSize: '12px',
        paddingLeft: '8px',
        marginBottom: '-4px',
    },
}))

const useMenuStyles = makeStyles((theme) => ({
    paper: {
        marginTop: '4px',
        padding: '0px 6px',
        maxHeight: '200px',
        border: '1px solid #d4d4d8',
        boxShadow: '0 4px 6px 0 rgb(0 0 0 / 7%)',
        backgroundColor: '#ffffff',
        paddingLeft: '6px',
        borderRadius: '5px',
    },

    root: {
        '& .Mui-selected': {
            color: '#000000 !important',
            backgroundColor: '#f5f6f8 !important',
        },
    },
}))

export interface SelectProps extends MuiSelectProps {
    value?: any
    options?: any
    isLoading?: boolean
    onChange?: any
    getOptionLabel?: any
    clearIcon?: any
    label?: any
}

export const Select: FC<SelectProps> = (props) => {
    const {
        options,
        getOptionLabel,
        value,
        onChange,
        onOpen,
        onClose,
        label,
        placeholder,
        isLoading,
    } = props
    const classes = useStyles()

    const menuClasses = useMenuStyles()

    const menuProps: any = {
        PopoverClasses: menuClasses,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        },
        getContentAnchorEl: null,
    }
    const isSelected = (option: any) => option.id === value?.id

    return (
        <>
            {label && <Box className={classes.label}>{label}</Box>}

            <FormControl className={classes.formControl}>
                {isNilOrEmpty(value) && (
                    <Box className={classes.placeholder}>{placeholder}</Box>
                )}

                <MuiSelect
                    value={value}
                    renderValue={(value: any) => (
                        <span>{getOptionLabel(value)}</span>
                    )}
                    input={<Input />}
                    MenuProps={{
                        ...menuProps,
                    }}
                    IconComponent={(props) => (
                        <Icon
                            name="Arrow"
                            className={`material-icons ${props.className}`}
                            style={{ right: '12px', width: '20px' }}
                            color="#919193"
                            {...props}
                        />
                    )}
                    onChange={(e: any) => {
                        onChange(e.target.value)
                    }}
                    onOpen={onOpen}
                    onClose={onClose}
                >
                    {!isLoading &&
                        options.map((o: any) => (
                            <StyledMenuItem
                                value={o}
                                key={o.id}
                                selected={isSelected(o)}
                            >
                                {getOptionLabel(o)}

                                {isSelected(o) && (
                                    <Icon
                                        name="Check"
                                        width="10px"
                                        height="7px"
                                        color="#7d7c7c"
                                    />
                                )}
                            </StyledMenuItem>
                        ))}

                    {isLoading &&
                        [0, 1, 2].map((i) => (
                            <StyledMenuItem key={i}>
                                <Skeleton height={20} width="100%" />
                            </StyledMenuItem>
                        ))}
                </MuiSelect>
            </FormControl>
        </>
    )
}
