import React from 'react'
import PropTypes from 'prop-types'
import { styled, Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { isNilOrEmpty } from '../../core'
import {
    AppMenuIcon,
    EmpowerIdAppLogoIcon,
    CombinedShapeIcon,
    Icon,
} from '../../eid-icons'

const MenuRoot = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '60px',
    boxShadow: ' 3px 3px 5px 0 rgba(0, 0, 0, 0.04)',
    borderRadius: 5,
    border: '1.3px solid #dfe0e2',
    backgroundColor: '#ffffff',
})

const MenuHeader = styled('div')({
    backgroundColor: '#f7f8fa',
    borderBottom: 'solid 1.3px #dfe0e2',
    borderTopLeftRadius: 5.3,
    borderTopRightRadius: 5.3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 48,
})

const Divider = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '4px',
    marginBottom: '16px',
})

const AppIconLink = styled('a')({
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '35px',
    width: '50px',
    marginTop: '10px',
    marginBottom: '10px',
    '&:hover': {
        borderRadius: 4,
        border: 'solid 1.3px rgba(214, 214, 214, 0.55)',
        backgroundColor: ' #f8f8f8',
    },
})

const CurrentAppIconLink = styled(AppIconLink)({
    borderRadius: 4,
    border: 'solid 1.3px rgba(214, 214, 214, 0.55)',
    backgroundColor: ' #f8f8f8',
})

const GlobalMenu = ({ eid, apps, loading, current }) => {
    const isCurrentApp = (appName) => appName === current

    const renderLink = (app) => {
        const LinkComponent = isCurrentApp(app.name)
            ? CurrentAppIconLink
            : AppIconLink

        return (
            <LinkComponent
                title={app.displayName ? app.displayName : app.name}
                href={app.url}
                key={app.name}
            >
                <Icon
                    name={app.name}
                    color={isCurrentApp(app.name) ? '#307fc1' : '#000'}
                />
            </LinkComponent>
        )
    }

    return (
        <MenuRoot>
            <MenuHeader>
                <AppMenuIcon color="#959598" />
            </MenuHeader>
            {loading ? (
                <Box marginTop={2} padding={1} width="100%">
                    <Skeleton
                        variant="rect"
                        animation="wave"
                        height={64}
                        style={{ borderRadius: '2px' }}
                    />
                </Box>
            ) : (
                <>
                    {!isNilOrEmpty(eid) && (
                        <>
                            <AppIconLink
                                title={
                                    eid.displayName ? eid.displayName : eid.name
                                }
                                href={eid.url}
                            >
                                <EmpowerIdAppLogoIcon color="#000" />
                            </AppIconLink>
                            <Divider>
                                <CombinedShapeIcon color="#DFE0E2" />
                            </Divider>
                        </>
                    )}
                    {apps.map((app) => renderLink(app))}
                </>
            )}
        </MenuRoot>
    )
}

GlobalMenu.propTypes = {
    eid: PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        displayName: PropTypes.string,
    }),
    apps: PropTypes.array,
    current: PropTypes.string.isRequired,
}

export default GlobalMenu
