import { styled } from '@material-ui/core'
import { HorizontalTabs } from '../../eid-ui'

import Tab from './SlantedTab'

const SlantedTabs = styled(HorizontalTabs)({
    '& .MuiTabs-flexContainer': {
        paddingLeft: '15px',
        height: '50px',
    },
    '& .Mui-selected': {
        backgroundColor: '#ffffff',
        color: '#307fc1',
        '& svg': {
            color: '#307fc1',
        },
    },
    '& .MuiTabs-indicator': {
        display: 'none',
    },
    '& .MuiTab-labelIcon': { minHeight: '0px' },
})

SlantedTabs.Tab = Tab

export default SlantedTabs
