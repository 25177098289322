import React from 'react'
import { Box } from '@material-ui/core'

const GenericCardContent = ({ children, avatarCard }) => {
    return (
        <Box
            style={{
                padding: '10px 0',
                display: avatarCard ? 'flex' : '',
                justifyContent: avatarCard ? 'center' : '',
            }}
        >
            {children}
        </Box>
    )
}

export default GenericCardContent
