import { styled } from '@material-ui/core'

const Listbox = styled('ul')({
    paddingLeft: '6px',
    paddingRight: '6px',
    listStyle: 'none',
    backgroundColor: '#fff',
    borderRadius: '5px',
    border: '1px solid #d4d4d8',
    boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.07)',
    color: '#919193',
    '& li': {
        padding: '5px 12px',
        display: 'flex',
        borderRadius: '5px',
        '&.focus': {
            backgroundColor: 'green',
        },
        '& span': {
            flexGrow: '1',
        },
        '& svg': {
            color: 'transparent',
        },
    },
    '& li[aria-selected="true"]': {
        backgroundColor: '#ffffff',
        fontWeight: 'normal',
        color: '#000000',
        '& svg': {
            color: '#1890ff',
        },
    },
    '& li[data-focus="true"]': {
        backgroundColor: '#f5f6f8',
        cursor: 'pointer',
        '& svg': {
            color: '#000',
        },
    },
})

export default Listbox
