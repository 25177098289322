import React, { useState, useRef } from 'react'
import { makeStyles, Link } from '@material-ui/core'
import { Dropdown } from '..'

const centerStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const useStyles = makeStyles(() => ({
    dropdownAnchor: ({ anchorPosition }) => ({
        position: 'relative',
        ...anchorPosition,
    }),
}))

const PopupMenu = ({
    displayIcon,
    style,
    onOpen,
    onClose,
    handleItemSelection,
    menuHeight,
    children,
    anchorPosition,
    showCone = true,
    ...rest
}) => {
    const [open, setOpen] = useState(false)
    const classes = useStyles({ anchorPosition })
    const anchorRef = useRef(null)

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false)
        onClose && onClose()
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
            onClose && onClose()
        }
    }

    return (
        <>
            <div style={{ position: 'relative', ...centerStyles, ...style }}>
                <Link
                    underline="none"
                    component="button"
                    onClick={() => {
                        onOpen && onOpen()
                        setOpen((prev) => !prev)
                    }}
                    style={{ ...centerStyles }}
                >
                    {displayIcon}
                </Link>
                <span ref={anchorRef} className={classes.dropdownAnchor} />
            </div>

            <Dropdown
                open={open}
                anchorEl={anchorRef.current}
                handleClose={handleClose}
                handleListKeyDown={handleListKeyDown}
                showCone={showCone}
                menuHeight={menuHeight}
                {...rest}
            >
                {children}
            </Dropdown>
        </>
    )
}

export default PopupMenu
