import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography, ListItem, List, Checkbox } from '@material-ui/core'
import { ReactComponent as CheckboxIconSvg } from './Resources/checkbox.svg'
import { ReactComponent as CheckedboxIconSvg } from './Resources/checkedbox.svg'
import { Spinner } from 'packages/eid-ui'

const listItemStyles = makeStyles((theme) => ({
    root: (props) => ({
        display: 'flex',
        alignItems: 'center',
        padding: 0,
        // height: props.itemContainerHeight && props.itemContainerHeight,
        marginBottom: '5px',
        marginTop: '5px',
        '&:hover': {
            backgroundColor: 'inherit',
        },
        '& span': {
            paddingTop: '0px',
            paddingBottom: '0px',
        },
        '& p': {
            width: '100%',
            padding: '0px 5px',

            borderRadius: '5px',
            '&:hover': {
                backgroundColor: '#f1f1f4',
            },
        },
        '& .search-highlight2': {
            color: '#950167',
            fontWeight: 600,
            textDecoration: 'underline',
        },
    }),
}))
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}))
const checkboxStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
})

const highlight = (text, searchKey) => {
    searchKey = new RegExp('(' + searchKey + ')', 'gi')
    return text.split(searchKey).map((o, i) =>
        i % 2 === 1 ? (
            <span className="search-highlight2" key={i}>
                {o}
            </span>
        ) : (
            o
        ),
    )
}

export default function CheckboxList(props) {
    const {
        fontSize,
        fontColor,
        itemContainerHeight,
        handleToggle,
        checked,
        loading,
        dataList,
        highlightText,
        fallbackMessage,
    } = props

    const classes = useStyles()
    const itemClasses = listItemStyles({ itemContainerHeight })
    const checkboxClasses = checkboxStyles()
    return loading ? (
        <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            padding="20px"
            marginLeft="-10px"
        >
            <Spinner />
        </Box>
    ) : (
        dataList && (
            <List className={classes.root}>
                {dataList.length === 0 && <>{fallbackMessage}</>}
                {dataList.map((item, index) => {
                    const labelId = `checkbox-list-label-${item.friendlyName}`

                    return (
                        <ListItem
                            key={index}
                            role={undefined}
                            button
                            onClick={() => handleToggle(item)}
                            classes={itemClasses}
                        >
                            <Box display="flex" alignItems="center">
                                <Box>
                                    <Checkbox
                                        edge="start"
                                        color="primary"
                                        classes={checkboxClasses}
                                        icon={
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <CheckboxIconSvg></CheckboxIconSvg>
                                            </Box>
                                        }
                                        checkedIcon={
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <CheckedboxIconSvg></CheckedboxIconSvg>
                                            </Box>
                                        }
                                        checked={Boolean(
                                            checked.find(
                                                (c) => c.id === item.id,
                                            ),
                                        )}
                                        tabIndex={-1}
                                        disableRipple
                                        disableFocusRipple
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            color: fontColor,
                                            fontSize: fontSize
                                                ? fontSize
                                                : '14px',
                                            wordBreak: 'break-word',
                                            wordWrap: 'break-word',
                                            overflow: 'auto',
                                        }}
                                    >
                                        {highlightText && highlightText.length
                                            ? highlight(
                                                  item.friendlyName,
                                                  highlightText,
                                              )
                                            : item.friendlyName}
                                    </Typography>
                                </Box>
                            </Box>
                        </ListItem>
                    )
                })}
            </List>
        )
    )
}

CheckboxList.propTypes = {
    dataList: PropTypes.array,
    handleToggle: PropTypes.func.isRequired,
    fontSize: PropTypes.string,
    fontColor: PropTypes.string,

    itemContainerHeight: PropTypes.any,
    handleToggle: PropTypes.func,
    checked: PropTypes.array,
    dataList: PropTypes.array,
    highlightText: PropTypes.any,
    fallbackMessage: PropTypes.any,
}

CheckboxList.defaultProps = {}
