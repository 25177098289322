import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { Header, Dropdown } from 'packages/eid-ui'
import { Icon } from 'packages/eid-icons'
import { useIsSmallScreen } from 'packages/core'
import { useTranslation } from 'react-i18next'
import { useCurrentPerson } from 'hooks'
import config from 'config'
import { useConfiguration } from 'appConfiguration'
import appConfig from 'config'

const AccountDropdown = ({ history }) => {
    const isSmallScreen = useIsSmallScreen()
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)

    const { data: personData } = useCurrentPerson()

    const { hasAccessToNotificationSettings } = useConfiguration()

    const content = (
        <span>
            {hasAccessToNotificationSettings && (
                <Dropdown.Item
                    onClick={() => {
                        history.push(`${appConfig.APP_SUBPATH}/notifications`)
                        setOpen((prev) => !prev)
                    }}
                >
                    <Icon name="EmailNotification" color="#919193" />
                    {t('MyIdentity_EmailNotifications')}
                </Dropdown.Item>
            )}
            <Dropdown.Item
                onClick={() => {
                    history.push('/signout')
                    setOpen((prev) => !prev)
                }}
            >
                <Icon name="LogOut" color="#919193" />
                {t('MyIdentity_SignOut')}
            </Dropdown.Item>
        </span>
    )

    const mobile = (
        <Header.AccountDropdown
            photoBase64={personData?.photoBase64}
            imageUrl={`${config.BASE_EID_URL}${personData?.pathToImage}`}
            anchorPosition={{ right: '-32px' }}
            friendlyName={personData?.friendlyName}
            showCone={false}
            open={open}
            handleOpen={setOpen}
        >
            {content}
        </Header.AccountDropdown>
    )

    const desktop = (
        <Header.AccountDropdown
            open={open}
            handleOpen={setOpen}
            photoBase64={personData?.photoBase64}
            friendlyName={personData?.friendlyName}
            imageUrl={`${config.BASE_EID_URL}${personData?.pathToImage}`}
        >
            {content}
        </Header.AccountDropdown>
    )

    return isSmallScreen ? mobile : desktop
}

export default withRouter(AccountDropdown)
