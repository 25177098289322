import { Box, createStyles, makeStyles } from '@material-ui/core'
import { FC } from 'react'

const useStyles = makeStyles(
    createStyles({
        root: {
            borderRadius: '5px',
            overflow: 'hidden',
            marginTop: '40px',
            border: '1px solid #d0021b',
        },
    }),
)

export const ValidationMessages: FC = (props) => {
    const classes = useStyles()

    return <Box className={classes.root}>{props.children}</Box>
}
