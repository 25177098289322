import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    root: (props) => ({
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        color: props.color,
        backgroundColor: props.backgroundColor,
        height: props.height,
        borderRadius: `${props.borderRadius}px ${props.borderRadius}px 0 0`,
        borderBottom: '1px solid #efeff1',
    }),
})

const GenericCardHeader = ({
    color,
    backgroundColor,
    height,
    icon,
    heading,
    borderRadius,
}) => {
    const classes = useStyles({
        color,
        backgroundColor,
        height,
        borderRadius,
    })

    return (
        <Box classes={classes}>
            {icon}
            <Typography variant="h2">{heading}</Typography>
        </Box>
    )
}

GenericCardHeader.propTypes = {
    icon: PropTypes.element,
    borderRadius: PropTypes.number,
}

GenericCardHeader.defaultProps = {
    borderRadius: 5,
}

export default GenericCardHeader
