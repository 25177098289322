import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import IconButton from '../IconButton'
import Arrow from '../../eid-icons/Arrow'

const variant = {
    small: 44,
    medium: 64,
    large: 80,
}

const useStyles = makeStyles(() => ({
    icon: ({ size }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        fontSize: '12px',
        color: '#ffffff',

        width: variant[size],
        height: variant[size],
        backgroundColor: 'rgba(59, 69, 77, 0.75)',
        boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.15)',
        borderRadius: '50%',

        '& >svg': {
            color: '#ffffff',
            width: '20px',
            height: '20px',
        },
        '&:hover': {
            backgroundColor: 'rgba(59, 69, 77, 0.9)',
            '& >svg': {
                color: '',
            },
        },
    }),
    label: {
        fontSize: '12px',
        color: '#ffffff',
        wordBreak: 'break-word',
    },

    button: {
        padding: 0,
        overflow: 'hidden',
    },
}))

const ScrollToTopButton = ({
    onClick,
    label = 'Top',
    size = 'medium',
    ...rest
}) => {
    const classes = useStyles({ size })
    return (
        <IconButton
            onClick={onClick}
            size="medium"
            className={classes.button}
            {...rest}
        >
            <Box className={classes.icon}>
                <Arrow direction="up" />
                <Typography className={classes.label}>{label}</Typography>
            </Box>
        </IconButton>
    )
}

export default ScrollToTopButton
