import React from 'react'
import { Box, makeStyles, } from '@material-ui/core'
import GenericCard from './Cards/GenericCard'
import GenericCardContent from './Cards/GenericCardContent'
import CardFieldGroup from './Cards/CardFieldGroup'
import { useTranslation } from 'react-i18next'
import OutOfficeField from './Cards/OutOfficeField'

const useStyles = makeStyles((theme) => ({
    officecard: {
        marginTop: '10px',
    },
}))

const OutOfOfficeCard = ({ oofInfo, handleModalOpen, config }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    return (
        <Box className={classes.officecard}>
            <GenericCard
                onClick={config.editable ? handleModalOpen : undefined}
                editable={config.editable}
            >
                <GenericCardContent>
                    <CardFieldGroup>
                        <OutOfficeField
                            label={t('MyIdentity_OoO_Status')}
                            bold
                            oofInfo={oofInfo}
                        />
                    </CardFieldGroup>
                </GenericCardContent>
            </GenericCard>
        </Box>
    )
}

export default OutOfOfficeCard
