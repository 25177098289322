import { useAuthState } from 'packages/core'

export default () => {
    const [
        {
            access: { controls },
        },
    ] = useAuthState()

    return controls
}
