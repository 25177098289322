import { FC } from 'react'
import { Box } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'

export interface IAttributeLabelProps {
    className?: string
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            fontSize: '12px',
            lineHeight: 1.33,
            color: theme.palette.grey[500],
            textTransform: 'uppercase',
            minWidth: '204px',
            display: 'block',
            wordBreak: 'break-word',
            padding: theme.spacing(0.5),
            fontWeight: 'bold',
        },
    }),
)

export const AttributeLabel: FC<IAttributeLabelProps> = (props) => {
    const classes = useStyles()

    return (
        <Box className={classNames(classes.root, props.className)}>
            {props.children}
        </Box>
    )
}
