import React, { useEffect, useState } from 'react'
import {
    Box,
    makeStyles,
    Typography,
    Grid,
    Link,
    withStyles,
    Dialog,
    DialogContent,
} from '@material-ui/core'
import { ReactComponent as CloseIconSvg } from 'icons/close-icon.svg'
import CustomButton from 'components/CustomButton'
import { useTranslation } from 'react-i18next'
import { NativeDateTimePicker } from 'packages/eid-ui/DateTimePickers'
import moment from 'moment'
import { Icon } from 'packages/eid-icons'
import ArrowIcon from './ArrowIcon'
import { OutOfOffceSelector } from './OutOfOfficeSelector'

const useStyles = makeStyles((theme) => ({
    modal: {
        '-webkit-backdrop-filter': 'blur(4px)',
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
    },

    modalContentWrapper: {
        padding: '27px 46px 54px 50px',
        background: '#f7f8fa',
        width: '100%',
        maxWidth: 960,
        border: 'solid 1px #ebebed',
        borderRadius: '5px',
        boxShadow: '0 0 13px 0 rgba(0, 0, 0, 0.15)',
        [theme.breakpoints.down('xs')]: {
            padding: '27px 30px 54px 30px',
        },
    },

    closeButton: {
        position: 'absolute',
        top: 16,
        right: 16,
    },
    outmodalchekbox: {},
    outmodalcheklabel: {
        marginLeft: '10px',
        fontSize: '14px',
        color: '#000',
    },
    outmodalinner: {
        padding: '12px 17px',
        marginTop: '16px',
        borderRadius: '5px',
        border: '1px solid #e4e5e6',
        backgroundColor: '#f2f3f4',
        width: '100%',
    },
    outmodalinnertext: {
        fontSize: '14px',
        color: '#000',
        marginBottom: '10px',
    },
    modalContent: {
        padding: '35px 0px 42px 0px',
        minHeight: '325px',
    },
    outmodaltextarea: {
        border: 'solid 1px #d8d8dd',
        fontSize: '14px',
        color: '#3b454d',
        padding: '8px',
        lineHeight: '1.71',
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        width: '100%',
    },
    datetimebox: {
        border: 'solid 1px #d8d8dd',
        fontSize: '14px',
        color: '#3b454d',
        padding: '8px',
        lineHeight: '1.71',
        boxShadow: '0 4px 6px -2px rgba(0, 0, 0, 0.1)',
        width: '100%',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px',
    },
    headerTypo: {
        fontWeight: 'bold',
        marginLeft: 9,
    },
    paddingbox: {
        paddingLeft: '25px',
    },
    marginbox: {
        marginBottom: '15px',
        marginTop: '15px',
    },
    datetimelabel: {
        display: 'flex',
        '& label': {
            color: '#767676',

        },
        '& input': {
            color: '#767676',
        }
    },
    datetimelabelSelected: {
        display: 'flex',
        '& label': {
            color: '#000',
        },
        '& input': {
            color: '#000',
        }
    },
    endtime: {
        display: 'flex',
        alignItems: 'end',
    },
    starttime: {
        display: 'flex',
        alignItems: 'end',
        svg: {
            margin: '10px',
        }
    },
}))

const MessageTypography = withStyles({
    root: {
        fontSize: '14px',
        fontWeight: 'normal',
        padding: '0px 10px 20px',
    },
    body1: {
        color: '#028f09',
    },
    body2: {
        color: '#ff0000',
    },

})(Typography)

export default function OutOfOfficModal({
    open,
    onClose,
    loading,
    oofInfo,
    updateData,
    handleDiscard,
    actions,
    unsavedChanges,
    response,
    handleOoFFieldChange,
    setResponse
}) {
    const classes = useStyles()
    const { t } = useTranslation()
    const [errorMesssage, setErrorMessage] = useState("")
    const [ooOselectionType, setOoOSelectionType] = useState(oofInfo.isOutOfOffice ? "ooOffice" : "inOffice")
    const [selectedStartDate, setSelectedStartDate] = useState(oofInfo.oofStartDate ? moment.utc(oofInfo.oofStartDate).local().format('LLL') : null)
    const [selectedEndDate, setSelectedEndDate] = useState(oofInfo.oofEndDate ? moment.utc(oofInfo.oofEndDate).local().format('LLL') : null)
    const [chooseAudience, setChooseAudience] = useState(oofInfo.oofAudience === null || oofInfo.oofAudience === 'none' || oofInfo.oofAudience === 'None' ? false : true)
    const [isOofEnabled, setIsOofEnabled] = useState(oofInfo.isOutOfOffice)
    const handleOnChange = (e) => {
        handleOoFFieldChange({ key: "oofStatus", value: e.target.checked ? "Scheduled" : "AlwaysEnabled" })
    }

    useEffect(() => {
        setErrorMessage("")
    }, [response])

    const handleOOfChange = (e) => {
        if (response)
            setResponse(undefined)
        setIsOofEnabled(e.target.checked)
        setErrorMessage("")
        if (!e.target.checked)
            handleOoFFieldChange({ key: "oofStatus", value: "Disabled" })
    }

    const handleInputChange = (e) => {
        if (response)
            setResponse(undefined)
        setErrorMessage("")
        const { name, value } = e.target;
        handleOoFFieldChange({ key: name, value: value })
    }

    const handleChooseAudience = (e) => {
        if (response)
            setResponse(undefined)
        setErrorMessage("")
        setChooseAudience(e.target.checked)
        handleOoFFieldChange({ key: "oofAudience", value: e.target.checked ? "ContactsOnly" : 'none' })
    }

    const handleDateChange = (type, date) => {
        if (response)
            setResponse(undefined)
        setErrorMessage("")
        handleOoFFieldChange({
            key: type, value: date ? moment(date).utc().format('YYYY-MM-DDTHH:mm:ss') : null
        });
    }

    const handleOoOChange = (e) => {
        if (response)
            setResponse(undefined)
        setErrorMessage("")
        if (!oofInfo.isOutOfOffice) {
            setChooseAudience(true)
            setIsOofEnabled(true)
        } else {
            setChooseAudience(false)
            setIsOofEnabled(false)
        }
        handleOoFFieldChange({
            key: "isOutOfOffice", value: !oofInfo.isOutOfOffice
        });

        setOoOSelectionType(e)
    }

    const validateOnSubmit = () => {
        if (response)
            setResponse(undefined)
        let isValidForm = true
        if (oofInfo.oofStatus === "Scheduled") {
            if (oofInfo.oofStartDate && oofInfo.oofEndDate) {
                //Check for valid end date
                if (!moment(oofInfo.oofEndDate).isAfter(oofInfo.oofStartDate)) {
                    isValidForm = false
                    setErrorMessage(t('MyIdentity_OoO_EndDate_Error'))
                }
            } else {
                isValidForm = false
                setErrorMessage(t('MyIdentity_OoO_Valid_Time_Error'))
            }
        }
        if (isValidForm) {
            if (chooseAudience && (oofInfo.oofAudience === "none" || oofInfo.oofAudience === "None")) {
                isValidForm = false
                setErrorMessage(t('MyIdentity_OoO_Valid_Audience_Error'))
            } else if (oofInfo.oofAudience && oofInfo.oofAudience !== "none" && oofInfo.oofAudience !== "None" && !oofInfo.oofExternalMsg) {
                isValidForm = false
                setErrorMessage(t('MyIdentity_OoO_Valid_Audience_Msg_Error'))
            } else if(oofInfo.oofAudience === null){
                handleOoFFieldChange({ key: "oofAudience", value: 'none' })
            }
        }

        if (isValidForm) {
            setErrorMessage('')
            updateData()
        }
    }

    return (
        <div>
            <Dialog
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                maxWidth="md"
                open={open}
                onClose={onClose}
                scroll="body"
                className={classes.modal}
            >
                <DialogContent className={classes.modalContentWrapper}>
                    <Box className={classes.modalHeader}>
                        <Typography variant="h5" className={classes.headerTypo}>
                            {t('MyIdentity_SetOoOStatus')}
                        </Typography>
                        <Box className={classes.closeButton}>
                            <Link component="button" onClick={onClose}>
                                <CloseIconSvg></CloseIconSvg>
                            </Link>
                        </Box>
                    </Box>
                    <Box>
                        <OutOfOffceSelector value={ooOselectionType}
                            handleChange={(e) => handleOoOChange(e)} />
                    </Box>

                    <Box className={classes.modalContent}>
                        <Box className={classes.outmodalchekbox}>
                            <input disabled={!oofInfo.isOutOfOffice} checked={isOofEnabled} type="checkbox" onChange={(e) => handleOOfChange(e)} />
                            <label className={classes.outmodalcheklabel}>
                                {`${t('MyIdentity_Send_Auto_Reply')} "${oofInfo.email}"`}
                            </label>
                        </Box>

                        <Box className={classes.outmodalinner}>
                            <Typography className={classes.outmodalinnertext}>
                                {t('MyIdentity_SetOoOStatusMsg')}
                            </Typography>
                            <textarea
                                disabled={!isOofEnabled || !oofInfo.isOutOfOffice}
                                rows="4"
                                className={classes.outmodaltextarea}
                                name="oofInternalMsg"
                                onChange={(e) => handleInputChange(e)}
                                value={oofInfo.oofInternalMsg ? oofInfo.oofInternalMsg : `${t('MyIdentity_OoOInternalMsg')} ${oofInfo.mobilePhone ? oofInfo.mobilePhone : '+1988888xxxx'}`}
                                placeholder={`${t('MyIdentity_OoOInternalMsg')} ${oofInfo.mobilePhone}`}
                            ></textarea>

                            <Box className={classes.marginbox}>
                                <input checked={oofInfo.oofStatus === "Scheduled"} disabled={!isOofEnabled || !oofInfo.isOutOfOffice} name="Scheduled" type="checkbox" onChange={(e) => handleOnChange(e)} />
                                <label
                                    className={classes.outmodalcheklabel}
                                    style={{
                                        color: '#000',
                                    }}
                                >
                                    {t('MyIdentity_OoOScheduleMsg')}
                                </label>
                            </Box>
                            <Box style={{ opacity: `${isOofEnabled && oofInfo.oofStatus === "Scheduled" && oofInfo.isOutOfOffice ? 1 : .3}` }} className={classes.datetimebox}>
                                <Box className={classes.starttime}>
                                    <Icon
                                        name={'StartTime'}
                                        name={'StartTime'}
                                        style={{
                                            marginRight: '10px',
                                            marginBottom: '6px',
                                        }}
                                    />
                                    <Box>
                                        <label

                                            style={{
                                                fontSize: '12px',
                                                color: '#919193',
                                            }}
                                        >
                                            {t('MyIdentity_OoOStartTime')}
                                        </label>
                                        <NativeDateTimePicker
                                            value={selectedStartDate ? moment(selectedStartDate).format(
                                                'YYYY-MM-DDTHH:mm',
                                            ) : ""}
                                            handleChange={(e) => {
                                                handleDateChange("oofStartDate", e)
                                                setSelectedStartDate(e)

                                            }}
                                            minDate={moment().format('YYYY-MM-DDTHH:mm')}
                                            disabled={!isOofEnabled || oofInfo.oofStatus !== "Scheduled" || !oofInfo.isOutOfOffice}
                                        >
                                            <input
                                                label={t('Start Date')}
                                                type="text"
                                                value={selectedStartDate && oofInfo.isOutOfOffice
                                                    ? moment(selectedStartDate).format('YYYY-MM-DD HH:mm a') : '--/--/---- --:--'}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{
                                                    border: 'none',
                                                    borderBottom:
                                                        'solid 1px #d6d9db',
                                                    padding: '6px',
                                                    paddingLeft: '0',
                                                }}
                                            />
                                        </NativeDateTimePicker>
                                    </Box>
                                </Box>
                                <ArrowIcon
                                    color={'#5d6870'}
                                    style={{ margin: '0px 20px' }}
                                />
                                <Box className={classes.endtime}>
                                    <Icon
                                        name={'EndTime'}
                                        style={{
                                            marginRight: '10px',
                                            marginBottom: '6px',
                                        }}
                                    />
                                    <Box>
                                        <label
                                            style={{
                                                fontSize: '12px',
                                                color: '#919193',
                                            }}
                                        >
                                            {t('MyIdentity_OoOEndTime')}
                                        </label>
                                        <NativeDateTimePicker
                                            value={selectedEndDate ? moment(selectedEndDate).format(
                                                'YYYY-MM-DDTHH:mm',
                                            ) : ""}
                                            handleChange={(e) => {
                                                handleDateChange("oofEndDate", e)
                                                setSelectedEndDate(e)
                                            }}
                                            minDate={selectedStartDate ? moment(
                                                selectedStartDate,
                                            ).format('YYYY-MM-DDTHH:mm') : moment().format('YYYY-MM-DDTHH:mm')}
                                            disabled={!isOofEnabled || oofInfo.oofStatus !== "Scheduled" || !oofInfo.isOutOfOffice}
                                        >
                                            <input
                                                label={t('End Date')}
                                                type="text"
                                                value={selectedEndDate && oofInfo.isOutOfOffice
                                                    ? moment(selectedEndDate).format('YYYY-MM-DD HH:mm a') : '--/--/---- --:--'}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                style={{
                                                    border: 'none',
                                                    borderBottom:
                                                        'solid 1px #d6d9db',
                                                    padding: '6px',
                                                    paddingLeft: '0',
                                                }}
                                            />
                                        </NativeDateTimePicker>
                                    </Box>
                                </Box>
                            </Box>

                            <Box
                                style={{
                                    marginTop: '15px',
                                }}
                            >
                                <input checked={chooseAudience} onChange={(e) => handleChooseAudience(e)} disabled={!isOofEnabled || !oofInfo.isOutOfOffice} type="checkbox" />
                                <label
                                    className={classes.outmodalcheklabel}
                                    style={{
                                        color: '#000',
                                    }}
                                >
                                    {t('MyIdentity_OoOReplyOutSide')}
                                </label>
                            </Box>
                            <Box className={classes.paddingbox}>
                                <Box className={`${oofInfo.oofAudience === "ContactsOnly" ? classes.datetimelabelSelected : classes.datetimelabel}`}>
                                    <input checked={oofInfo.oofAudience === "ContactsOnly"} onChange={(e) => handleInputChange(e)} disabled={!isOofEnabled || !chooseAudience || !oofInfo.isOutOfOffice} type="radio" value="ContactsOnly" name="oofAudience" />
                                    <label
                                        className={classes.outmodalcheklabel}
                                    >
                                        {t('MyIdentity_OoOMyContacts')}
                                    </label>
                                </Box>
                                <Box className={`${oofInfo.oofAudience === "All" ? classes.datetimelabelSelected : classes.datetimelabel}`}>
                                    <input checked={oofInfo.oofAudience === "All"} onChange={(e) => handleInputChange(e)} disabled={!isOofEnabled || !chooseAudience | !oofInfo.isOutOfOffice} type="radio" value="All" name="oofAudience" />
                                    <label
                                        className={classes.outmodalcheklabel}
                                    >
                                        {t('MyIdentity_OoOAllExternals')}
                                    </label>
                                </Box>

                                <Typography
                                    className={classes.outmodalinnertext}
                                    style={{
                                        marginTop: '15px',
                                        color: '#000',
                                    }}
                                >
                                    {`${t('MyIdentity_OoOExternamMsg')}:`}
                                </Typography>
                                <textarea
                                    disabled={!isOofEnabled || !chooseAudience || !oofInfo.isOutOfOffice}
                                    rows="3"
                                    name="oofExternalMsg"
                                    onChange={(e) => handleInputChange(e)}
                                    value={(oofInfo.oofAudience === null || oofInfo.oofAudience === "none" || oofInfo.oofAudience === "None") ? "" : oofInfo.oofExternalMsg}
                                    className={classes.outmodaltextarea}
                                ></textarea>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <MessageTypography
                            variant={
                                response && response.success && errorMesssage === '' ? 'body1' : 'body2'
                            }
                        >
                            {response && response.message}
                            {errorMesssage}
                        </MessageTypography>
                    </Box>
                    <Box className={classes.actionArea}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <CustomButton
                                    label={
                                        actions &&
                                            actions[1] &&
                                            actions[1].label
                                            ? actions[1].label
                                            : t('save')
                                    }
                                    onClick={() => validateOnSubmit()}
                                    active={true}
                                    disabled={loading || !unsavedChanges}
                                    loading={loading}
                                />
                            </Grid>

                            <Grid item>
                                <CustomButton
                                    label={
                                        actions &&
                                            actions[1] &&
                                            actions[1].label
                                            ? actions[1].label
                                            : unsavedChanges
                                                ? t('MyIdentity_DiscardChanges')
                                                : t('MyIdentity_Close')
                                    }
                                    width={150}
                                    onClick={
                                        unsavedChanges ? handleDiscard : onClose
                                    }
                                    active={
                                        actions &&
                                        actions[0] &&
                                        actions[0].active
                                    }
                                    disabled={loading}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}
