import { ExportRegular, FilterRegular, VerticalSquare } from 'images'
import { IConfigurationOption } from 'models'

export const whitePagesSubOptions: IConfigurationOption[] = [
    {
        name: 'MyIdentities',
        title: 'MyIdentity_MyIdentities',
        route: '/identities',
        requireAccess: {
            page: 'MyIdentity-MyIdentities-Page',
        },
        order: 1,
        dataSource: {
            type: 'MS_DS',
            url: '/api/v1/me/identities',
        },
        attributes: [
            {
                name: 'pathToImage',
                hideValue: true,
                src: 'pathToImage',
                label: '',
                icons: [FilterRegular, ExportRegular],
                align: 'center',
                avatar: true,
                sort: false,
            },
            {
                name: 'firstName',
                label: 'MyIdentity_FirstName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: true,
                link: true,
                styles: {
                    fontSize: '15px',
                    color: '#307fc1',
                },
            },
            {
                name: 'lastName',
                label: 'MyIdentity_LastName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: true,
                link:true,
                styles: {
                    fontSize: '15px',
                    color: '#307fc1',
                },
            },
            {
                name: 'login',
                label: 'MyIdentity_Login',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
            },
            {
                name: 'company',
                label: 'MyIdentity_CompanyName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
            },
            {
                name: 'email',
                label: 'MyIdentity_Email',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
                styles: {
                    textDecoration: 'underline',
                },
            },
            {
                name: 'viewProfileButton',
                label: '',
                icon: '',
                align: 'center',
                sort: false,
                hideValue: true,
                component: {
                    name: 'ViewProfileButton',
                },
            },
        ],
    },
    {
        name: 'AllPeople',
        title: 'MyIdentity_AllPeople',
        route: '/people',
        requireAccess: {
            page: 'MyIdentity-AllPeople-Page',
        },
        order: 2,
        dataSource: {
            type: 'MS_DS',
            url: '/api/v1/people/identities',
        },
        attributes: [
            {
                name: 'pathToImage',
                label: '',
                icons: [FilterRegular, ExportRegular],
                align: 'center',
                hideValue: true,
                avatar: true,
                src: 'pathToImage',
                sort: false,
            },
            {
                name: 'firstName',
                label: 'MyIdentity_FirstName',
                link: true,
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: true,
                styles: {
                    fontSize: '15px',
                    color: '#307fc1',
                },
            },
            {
                name: 'lastName',
                label: 'MyIdentity_LastName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: true,
                styles: {
                    fontSize: '15px',
                    color: '#307fc1',
                },
            },
            {
                name: 'company',
                label: 'MyIdentity_CompanyName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
            },
            {
                name: 'login',
                label: 'MyIdentity_Login',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
            },
            {
                name: 'email',
                label: 'MyIdentity_Email',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
                styles: {
                    textDecoration: 'underline',
                },
            },
            {
                name: 'viewProfileButton',
                label: '',
                icon: '',
                align: 'center',
                sort: false,
                hideValue: true,
                component: {
                    name: 'ViewProfileButton',
                },
            },
        ],
    },
    {
        name: 'MyDirectReports',
        title: 'MyIdentity_MyDirectReports',
        route: '/directReports',
        requireAccess: {
            page: 'MyIdentity-MyDirectReports-Page',
        },
        order: 3,
        dataSource: {
            type: 'MS_DS',
            url: '/api/v1/people/directReport',
        },
        attributes: [
            {
                name: 'pathToImage',
                hideValue: true,
                src: 'pathToImage',
                label: '',
                icons: [FilterRegular, ExportRegular],
                align: 'center',
                avatar: true,
                sort: false,
            },
            {
                name: 'firstName',
                label: 'MyIdentity_FirstName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: true,
                link: true,
                styles: {
                    fontSize: '15px',
                    color: '#307fc1',
                    
                },
            },
            {
                name: 'lastName',
                label: 'MyIdentity_LastName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: true,
                link:true,
                styles: {
                    fontSize: '15px',
                    color: '#307fc1',
                },
            },
            {
                name: 'company',
                label: 'MyIdentity_CompanyName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
            },
            {
                name: 'login',
                label: 'MyIdentity_Login',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
            },
            {
                name: 'email',
                label: 'MyIdentity_Email',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
                styles: {
                    textDecoration: 'underline',
                },
            },
            {
                name: 'viewProfileButton',
                label: '',
                icon: '',
                align: 'center',
                sort: false,
                hideValue: true,
                component: {
                    name: 'ViewProfileButton',
                },
            },
        ],
    },
    {
        name: 'MyDepartment',
        title: 'MyIdentity_MyDepartment',
        route: '/department',
        requireAccess: {
            page: 'MyIdentity-MyDepartment-Page',
        },
        order: 4,
        dataSource: {
            type: 'MS_DS',
            url: '/api/v1/me/departmentPeople',
        },
        attributes: [
            {
                name: 'pathToImage',
                hideValue: true,
                src: 'pathToImage',
                label: '',
                icons: [FilterRegular, ExportRegular],
                align: 'center',
                avatar: true,
                sort: false,
            },
            {
                name: 'firstName',
                label: 'MyIdentity_FirstName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: true,
                link: true,
                styles: {
                    fontSize: '15px',
                    color: '#307fc1',
                },
            },
            {
                name: 'lastName',
                label: 'MyIdentity_LastName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: true,
                styles: {
                    fontSize: '15px',
                    color: '#307fc1',
                },
            },
            {
                name: 'company',
                label: 'MyIdentity_CompanyName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
            },
            {
                name: 'login',
                label: 'MyIdentity_Login',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
            },
            {
                name: 'email',
                label: 'MyIdentity_Email',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
                styles: {
                    textDecoration: 'underline',
                },
            },
            {
                name: 'viewProfileButton',
                label: '',
                icon: '',
                align: 'center',
                sort: false,
                hideValue: true,
                component: {
                    name: 'ViewProfileButton',
                },
            },
        ],
    },
    {
        name: 'Internals',
        title: 'MyIdentity_Internals',
        route: '/internals',
        requireAccess: {
            page: 'MyIdentity-Internals-Page',
        },
        order: 5,
        dataSource: {
            type: 'MS_DS',
            url: '/api/v1/people/internal',
        },
        attributes: [
            {
                name: 'pathToImage',
                hideValue: true,
                src: 'pathToImage',
                label: '',
                icons: [FilterRegular, ExportRegular],
                align: 'center',
                avatar: true,
                sort: false,
            },
            {
                name: 'firstName',
                label: 'MyIdentity_FirstName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: true,
                link: true,
                styles: {
                    fontSize: '15px',
                    color: '#307fc1',
                },
            },
            {
                name: 'lastName',
                label: 'MyIdentity_LastName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: true,
                styles: {
                    fontSize: '15px',
                    color: '#307fc1',
                },
            },
            {
                name: 'company',
                label: 'MyIdentity_CompanyName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
            },
            {
                name: 'login',
                label: 'MyIdentity_Login',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
            },
            {
                name: 'email',
                label: 'MyIdentity_Email',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
                styles: {
                    textDecoration: 'underline',
                },
            },
            {
                name: 'viewProfileButton',
                label: '',
                icon: '',
                align: 'center',
                sort: false,
                hideValue: true,
                component: {
                    name: 'ViewProfileButton',
                },
            },
        ],
    },
    {
        name: 'Externals',
        title: 'MyIdentity_Externals',
        route: '/externals',
        requireAccess: {
            page: 'MyIdentity-Externals-Page',
        },
        order: 6,
        dataSource: {
            type: 'MS_DS',
            url: '/api/v1/people/external',
        },
        attributes: [
            {
                name: 'pathToImage',
                hideValue: true,
                src: 'pathToImage',
                label: '',
                icons: [FilterRegular, ExportRegular],
                align: 'center',
                avatar: true,
                sort: false,
            },
            {
                name: 'firstName',
                label: 'MyIdentity_FirstName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: true,
                link: true,
                styles: {
                    fontSize: '15px',
                    color: '#307fc1',
                },
            },
            {
                name: 'lastName',
                label: 'MyIdentity_LastName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: true,
                styles: {
                    fontSize: '15px',
                    color: '#307fc1',
                },
            },
            {
                name: 'company',
                label: 'MyIdentity_CompanyName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
            },
            {
                name: 'login',
                label: 'MyIdentity_Login',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
            },
            {
                name: 'email',
                label: 'MyIdentity_Email',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: false,
                styles: {
                    textDecoration: 'underline',
                },
            },
            {
                name: 'viewProfileButton',
                label: '',
                icon: '',
                align: 'center',
                sort: false,
                hideValue: true,
                component: {
                    name: 'ViewProfileButton',
                },
            },
        ],
    },
]
