import React from 'react'
import { Container, makeStyles } from '@material-ui/core'
import { useIsSmallScreen } from '../../core'
import FullScreenLoader from './FullScreenLoader'

const useLayoutStyles = makeStyles((theme) => ({
    fluidContainer: ({ rootStyles }) => ({
        position: 'absolute',
        ...theme.mixins.headerTop,
        right: 0,
        left: 0,
        bottom: 0,
        padding: 0,
        overflowY: 'auto',
        ...rootStyles,
    }),
    xlContainer: {
        padding: 0,
        display: 'flex',
    },
    globalMenuContainer: (props) => ({
        minWidth: '80px',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 1200,
        ...props.rootMenuStyles,
    }),
    contentContainer: {
        width: '100%',
        marginLeft: '80px',
        paddingRight: '16px',
        paddingBottom: '25px',
    },
}))

const Layout = ({
    header,
    subHeader,
    globalMenu,
    loading,
    rootMenuStyles,
    rootStyles,
    children,
    scrollToTopComponent,
}) => {
    const isSmallScreen = useIsSmallScreen()
    const layoutClasses = useLayoutStyles({ rootStyles, rootMenuStyles })

    return (
        <Container maxWidth={false} className={layoutClasses.fluidContainer}>
            {isSmallScreen && loading && <FullScreenLoader />}

            {header}
            <Container maxWidth="xl" className={layoutClasses.xlContainer}>
                {subHeader && subHeader}

                <div className={layoutClasses.globalMenuContainer}>
                    {globalMenu}
                </div>

                {!isSmallScreen ? (
                    <div
                        style={{
                            width: '100%',
                            marginLeft: !isSmallScreen ? '80px' : 'initial',
                            paddingRight: '16px',
                            paddingBottom: '25px',
                        }}
                    >
                        {children}
                    </div>
                ) : (
                    <div
                        style={{
                            height: '100%',
                            width: '100%',
                            position: 'relative',
                            marginBottom: '20px',
                        }}
                    >
                        {children}
                    </div>
                )}
            </Container>
            {scrollToTopComponent}
        </Container>
    )
}

export default Layout
