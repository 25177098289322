import { useState, useCallback } from 'react'

const useModalState = () => {
    const [modalOpen, setModalOpen] = useState(false)

    const openModal = useCallback(() => setModalOpen(true), [setModalOpen])
    const closeModal = useCallback(() => setModalOpen(false), [setModalOpen])

    return [modalOpen, openModal, closeModal]
}

export default useModalState
