import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { InputBase, makeStyles, Link } from '@material-ui/core'
import { SearchIcon, Icon } from 'packages/eid-icons'
import { useDebounce } from 'packages/core'

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                paddingLeft: '24px',
            },
            width: '100%',
        },
        searchContainer: (props) => ({
            width: '100%',
            height: 36,
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            borderRadius: 3,
            margin: 'auto',
            border: `1px solid ${props.borderColor}`,
            backgroundColor: props.bgColor,
        }),
        searchIcon: {
            width: 13,
            height: 13,
            marginLeft: 17,
        },
        closeIcon: {
            width: 13,
            height: 13,
            marginRight: 17,
            paddingTop: '1px',
            zIndex: 10,
        },
        input: (props) => ({
            padding: '10px 0px 8px 0px ',
            marginLeft: 13,
            fontSize: 13,
            flex: 1,
            color: `${props.color} !important`,
            '&:focus': {
                color: `${props.hoverColor} !important`,
            },
        }),
    }
})

function Search({ handleSearch, styleData, placeholder, value }) {
    const classes = useStyles(styleData)
    const [searchKey, setSearchKey] = useState(value)

    useEffect(() => {
        if (value !== searchKey) {
            setSearchKey(value)
        }
    }, [value])

    const handleChange = (event) => {
        const keyword = event.target.value
        setSearchKey(keyword)
    }
    const handleClearSearch = () => {
        setSearchKey('')
    }
    const debouncedValue = useDebounce(searchKey)

    useEffect(() => {
        handleSearch(debouncedValue)
    }, [debouncedValue])

    return (
        <div className={classes.root}>
            <div className={classes.searchContainer}>
                <div className={classes.searchIcon}>
                    <SearchIcon color={styleData.iconColor} />
                </div>
                <InputBase
                    fullWidth={true}
                    value={searchKey}
                    onChange={handleChange}
                    classes={{ input: classes.input }}
                    placeholder={placeholder ?? 'Search'}
                />
                {searchKey && (
                    <Link
                        component="button"
                        className={classes.closeIcon}
                        onClick={() => handleClearSearch()}
                    >
                        <Icon name="Close" color={styleData.iconColor} />
                    </Link>
                )}
            </div>
        </div>
    )
}

Search.propTypes = {
    handleSearch: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
}

Search.defaultProps = {
    value: '',
    styleData: {
        bgColor: '#f7f8fa',
        borderColor: '#ebebed',
        iconColor: '#b5b6b8',
        color: '#a7a7a9',
        hoverColor: '#a7a7a9',
    },
}

export default Search
