import React from 'react'
import { Avatar } from '@material-ui/core'
import { MIDPathToImage } from 'packages/core/helpers'
import appConfig from 'config'

const style = {
    marginLeft: '4px',
    width: 45,
    height: 45,
}
const CellAvatar = ({ attribute, data }) => {
    return (
        <Avatar
            src={
                data[attribute.src] &&
                MIDPathToImage(appConfig.BASE_EID_URL, data[attribute.src])
            }
            style={style}
        />
    )
}

export default CellAvatar
