import React, { useEffect } from 'react'
import { useAuthState, useQuery } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import {
    useAccessSubcomponents,
    useTranslations,
    useCurrentPerson,
    useApplicationSettings,
} from 'hooks'
import moment from 'moment'
import i18n from 'i18next'

const useLoadAccessSubcomponents = () => {
    const [{ access }, dispatchAuthState] = useAuthState()
    const { data } = useAccessSubcomponents()

    useEffect(() => {
        if (data) {
            dispatchAuthState({
                type: 'SET_ACCESS_SUBCOMPONENTS',
                payload: data.data,
            })
        }
    }, [data, dispatchAuthState])

    return access !== null
}

const useLoadTranslations = () => {
    const query = useQuery()
    const debugLocalization = query.get('debugLocalization') === 'true'

    const { data } = useTranslations()
    const { data: currentPerson } = useCurrentPerson()

    const locale =
        currentPerson && currentPerson.language?.languageCode
            ? currentPerson.language?.languageCode
            : 'en'
    useEffect(() => {
        i18n.changeLanguage(locale)
        moment.locale(locale)
    }, [locale])

    useEffect(() => {
        if (data) {
            const localeData = data.reduce((current, r) => {
                if (debugLocalization) {
                    current[r.key] = r.key
                } else {
                    current[r.key] = r.translatedValue
                }

                return current
            }, {})

            i18n.addResourceBundle(locale, 'translation', localeData)
        }
    }, [data, locale, debugLocalization])

    return Boolean(data)
}

const useLoadApplicationSettings = () => {
    const { data } = useApplicationSettings()

    return Boolean(data)
}

const withRequiredData = (ChildComponent) => (props) => {
    const translationsLoaded = useLoadTranslations()
    const accessSubcomponentsLoaded = useLoadAccessSubcomponents()
    const appConfigLoaded = useLoadApplicationSettings()

    if (!accessSubcomponentsLoaded || !translationsLoaded || !appConfigLoaded) {
        return <Loader />
    } else {
        return <ChildComponent {...props} />
    }
}

export default withRequiredData
