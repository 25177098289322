import React from 'react'
import { makeStyles, Fab } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { Loader } from 'packages/eid-ui'

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        margin: theme.spacing(1),
        textTransform: 'capitalize',
        fontSize: '14px',
        height: '31px !important',
        color: '#ffffff !important',
        backgroundColor: '#307fc1',
        '&:hover': {
            backgroundColor: '#307fc1',
        },
        ...props.styles,
    }),

    disabled: {},

    icon: {
        marginLeft: 6,
    },
}))
const RoundedButton = ({ styles, children, loading, ...rest }) => {
    const classes = useStyles({ styles })

    return (
        <Fab variant="extended" size="small" className={classes.root} {...rest}>
            {loading ? (
                <Loader color={'#ffffff'} />
            ) : (
                <>
                    {children}
                    <Icon
                        name="SmallArrow"
                        color={styles?.color ? styles.color : '#ffffff'}
                        className={classes.icon}
                    ></Icon>
                </>
            )}
        </Fab>
    )
}

export default RoundedButton
