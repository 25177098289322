import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles, Drawer as MuiDrawer, styled } from '@material-ui/core'
import SideClosButton from './SideCloseButton'
import { Spinner } from 'packages/eid-ui'
import WithCloseButton from '../WithCloseButton'

const useStyles = makeStyles({
    paper: (props) => ({
        top: props.top,
        width: props.width,
        backgroundColor: props.sideButton ? '#edeff3' : props.bgColor,
        height: `calc(100% - ${props.top}px)`,
        borderTopLeftRadius: '16px',
        borderBottomLeftRadius: '0px',
        overflowY: 'hidden',
    }),
})

const LoadingContainer = styled('div')({
    position: 'absolute',
    top: '33%',
    left: 0,
    right: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
})

const Header = styled('div')(({ position }) => ({
    position: position,
    zIndex: 20,
    top: 0,
    left: 0,
    width: '100%',
}))

const ScrollContainer = styled('div')(({ top }) => ({
    height: `calc(100% - ${top}px)`,

    overflow: 'auto',
    msOverflowY: 'auto',
    msOverflowX: 'auto',

    '@supports ( -moz-appearance:none )': {
        overflow: 'auto',
    },
    '@supports ( -webkit-appearance: none )': {
        overflow: 'auto overlay',
    },
}))

export default function Drawer({
    bgColor,
    sideButton,
    width,
    sideButtonWidth,
    top,
    children,
    loading,
    toggleDrawer,
    open,
    header,
    stickyHeader,
    closeOnOutsideClick,
    closeLabel,
    className,
}) {
    const classes = useStyles({
        bgColor,
        sideButton,
        width,
        sideButtonWidth,
        top,
    })

    return (
        <MuiDrawer
            classes={classes}
            anchor="right"
            open={open}
            onClose={closeOnOutsideClick ? toggleDrawer : undefined}
            className={className}
        >
            {sideButton ? (
                <ScrollContainer>
                    <Box marginRight={sideButton && sideButtonWidth}>
                        {loading ? (
                            <Box
                                width="100%"
                                height="98vh"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Spinner></Spinner>
                            </Box>
                        ) : (
                            <>{children}</>
                        )}
                    </Box>
                    <SideClosButton
                        top={top}
                        toggleDrawer={toggleDrawer}
                        width={sideButtonWidth}
                        closeLabel={closeLabel}
                    />
                </ScrollContainer>
            ) : (
                <>
                    {loading ? (
                        <LoadingContainer>
                            <Spinner></Spinner>
                        </LoadingContainer>
                    ) : (
                        <>
                            {header && (
                                <Header
                                    position={
                                        stickyHeader ? 'sticky' : 'absolute'
                                    }
                                >
                                    <WithCloseButton
                                        iconPosition={{
                                            right: '18px',
                                            top: '50%',
                                        }}
                                        onClose={toggleDrawer}
                                    >
                                        {header}
                                    </WithCloseButton>
                                </Header>
                            )}

                            <ScrollContainer top={top}>
                                {children}
                            </ScrollContainer>
                        </>
                    )}
                </>
            )}
        </MuiDrawer>
    )
}

Drawer.propTypes = {
    top: PropTypes.number,
    bgColor: PropTypes.string,
    sideButton: PropTypes.bool,
    sideButtonWidth: PropTypes.string,
    width: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    header: PropTypes.node,
}

Drawer.defaultProps = {
    top: 10,
    bgColor: '#ffffff',
    width: '510px',
    sideButtonWidth: '100px',
    loading: false,
}
