import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDebounce, useSwrApiGet } from 'packages/core'
import { Autocomplete } from 'packages/eid-ui'
import optionComponents from './optionComponents'
import NewEidAutocomplete from './NewEidAutocomplete'

const EidAutocomplete = (props) => {
    const {
        url,
        queryParams = [],
        dedupingInterval = 2000,
        debounceInterval = 300,
        onChange,
        shouldTriggerApiCall = true,
        supportsServerSideSearch = true,
        excludeOptions = [],
        primitiveType,
        minCharactersReqForSearch = 3,
        defaultOptions,
        ...rest
    } = props

    const [callEndpoint, setCallEndpoint] = useState(false)
    const [options, setOptions] = useState(defaultOptions ? defaultOptions : [])

    const [searchKey, setSearchKey] = useState('')
    const debouncedValue = useDebounce(searchKey, debounceInterval)

    const queryStringParts = []
    Object.keys(queryParams).forEach((key) => {
        if (queryParams[key] !== undefined)
            queryStringParts.push(`${key}=${queryParams[key]}`)
    })

    if (supportsServerSideSearch && debouncedValue) {
        queryStringParts.push(`searchTerm=${debouncedValue}`)
    }

    const queryString = queryStringParts.join('&')
    const urlWithQs = queryString ? `${url}?${queryString}` : url

    const { data } = useSwrApiGet(
        () => (callEndpoint && shouldTriggerApiCall ? urlWithQs : false),
        {
            dedupingInterval,
        },
    )

    useEffect(() => {
        if (
            minCharactersReqForSearch > 0 &&
            Boolean(debouncedValue) &&
            debouncedValue.length >= minCharactersReqForSearch
        ) {
            setCallEndpoint(true)
        }
    }, [minCharactersReqForSearch, debouncedValue])

    useEffect(() => {
        if (data) {
            let options = data.data

            if (primitiveType) {
                options = data.data.map((option) => {
                    return { id: option, value: option }
                })
            }
            if (excludeOptions.length > 0) {
                options = data.data.filter(
                    (d) => !excludeOptions.includes(d.id),
                )
            }

            setOptions(options)
        }
    }, [data])

    return (
        <Autocomplete
            options={options}
            loading={callEndpoint && shouldTriggerApiCall && !data}
            onInputChange={(_, value) => setSearchKey(value)}
            onKeyDown={(e) => {
                if (e.keyCode === 13) {
                    setCallEndpoint(true)
                } else {
                    setCallEndpoint(false)
                }
            }}
            onChange={onChange}
            onOpen={() => {
                if (
                    minCharactersReqForSearch === 0 ||
                    (minCharactersReqForSearch > 0 &&
                        Boolean(debouncedValue) &&
                        debouncedValue.length >= minCharactersReqForSearch)
                ) {
                    setCallEndpoint(true)
                }
            }}
            onClose={() => setCallEndpoint(false)}
            {...rest}
        />
    )
}

EidAutocomplete.getOptionComponent = ({ type, ...rest }) => {
    const OptionComponent = optionComponents[type]

    return ({ option, optionState }) => (
        <OptionComponent option={option} optionState={optionState} {...rest} />
    )
}

// EidAutocomplete.propTypes = {
//     /**
//      * URL where to fetch the data from.
//      */
//     url: PropTypes.string,

//     /**
//      * Callback when there is a change in the value.
//      */
//     onChange: PropTypes.func,

//     /**
//      * Request deduplication interval (ms) for requests with the same key.
//      * Default is set to 2000.
//      */
//     dedupingInterval: PropTypes.number,

//     /**
//      * Request debounce interval (ms).
//      * Default is set to 300.
//      */
//     debounceInterval: PropTypes.number,

//     /**
//      * If server-side search is supported or not.
//      */
//     supportsSearch: PropTypes.bool,

// }

EidAutocomplete.New = NewEidAutocomplete
export default EidAutocomplete
