import React from 'react'
import { PaginationItem as MuiPaginationItem } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core'

const sizes = {
    small: '26px',
    medium: '32px',
    large: '40px',
}

const useStyles = makeStyles({
    root: {
        borderRadius: '0px',
        margin: '0px !important',
        border: 'solid 1px #ebebed',
        color: '#5d6870',
        borderRight: 'none',
        fontSize: '14px !important',
        '&:hover': {
            backgroundColor: '#ffffff',
            color: '#307fc1',
        },
    },

    ellipsis: (props) => ({
        height: props.size ? sizes[props.size] : '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRight: '0px',
    }),
    selected: {
        backgroundColor: '#307fc1 !important',
        border: 'none',
        color: '#ffffff',
        '&:hover': {
            color: '#ffffff',
        },
    },
})

const PaginationItem = ({ size, ...rest }) => {
    const classes = useStyles({ size })
    return <MuiPaginationItem classes={classes} size={size} {...rest} />
}

export default PaginationItem
