import React from 'react'
import { CheckIcon } from '../../eid-icons'

const Option = ({ option, optionState, getOptionLabel }) => (
    <>
        <span style={{ wordBreak: 'break-all' }}>{getOptionLabel(option)}</span>
        {optionState.selected && <CheckIcon color="#919193" />}
    </>
)

export default Option
