import React from 'react'
import { Box, styled } from '@material-ui/core'
import { Layout, ScrollToTopButton } from 'packages/eid-ui'
import Header from './Header'
import GlobalMenu from './GlobalMenu'
import ScrollToTop from 'react-scroll-up'
import { useTranslation } from 'react-i18next'
import config from 'config'

const smallScreenWidth = 959
const SubHeaderContainer = styled(Box)({
    position: 'fixed',
    top: 70,

    width: '100%',
    maxWidth: '1920px',

    display: 'flex',
    flexDirection: 'column',

    paddingTop: '10px',
    paddingRight: '40px',
    paddingLeft: '90px',

    backgroundColor: '#eef0f4',

    zIndex: 5,

    [`@media (max-width:${smallScreenWidth}px)`]: {
        top: 111,
        paddingLeft: '10px',
        paddingRight: '10px',
    },
})

const styles = {
    rootStyles: {
        position: 'relative',
        paddingBottom: '30px',
    },
    scrollButton: {
        bottom: 20,
        right: 20,
        zIndex: 5,
    },
}

const AppLayout = (props) => {
    const { handleSearch, subHeader, children } = props
    const { t } = useTranslation()

    const scrollToTopComponent = (
        <ScrollToTop showUnder={160} style={styles.scrollButton}>
            <ScrollToTopButton
                size="medium"
                label={t('MyIdentity_BackToTop')}
            />
        </ScrollToTop>
    )
    return (
        <Layout
            header={<Header handleSearch={handleSearch} />}
            globalMenu={
                <div style={{ marginTop: '24px' }}>
                    <GlobalMenu />
                </div>
            }
            subHeader={<SubHeaderContainer>{subHeader}</SubHeaderContainer>}
            children={children}
            scrollToTopComponent={
                config.SHOW_SCROLL_TO_TOP && scrollToTopComponent
            }
            rootStyles={styles.rootStyles}
        />
    )
}

export default AppLayout
