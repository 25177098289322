import { MenuItem, makeStyles, ListItemText, Typography } from '@material-ui/core'
import moment from 'moment'

const useStyles = makeStyles({
    root: {
        minWidth: '160px',
        color: '#8b909a',
        fontSize: '13px',
        height: '40px',
        borderTop: '1px solid #efeff1',

        '& .MuiListItemText-primary':{
            fontSize:'13px',
            fontWeight:'inherit',
            wordWrap: 'break-word',
        },

        '& p': {
            fontSize:'13px',
            fontWeight:'inherit'
        },

        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    selected: {
        color: '#307fc1',
        fontWeight:'bold !important',
        backgroundColor: 'transparent !important',
    },
})

const TimeZoneMenuItem = 
    ({ timezone, selected, onClick }) => {
        const classes = useStyles()

        const timezoneTime = moment().utcOffset(timezone.timeOffsetUtc).format('LT')

        return (
            <MenuItem onClick={onClick} classes={classes} selected={selected}>
                <ListItemText>{timezone.displayName}</ListItemText>

                <Typography >
                    {timezoneTime}
                </Typography>
            </MenuItem>
        )
    }

export default TimeZoneMenuItem
