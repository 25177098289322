import React from 'react'
import { makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const sizes = {
    large: '30px',
    medium: '20px',
    small: '15px',
}

const useStyles = makeStyles(() => ({
    root: (props) => ({
        border: `solid ${props.color}`,
        borderWidth: '0 1.5px 1.5px 0',
        display: 'inline-block',
        width: sizes[props.size],
        height: sizes[props.size],
        '&:hover': {
            cursor: 'pointer',
        },
    }),
    right: {
        transform: 'rotate(-45deg)',
        '-webkit-transform': 'rotate(-45deg)',
    },

    left: {
        transform: 'rotate(135deg)',
        '-webkit-transform': 'rotate(135deg)',
    },
    up: {
        transform: 'rotate(-135deg)',
        '-webkit-transform': 'rotate(-135deg)',
    },
    down: {
        transform: 'rotate(45deg)',
        '-webkit-transform': 'rotate(45deg)',
    },
}))

function KeyboardArrow({ color, size, direction, onClick, style }) {
    const classes = useStyles({
        color,
        size,
    })
    return (
        <i
            onClick={onClick}
            className={classNames(classes.root, classes[direction])}
            style={style}
        ></i>
    )
}

KeyboardArrow.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOf(['large', 'medium', 'small']).isRequired,
    direction: PropTypes.oneOf(['left', 'right', 'up', 'down']).isRequired,
    onClick: PropTypes.func.isRequired,
}

KeyboardArrow.defaultProps = {
    color: '#000',
    size: 'medium',
    direction: 'down',
}

export default KeyboardArrow
