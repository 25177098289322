import {
    RESET_AUTH_STATE,
    USER_LOGGED_IN,
    SET_ACCESS_SUBCOMPONENTS,
    SET_IS_WORKFLOW_RENDERABLE,
} from './constants'
import { initialState } from './context'

const reducer = (state, { type, payload }) => {
    switch (type) {
        case RESET_AUTH_STATE:
            return {
                ...state,
                ...initialState,
                isAuth: false,
            }
        case USER_LOGGED_IN:
            return {
                ...state,
                isAuth: true,
                ...payload,
            }
        case SET_ACCESS_SUBCOMPONENTS:
            const pages = payload.filter((s) => s.type === 'Page')
            const controls = payload.filter((s) => s.type === 'Control')
            return {
                ...state,
                access: {
                    pages,
                    controls,
                },
            }
        case SET_IS_WORKFLOW_RENDERABLE:
            return {
                ...state,
                isWorkflowIRenderable: payload,
            }
        default:
            return state
    }
}

export default reducer
