import { styled } from '@material-ui/core'

const InputWrapper = styled('div')({
    position: 'relative',
    border: '1px solid #d4d4d8',
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '1px',
    display: 'flex',
    flexWrap: 'wrap',
    boxShadow: '0 2px 4px 0 rgba(48, 127, 193, 0.15)',
    '& input': {
        fontSize: '15px',
        height: '40px',
        lineHeight: 'normal',
        padding: '8px',
        flexGrow: 1,
        border: 0,
        outline: 0,
        '&::placeholder': {
            color: '#b4b4b4',
        },

        '&:-ms-input-placeholder': {
            opacity: `${1} !important`,
            color: '#b4b4b4 !important',
        },
    },
    '&:hover': {
        borderColor: '#453fbb',
    },
})

export default InputWrapper
