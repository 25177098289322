import React from 'react'
import { Fade } from '@material-ui/core'
import { SnackbarProvider } from 'notistack'

const NotificationsProvider = (props) => (
    <SnackbarProvider
        maxSnack={1}
        preventDuplicate
        autoHideDuration={2000}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        TransitionComponent={Fade}
        {...props}
    />
)

export default NotificationsProvider
