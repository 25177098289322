import React, { useState } from 'react'
import { Grid, Box, Typography, Collapse } from '@material-ui/core'
import {
    MyIdentityLayout,
    SubHeader,
    TableContainer,
    FiltersColumn,
} from 'components'
import TableView from './TableView'
import Card from './Card'
import {
    useIsSmallScreen,
    isNilOrEmpty,
    useGetControlsAccess,
} from 'packages/core'
import { useListingData } from 'hooks'
import { useAppState } from 'appContext'
import { Loader } from 'packages/eid-ui'
import { useTranslation } from 'react-i18next'
import { Icon } from 'packages/eid-icons'
import { Card as FilterCard } from 'packages/eid-ui'
import AdvancedFilter from 'components/EidFilter/AdvancedFilter'
import * as DynamicComponents from 'components/DynamicComponents'

const subHeaderHeight = 65

const renderPeopleGrid = (rows, route, fallbackMessage) => {
    if (rows.length === 0) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                height="40vh"
                margin="auto"
                alignItems="center"
            >
                <Typography variant="body2">{fallbackMessage}</Typography>
            </Box>
        )
    }

    return (
        <Grid
            container
            spacing={2}
            style={{             
                padding:'0 80px'
            }}
        >
            {rows.map((r, index) => (
                <Grid item xs={12} sm={6} md={4} key={'people-grid-' + index}>
                    <Card data={r} route={route} />
                </Grid>
            ))}
        </Grid>
    )
}

const Listing = ({
    route,
    dataSource,
    attributes,
    filters,
    subHeaderButton,
}) => {
    const { t } = useTranslation()

    const fallbackMessage = t('MyIdentity_NoIdentitiesFound')

    const isSmallScreen = useIsSmallScreen()

    const [showCardView, toggleShowCardView] = useState(false)
    const [openFilters, setOpenFilters] = useState(false)

    const [{ sortBy, sortOrder }, dispatch] = useAppState()

    const searchIdentity = (text) => {
        dispatch({
            type: 'SEARCH_IDENTITIES',
            payload: text,
        })
    }

    const controls = useGetControlsAccess().map((c) => c.name)

    const hasAccessToAdvancedSearchFilters =
        filters && controls.includes(filters[0].requireAccess?.control)

    const filteredAttributes = attributes.filter(
        (a) =>
            isNilOrEmpty(a.requireAccess?.control) ||
            controls.includes(a.requireAccess?.control),
    )
    const { list, loading, loadingMore, infiniteScrollObervableDiv } =
        useListingData(dataSource.url)

    const renderComponent = (name) => {
        const DynamicComponentToRender = DynamicComponents[name]

        return <DynamicComponentToRender />
    }

    const subHeaderButtonComponent =
        subHeaderButton &&
        (controls.includes(subHeaderButton.requireAccess.control) ? (
            renderComponent(subHeaderButton.name)
        ) : (
            <></>
        ))

    return (
        <MyIdentityLayout
            handleSearch={searchIdentity}
            subHeader={
                <SubHeader
                    showNavTabs={true}
                    showCardView={showCardView}
                    toggleCardView={(showCardView) =>
                        toggleShowCardView(showCardView)
                    }
                    subHeaderButton={subHeaderButtonComponent}
                    showFilters={openFilters}
                    toggleFilters={() => setOpenFilters((prev) => !prev)}
                    showFiltersButton={hasAccessToAdvancedSearchFilters}
                />
            }
        >
            <Box
                marginTop="20px"
                width="100%"
                display="flex"
                position="relative"
                paddingX="10px"
                paddingTop={
                    !isSmallScreen ? `${subHeaderHeight + 15}px` : '0px'
                }
            >
                {loading && <Loader margin="auto" />}

                {list && (
                    <>
                        {isSmallScreen &&
                            renderPeopleGrid(list, route, fallbackMessage)}

                        {!isSmallScreen && (
                            <>
                                {showCardView ? (
                                    <TableContainer openFilters={openFilters}>
                                        {renderPeopleGrid(
                                            list,
                                            route,
                                            fallbackMessage,
                                        )}
                                    </TableContainer>
                                ) : (
                                    <TableContainer openFilters={openFilters}>
                                        <TableView
                                            fallbackMessage={fallbackMessage}
                                            headings={filteredAttributes}
                                            route={route}
                                            data={list}
                                            sort={(sortBy, sortOrder) => {
                                                dispatch({
                                                    type: 'SORT_IDENTITIES',
                                                    payload: {
                                                        sortBy,
                                                        sortOrder,
                                                    },
                                                })
                                            }}
                                            sortBy={sortBy}
                                            sortOrder={sortOrder}
                                        />
                                    </TableContainer>
                                )}
                            </>
                        )}
                    </>
                )}
                <Box style={{ position: 'absolute', right: 0 }}>
                    <Collapse in={openFilters} timeout={300} unmountOnExit>
                        <FiltersColumn openFilters={openFilters}>
                            <FilterCard
                                fontColor="black"
                                cardTitle={t('MyIdentity_AdvancedSearch')}
                                icon={
                                    <Icon
                                        name="AdvancedSearch"
                                        color="#959598"
                                    />
                                }
                            >
                                <AdvancedFilter
                                    forms={filters && filters[0].forms}
                                    tags={null}
                                />
                            </FilterCard>
                        </FiltersColumn>
                    </Collapse>
                </Box>
            </Box>
            {infiniteScrollObervableDiv}
            {loadingMore && <Loader margin="auto" />}
        </MyIdentityLayout>
    )
}

export default Listing
