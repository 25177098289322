import React from 'react'
import { makeStyles, Fab } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        textTransform: 'capitalize',
        fontSize: '14px',
        lineHeight: 1.2,
        height: '31px !important',
        color: '#ffffff ',
        margin: '8px 0px !important',
        overflowWrap: 'anywhere',
        backgroundColor: '#ff4d5f ',
        boxShadow: 'none',
        width: '100% !important',
        '& svg': {
            transform: 'translateY(-50%) rotate(-180deg) ',

            position: 'absolute',
            left: '12px',
            top: '50%',
        },
        '&:hover': {
            backgroundColor: '#ff4d5f ',
        },

        ...props.styles,
    }),
    disabled: {
        backgroundColor: '#f7f8fa !important',
        border: '1px solid #8b909a !important',

        color: '#8b909a !important',
        '& svg': {
            color: '#8b909a !important',
        },
    },
}))
const RemoveButton = ({
    styles,
    active,
    iconName,
    startIcon,
    children,
    activeStyle,
    ...rest
}) => {
    const classes = useStyles({ styles })
    return (
        <Fab variant="extended" size="small" classes={classes} {...rest}>
            {startIcon && (
                <Icon
                    name={iconName ? iconName : 'SmallArrow'}
                    color={styles?.color ? styles.color : '#ffffff'}
                    className={classes.icon}
                ></Icon>
            )}
            {children}

            {!startIcon && (
                <Icon
                    name={iconName ? iconName : 'SmallArrow'}
                    color={styles?.color ? styles.color : '#ffffff'}
                    className={classes.icon}
                ></Icon>
            )}
        </Fab>
    )
}

export default RemoveButton
