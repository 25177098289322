import React, { useEffect, useState } from 'react'
import {
    Box,
    Modal,
    makeStyles,
    Typography,
    Grid,
    Link,
    withStyles,
    Dialog,
    DialogContent,
} from '@material-ui/core'
import { ReactComponent as CloseIconSvg } from 'icons/close-icon.svg'
import CustomButton from 'components/CustomButton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    modal: {
        '-webkit-backdrop-filter': 'blur(4px)',
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(0, 0, 0, 0.15)',
    },

    modalContentWrapper: {
        padding: '27px 46px 54px 50px',
        background: '#f7f8fa',
        maxWidth: 796,
        border: 'solid 1px #ebebed',
        borderRadius: '5px',
        boxShadow: '0 0 13px 0 rgba(0, 0, 0, 0.15)',
        [theme.breakpoints.down('xs')]: {
            padding: '27px 30px 54px 30px',
        },
    },

    closeButton: {
        position: 'absolute',
        top: 16,
        right: 16,
    },

    modalContent: {
        padding: '40px 0px 42px 0px',
        minHeight: '325px',
    },

    headerTypo: {
        fontWeight: 'bold',
        marginLeft: 9,
    },
}))

const MessageTypography = withStyles({
    root: {
        fontSize: '14px',
        fontWeight: 'normal',
        padding: '0px 10px 20px',
    },
    body1: {
        color: '#028f09',
    },
    body2: {
        color: '#ff0000',
    },
})(Typography)

export default function EditModal({
    open,
    onClose,
    header,
    body,
    loading,
    data,
    updateData,
    handleDiscard,
    actions,
    unsavedChanges,
    response,
}) {
    const classes = useStyles()
    const [state, setState] = useState({})

    useEffect(() => {
        //get data
        setState(data)
    }, [data])

    const saveLabel =
        actions && actions[0] && actions[0].label
            ? actions[0].label
            : 'MyIdentity_SaveChanges'
    const { t } = useTranslation()
    return (
        <div>
            <Dialog
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                maxWidth="md"
                open={open}
                onClose={onClose}
                scroll="body"
                className={classes.modal}
            >
                <DialogContent className={classes.modalContentWrapper}>
                    <Box className={classes.modalHeader}>
                        <Typography variant="h5" className={classes.headerTypo}>
                            {t(`${header}`)}
                        </Typography>
                        <Box className={classes.closeButton}>
                            <Link component="button" onClick={onClose}>
                                <CloseIconSvg></CloseIconSvg>
                            </Link>
                        </Box>
                    </Box>
                    <Box className={classes.modalContent}>
                        <Box>{body}</Box>
                    </Box>
                    <Box>
                        <MessageTypography
                            variant={
                                response && response.success ? 'body1' : 'body2'
                            }
                        >
                            {response && response.message}
                        </MessageTypography>
                    </Box>
                    <Box className={classes.actionArea}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <CustomButton
                                    label={
                                        actions &&
                                            actions[1] &&
                                            actions[1].label
                                            ? actions[1].label
                                            : t('MyIdentity_SaveChanges')
                                    }
                                    onClick={updateData}
                                    active={true}
                                    disabled={loading || !unsavedChanges}
                                    loading={loading}
                                />
                            </Grid>

                            <Grid item>
                                <CustomButton
                                    label={
                                        actions &&
                                            actions[1] &&
                                            actions[1].label
                                            ? actions[1].label
                                            : unsavedChanges
                                                ? t('MyIdentity_DiscardChanges')
                                                : t('MyIdentity_Close')
                                    }
                                    width={150}
                                    onClick={
                                        unsavedChanges ? handleDiscard : onClose
                                    }
                                    active={
                                        actions &&
                                        actions[0] &&
                                        actions[0].active
                                    }
                                    disabled={loading}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}
