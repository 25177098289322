import { Row, useStyles } from './utils'
import { Box } from '@material-ui/core'

export const NotificationsSection = (props) => {
    const { section, notifications, handleUpdate, updatingItem } = props

    const classes = useStyles()

    return (
        <>
            <Box className={classes.sectionTitle} paddingY="16px">
                {section}
            </Box>

            <Box className={classes.container}>
                {notifications.map((n) => (
                    <Row
                        key={n.id}
                        label={n.localizedDefaultPreferenceFriendlyName}
                        description={n.localizedDefaultPreferenceDescription}
                        active={updatingItem.id === n.id ? false : n.active}
                        loading={updatingItem.id === n.id}
                        onChange={() => handleUpdate(n, !n.active)}
                    />
                ))}
            </Box>
        </>
    )
}
