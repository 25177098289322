import { styled } from '@material-ui/core'

const EndAdornmentContainer = styled('div')({
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    msTransform: 'translate(-50%, -50%)',
    top: '50%',
    right: '8px',
    display: 'flex',
    cursor: 'pointer',
})

export default EndAdornmentContainer
