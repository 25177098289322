import React from 'react'
import { Box, makeStyles, Typography, Link } from '@material-ui/core'
import { CloseIcon, WarningIcon } from '../../eid-icons'
import WarningImageSvg from './Resources/warning-modal-background-image.svg'
import { ButtonV2 } from 'packages/eid-ui'

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        borderRadius: '5px',
        maxHeight: '296px',
        maxWidth: '600px',

        [theme.breakpoints.down('sm')]: {
            maxWidth: '350px',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 25px',
        backgroundColor: '#ff5e65',
        minHeight: '40px',
    },
    content: {
        backgroundColor: '#ffffff',
        backgroundImage: `url(${WarningImageSvg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left bottom',
        padding: '25px',
    },
    title: {
        fontSize: '18px',
        color: '#ffffff',
    },
    description: {
        color: '#282828',
        fontSize: '16px',
    },
    actionArea: {
        display: 'flex',
        marginTop: '20px',
    },
    icon: { paddingTop: '5px' },
}))

const buttonStyles = {
    minWidth: '60px',
    padding: '0 6px',
    width: '100%',
}

const WarningPrompt = ({
    title,
    description,
    proceed,
    proceeding,
    cancel,
    yesLabel,
    noLabel,
}) => {
    const classes = useStyles()
    return (
        <Box className={classes.paper}>
            <Box className={classes.header}>
                <Typography className={classes.title}>{title}</Typography>
                <Link component="button" onClick={cancel}>
                    <CloseIcon color="#ffffff" />
                </Link>
            </Box>
            <Box display="flex" className={classes.content}>
                <Box className={classes.icon}>
                    <WarningIcon color="#FF5E65" />
                </Box>
                <Box maxWidth="350px" marginLeft="60px" marginRight="60px">
                    <Typography className={classes.description}>
                        {description}
                    </Typography>
                    <Box className={classes.actionArea}>
                        <Box marginRight="15px">
                            <ButtonV2
                                label={noLabel}
                                border="solid 1px #8b909a"
                                borderRadius="5px"
                                fontColor="#8b909a"
                                fontSize="16px"
                                height="40px"
                                onClick={cancel}
                                style={buttonStyles}
                            />
                        </Box>
                        <Box marginRight="15px">
                            <ButtonV2
                                loading={proceeding}
                                label={yesLabel}
                                bgColor=" #ff5e65"
                                fontColor="#ffffff"
                                fontSize="16px"
                                borderRadius="5px"
                                height="40px"
                                onClick={proceed}
                                style={buttonStyles}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default WarningPrompt
