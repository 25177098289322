import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Button } from 'components'

const ViewProfileButton = ({ data }) => {
    const { t } = useTranslation()
    const history = useHistory()

    const {
        location: { pathname },
    } = history

    return (
        <Button.ViewProfile
            onClick={() => history.push(`${pathname}/${data.id}`)}
        >
            {t('MyIdentity_ViewProfile')}
        </Button.ViewProfile>
    )
}

export default ViewProfileButton
