import React from 'react'
import { Link } from '@material-ui/core'
import { isTruthy } from 'packages/core'
import Item from './Item'
import Divider from './Divider'

const IconLink = ({
    leftDivider,
    logo,
    children,
    rightDivider,
    style,
    ...rest
}) => {
    return (
        <Item style={{ minWidth: isTruthy(logo) ? '80px' : '56px', ...style }}>
            {leftDivider && <Divider side="left" />}
            <Link
                {...rest}
                underline="none"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'visible',
                }}
            >
                {children}
            </Link>
            {rightDivider && <Divider side="right" />}
        </Item>
    )
}

IconLink.defaultProps = {}

export default IconLink
