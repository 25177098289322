import React from 'react'
import { ArrowIcon } from 'packages/eid-icons'
import { EidAutocomplete } from 'packages/eid-controls'
import { useTranslation } from 'react-i18next'
import config from 'config'

const OptionComponent = EidAutocomplete.getOptionComponent({
    type: 'person',
    cdnUrl: config.BASE_EID_URL,
})

const queryParams = {
    take: 10,
}

const getOptionLabel = (option) => option.friendlyName

function PeopleSearch({ value, ...rest }) {
    const { t } = useTranslation()
    return (
        <EidAutocomplete
            label={t('MyIdentity_SomeoneElse')}
            placeholder={t('MyIdentity_SelectPerson')}
            url="/api/people"
            queryParams={queryParams}
            dedupingInterval={60000}
            value={value ? value : null}
            getOptionLabel={getOptionLabel}
            endAdornmentComponent={() => (
                <ArrowIcon direction="right" color="#453fbb" />
            )}
            optionComponent={OptionComponent}
            {...rest}
        />
    )
}

export default PeopleSearch
