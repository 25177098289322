import React from 'react'
import {
    Drawer,
    Backdrop,
    makeStyles,
    Box,
    Divider,
    styled,
} from '@material-ui/core'
import { withRouter } from 'react-router'
import { MobileMenuIcon } from 'packages/eid-icons'
import Menu from './Menu'
import ContentList from './Menu/ContentList'
import ContentFooter from './Menu/ContentFooter'
import ContentHeader from './Menu/ContentHeader'

const ContentWrapper = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: '0px 15px',
    flexDirection: 'column',
    paddingTop: '10px',
    paddingBottom: '45px',
}))

const buttonStyles = {
    position: 'absolute',
    top: '50%',
}

const useStyles = makeStyles({
    paper: {
        display: 'flex',
        flexDirection: 'row',
        overflow: 'visible',
        visibility: 'visible !important',
        padding: '40px 0px 0px 0px',
        width: '268px',
    },
})

const GlobalMenuMobile = ({
    routes,
    colorCodes,
    currentSection,
    eid,
    apps,
    loading,
    current,
    settings,
    children,
}) => {
    const iconMenuProps = { eid, apps, loading, current }

    const classes = useStyles()
    const [open, setOpen] = React.useState('hide')
    const handleClose = () =>
        setOpen((prev) => (prev === 'show' ? 'hide' : 'show'))

    return (
        <>
            {open === 'show' && (
                <Backdrop
                    open={open === 'show'}
                    onClick={() => setOpen('hide')}
                />
            )}

            <Drawer
                classes={classes}
                variant="persistent"
                anchor="left"
                open={open === 'show'}
                onClose={() => setOpen('hide')}
            >
                <Menu {...iconMenuProps} />

                {children ? (
                    children(handleClose)
                ) : (
                    <ContentWrapper>
                        <ContentHeader
                            currentApp={current}
                            currentSection={currentSection}
                            onClick={() => setOpen('hide')}
                            style={{ color: colorCodes.primary }}
                        />

                        {routes && (
                            <ContentList
                                data={routes}
                                handleMenuClose={() =>
                                    setOpen((prev) =>
                                        prev === 'show' ? 'hide' : 'show',
                                    )
                                }
                            />
                        )}

                        <Divider variant="middle" />

                        <ContentFooter
                            colorCodes={colorCodes}
                            settings={settings}
                            data={[
                                {
                                    name: 'Sign Out',
                                    icon: 'LogOut',
                                    path: '/signout',
                                },
                            ]}
                        />
                    </ContentWrapper>
                )}

                <MobileMenuIcon
                    style={{
                        ...buttonStyles,
                        right: open === 'show' ? '-33px' : '-32px',
                    }}
                    color="#000000"
                    onClick={() =>
                        setOpen((prev) => (prev === 'show' ? 'hide' : 'show'))
                    }
                />
            </Drawer>
        </>
    )
}

export default withRouter(GlobalMenuMobile)
