import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, withStyles } from '@material-ui/core'
import { icon } from './resources'

const styles = (theme) => ({})

const ToolTip = withStyles(styles)(({ classes, iconWidth, ...rest }) => {
    return (
        <Tooltip {...rest}>
            <img width={iconWidth} src={icon} alt="" />
        </Tooltip>
    )
})

ToolTip.propTypes = {
    ...ToolTip.propTypes,
    iconWidth: PropTypes.string,
}

ToolTip.defaultProps = {
    ...ToolTip.defaultTypes,
    iconWidth: 'auto',
}

export default ToolTip
