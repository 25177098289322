import Image3 from './Resources/image-3.png'
import { Link, Box, makeStyles, Typography } from '@material-ui/core'
import config from 'config'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: ' #eef0f4',
        textAlign: 'center',
        '& img': {
            marginTop: '6vw',
            margin: 'auto',
            maxWidth: '100%',
        },
    },
    content: {
        [theme.breakpoints.up('md')]: {
            marginTop: '-15%',
        },
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        flexWrap: 'wrap',
        textAlign: 'center',
        background: 'transparent',
        left: 0,
        right: 0,
    },
    footer: {
        maxWidth: 550,
        marginTop: '-45px',
        margin: 'auto',
        left: 0,
        right: 0,
    },
    notFoundContainer: {
        marginTop: '-65px',
        [theme.breakpoints.down('lg')]: {
            marginTop: '-30px',
        },
    },
    notFoundText: {
        color: ' #3f3767',
        fontWeight: '300',
        fontSize: '16vw',
    },
}))

const NotFoundPage = (props) => {
    const { description, linkText, errorCode } = props
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <img src={Image3} alt="Not Found" />

            <Box className={classes.content}>
                <Box className={classes.notFoundContainer}>
                    <Typography className={classes.notFoundText}>
                        {errorCode}
                    </Typography>
                </Box>
            </Box>
            <Box className={classes.footer}>
                <Box marginTop="10vh">
                    <Box>
                        <Typography style={{ fontSize: '22px' }}>
                            {description}
                        </Typography>
                    </Box>
                    <Box marginTop="20px">
                        <Link
                            component="a"
                            underline="none"
                            href={`${config.APP_SUBPATH}/`}
                        >
                            <Typography
                                style={{
                                    color: '#453fbb',
                                    fontSize: '22px',
                                }}
                            >
                                {linkText}
                            </Typography>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default NotFoundPage
