import React from 'react'
import { Fab as MuiFab } from '@material-ui/core'
import PropTypes from 'prop-types'

function Fab({ loading, icon, size, children, ...rest }) {
    return (
        <MuiFab size={size} disabled={loading} {...rest}>
            {loading && <></>}
            {!loading && icon}
            {!loading && children}
        </MuiFab>
    )
}

Fab.propTypes = {
    ...MuiFab.propTypes,
    loading: PropTypes.bool,
    icon: PropTypes.any,
}

Fab.defaultProps = {
    ...MuiFab.defaultProps,
    loading: false,
    size: 'medium',
}

export default Fab
