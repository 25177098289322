import { styled, Box } from '@material-ui/core'

const PaperComponent = styled(Box)({
    marginTop: '7px',
    '& > ul': {
        maxHeight: '30vh',
    },
})

export default PaperComponent
