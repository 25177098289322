import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab'
import DefaultPaper from './Paper'
import DefaultListbox from './Listbox'
import DefaultLabel from './Label'
import DefaultOption from './Option'
import DefaultTag from './Tag'
import DefaultInputWrapper from './InputWrapper'
import DefaultEndAdornmentContainer from './EndAdornmentContainer'
import Spinner from '../Spinner'

const useStyles = makeStyles(() => ({
    root: ({ rootStyleProps }) => ({
        width: '100%',
        ...rootStyleProps,
    }),
    focused: {
        '& > div': {
            borderColor: '#453fbb',
        },
    },
}))

const fullWidthStyles = { width: '100%' }

function Autocomplete(props) {
    const {
        label,
        loading,
        options,
        getOptionLabel,
        disabled,
        placeholder,
        labelComponent: Label,
        optionComponent: Option,
        tagComponent: Tag,
        inputWrapperComponent: InputWrapper,
        endAdornmentContainerComponent: EndAdornmentContainer,
        endAdornmentComponent: EndAdornment,
        clearIcon,
        value,
        rootStyleProps,
        ...rest
    } = props
    const classes = useStyles({ rootStyleProps })
    return (
        <MuiAutocomplete
            classes={classes}
            loadingText=""
            noOptionsText=""
            disablePortal={false}
            loading={loading}
            options={options}
            getOptionLabel={getOptionLabel}
            disabled={disabled}
            value={value}
            filterOptions={(options) => options}
            renderOption={(option, optionState) => (
                <Option
                    option={option}
                    optionState={optionState}
                    getOptionLabel={getOptionLabel}
                />
            )}
            getOptionSelected={(option, value) =>
                getOptionLabel(option) === getOptionLabel(value)
            }
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Tag
                        label={getOptionLabel(option)}
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderInput={(params) => {
                return (
                    <>
                        {label && (
                            <Label {...params.InputLabelProps}>{label}</Label>
                        )}
                        <InputWrapper
                            ref={params.InputProps.ref}
                            style={{
                                paddingRight:
                                    (EndAdornment || clearIcon) && '55px',
                            }}
                        >
                            {params.InputProps.startAdornment}
                            <input
                                {...params.inputProps}
                                placeholder={placeholder}
                                style={fullWidthStyles}
                            />
                            {loading && (
                                <EndAdornmentContainer>
                                    <Spinner size={0.7} color="#453fbb" />
                                </EndAdornmentContainer>
                            )}
                            {!loading && EndAdornment && (
                                <EndAdornmentContainer>
                                    <EndAdornment />
                                </EndAdornmentContainer>
                            )}
                            {!loading && clearIcon && (
                                <EndAdornmentContainer>
                                    {clearIcon}
                                </EndAdornmentContainer>
                            )}
                        </InputWrapper>
                    </>
                )
            }}
            {...rest}
        />
    )
}

Autocomplete.defaultProps = {
    PaperComponent: DefaultPaper,
    ListboxComponent: DefaultListbox,
    labelComponent: DefaultLabel,
    optionComponent: DefaultOption,
    tagComponent: DefaultTag,
    inputWrapperComponent: DefaultInputWrapper,
    endAdornmentContainerComponent: DefaultEndAdornmentContainer,
}

Autocomplete.PaperComponent = DefaultPaper
Autocomplete.ListboxComponent = DefaultListbox
Autocomplete.labelComponent = DefaultLabel
Autocomplete.optionComponent = DefaultOption
Autocomplete.tagComponent = DefaultTag
Autocomplete.inputWrapperComponent = DefaultInputWrapper
Autocomplete.endAdornmentContainerComponent = DefaultEndAdornmentContainer

export default Autocomplete
