import React from 'react'
import { Link } from '@material-ui/core'
import { useHistory } from 'react-router'


const CellLink = ({ data, value,styles }) => {
    const history = useHistory()
    const style = {
        textAlign: 'left',
        ...styles
    }
    const {
        location: { pathname },
    } = history

    return (
        <Link
            component="button"
            onClick={() => history.push(`${pathname}/${data.id}`)}
            style={style}
        >
            {value}
        </Link>
    )
}

export default CellLink
