import React from 'react'
import { Box, makeStyles, Typography, Dialog } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { IconButton } from 'packages/eid-ui'
import { mobileScreenWidth } from 'utils'

const useStyles = makeStyles((theme) => ({
    modal: () => ({
        backgroundColor: '#f7f8fa',
        borderRadius: '8px',
        boxShadow: '0 0 13px 0 rgba(0, 0, 0, 0.15)',
        padding: '24px 40px',
        '&:focus': {
            outline: 'none',
        },

        [`@media (max-width:${mobileScreenWidth})`]: {
            padding: '24px 16px',
        },
    }),
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        maxHeight: '12%',
        padding: '16px 0px',
    },
    closeIcon: {
        display: 'flex',
        marginRight: '-16px',
        marginTop: '-16px',
        height: '10px',
    },

    title: {
        fontSize: '24px',
        fontWeight: 'normal',
        color: '#282828',
    },
}))

const useDialogStyles = makeStyles((theme) => ({
    paper: {
        [`@media (max-width:${mobileScreenWidth})`]: {
            margin: '8px',
            width: 'calc(100% - 16px) !important',
            maxWidth: 'calc(100% - 16px) !important ',
        },
    },

    paperWidthSm: {
        maxWidth: '760px',
    },
}))

const Modal = ({ open, onClose, title, children }) => {
    const classes = useStyles()
    const dialogClasses = useDialogStyles()

    return (
        <Dialog
            fullWidth
            scroll={'body'}
            open={open}
            onClose={onClose}
            aria-labelledby={title}
            aria-describedby={title}
            maxWidth="sm"
            classes={dialogClasses}
        >
            <Box className={classes.modal}>
                <Box className={classes.modalHeader}>
                    <Typography className={classes.title}>{title}</Typography>
                    <Box className={classes.closeIcon}>
                        <IconButton onClick={onClose}>
                            <Icon name="Close" color="#959598" />
                        </IconButton>
                    </Box>
                </Box>

                {children}
            </Box>
        </Dialog>
    )
}

export default Modal
