import React, { useReducer, useEffect } from 'react'
import { Box, styled, makeStyles, List, ListItem } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { useTranslation } from 'react-i18next'

const StyledList = styled(List)({
    width: '100%',
    height: '100%',
    padding: '0px 4px',
    border: '1px solid #EBEBED',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    overflow: 'auto',
})

const useListItemStyles = makeStyles({
    root: {
        paddingLeft: '8px',
        paddingRight: '8px',
        color: '#5d6870',
        fontSize: '14px',
        lineHeight: 1.16,
        borderRadius: '5px',
        margin: '4px 0px',
        '&:hover': {
            backgroundColor: '#f8f8f8',
            cursor: 'pointer',
        },
    },
    selected: {
        backgroundColor: '#307fc1 !important',
        color: '#ffffff',
    },
})

const styles = {
    checkIcon: {
        position: 'absolute',
        right: '10px',
    },
}

const SelectionList = ({ options, selectedItems, onChange, variant }) => {
    const listItemClasses = useListItemStyles()

    const handleClick = (item) => {
        const itemIndex = selectedItems.findIndex((i) => i.id === item.id)

        let newSelected = []

        if (itemIndex === -1) {
            newSelected = newSelected.concat(selectedItems, item)
        } else if (itemIndex === 0) {
            newSelected = newSelected.concat(selectedItems.slice(1))
        } else if (itemIndex === selectedItems.length - 1) {
            newSelected = newSelected.concat(selectedItems.slice(0, -1))
        } else if (itemIndex > 0) {
            newSelected = newSelected.concat(
                selectedItems.slice(0, itemIndex),
                selectedItems.slice(itemIndex + 1),
            )
        }

        onChange(newSelected)
    }

    const isSelected = (item) =>
        selectedItems.findIndex((i) => i.id === item.id) !== -1

    return (
        <>
            <StyledList>
                {options.map((item) => (
                    <ListItem
                        classes={listItemClasses}
                        key={item.name}
                        onClick={() => handleClick(item)}
                        selected={isSelected(item)}
                    >
                        {item.name}

                        {variant !== 'secondary' && isSelected(item) && (
                            <Icon
                                name="Check"
                                color="#ffffff"
                                style={styles.checkIcon}
                            />
                        )}

                        {variant === 'secondary' && (
                            <Icon
                                name="Check"
                                color={isSelected(item) ? '#ffffff' : '#8b909a'}
                                style={styles.checkIcon}
                            />
                        )}
                    </ListItem>
                ))}
            </StyledList>
        </>
    )
}

export default SelectionList
