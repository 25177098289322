import React, {
  useEffect,
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
} from 'react';
import { makeStyles } from '@material-ui/core';
import { fabric } from 'fabric';

const useStyles = makeStyles({
  customContainer: {
    position: 'relative',
    overflow: 'hidden',
    '&::after': {
      width: '180px',
      height: '180px',
      borderRadius: '100%',
      pointerEvents: 'none',
      border: '2px solid #fff',
      position: 'absolute',
      content: "''",
      boxShadow: '0 0 0 100vw rgba(0,0,0,.5)',
      top: '-65px',
      left: 0,
      right: 0,
      margin: 'auto',
      bottom: 0,
    },
  },
  toolTip: {
    position: 'relative',
    top: '-83px',
    color: 'white',
    zIndex: '2',
    fontSize: '14px',
    cursor: 'context-menu',
  },
});

const ImageEditor = forwardRef((props, ref) => {
  const classes = useStyles();
  const clippingCircleLeftPrimary = 134;
  const clippingCircleLeftSecondary = 58;
  const clippingCircleTop = 27;
  const clippingCircleRadius = 90.5;
  const editorCanvasBreakPoint = 450;
  let [editorWidth, editorHeight] = useEditorSize();
  let clippingCircleConfig = {
    id: "clippingCircle",
    top: clippingCircleTop,
    radius: clippingCircleRadius,
    fill: 'transparent',
    opacity: 1,
    objectCaching: false, //<-- set this
  };
  
  const { srcFile, scale, straighten } = props;

  const [state, setState] = useState({
    canvas: null,
    clippingCircle: null,
    reader: null,
    img: null,
    imgInstance: null,
  });

  const ImgScalingData = (canvas, img) => {
    let scaleX, scaleY, instanceWidth, instanceHeight, left, top,
      canvasHeight, canvasWidth;
    
    //always scale image according to full size canvas
    canvasWidth = 450;
    canvasHeight = 307;

    instanceWidth = img.width;
    instanceHeight = img.height;

    // show complete image in editor
    let aspectRatio = instanceWidth / instanceHeight;
    if (aspectRatio > 1) {
      scaleX = canvasWidth / instanceWidth;
      scaleY = scaleX;
    } else {
      scaleY = (canvasHeight + 100) / instanceHeight;
      scaleX = scaleY;
    }

    top = canvas.getHeight() / 2 - (instanceHeight * scaleY) / 2 - 35;
    left = canvas.getWidth() / 2 - (instanceWidth * scaleX) / 2;
    return {
      scaleX, scaleY, instanceWidth, instanceHeight, left, top
    };
  };

  const clipImage = () => {
    let tX, tY, cropX, cropY;
    clippingCircleConfig.left = clippingCircleLeftPrimary;
    if(window.innerWidth < editorCanvasBreakPoint){
      clippingCircleConfig.left = clippingCircleLeftSecondary;  
    }
    let newClippingCircle = new fabric.Circle(clippingCircleConfig);
    state.canvas.add(newClippingCircle);

    tX = -1 * clippingCircleConfig.left;
    tY = -1 * clippingCircleConfig.top;
    state.imgInstance.clipTo = function(ctx) {
      ctx.restore();
      ctx.save();
      ctx.setTransform(1, 0, 0, 1, tX, tY);
      newClippingCircle.render(ctx);
      ctx.restore();
    };

    cropX = (-1 * (state.imgInstance.left)) + clippingCircleConfig.left;
    cropY = (-1 * (state.imgInstance.top)) + clippingCircleConfig.top;
    let cropperSettings = {
      format: 'png',
      height: 181,
      width: 181,
      left: cropX,
      top: cropY,
    };

    return state.imgInstance.toDataURL(cropperSettings);
  };

  const resetImageEditor = () => {
    // reset image position
    let img = state.canvas.getObjects().find(function(elem){
      return elem.id === "mainImage";
    });
    let {scaleX, scaleY, instanceWidth, instanceHeight, left, top} = 
      ImgScalingData(state.canvas, img);
    img.set({
      top : top,
      left : left,
    });
    state.canvas.renderAll();
  };

  const checkObjectMove = (e) => {
    var img = e.target;
    let maxLeft, minLeft, minTop, maxTop;
    maxLeft = clippingCircleLeftPrimary;
    if(window.innerWidth < editorCanvasBreakPoint){
      maxLeft = clippingCircleLeftSecondary;  
    }

    minLeft = maxLeft - ((img.width * img.scaleX) - (2 *clippingCircleRadius));
    maxTop = clippingCircleTop;
    minTop = maxTop - ((img.height * img.scaleY) - (2 *clippingCircleRadius));
    if(img.id === "mainImage"){
      if(img.left > maxLeft){
        img.left = maxLeft
      }

      if(img.left < minLeft){
        img.left = minLeft
      }

      if(img.top > maxTop){
        img.top = maxTop;
      }

      if(img.top < minTop){
        img.top = minTop;
      }
    }
  };

  useImperativeHandle(ref, () => ({
    getEdittedImage() {
      return clipImage();
    },
    resetImage() {
      resetImageEditor();
    },
  }));


  function useEditorSize() {
    let size, setSize, width, height;
    if(window.innerWidth < editorCanvasBreakPoint){
      width = 300;
      height = 307;
    }
    else{
      width = 450;
      height = 307;
    }
    [size, setSize] = useState([width, height]);
    useLayoutEffect(() => {
      function updateEditorSize() {
        if(window.innerWidth < editorCanvasBreakPoint){
          setSize([300, 307]);
        }
        else{
          setSize([450, 307]); 
        }
      }
      window.addEventListener('resize', updateEditorSize);
      updateEditorSize();
      return () => window.removeEventListener('resize', updateEditorSize);
    }, []);
    return size;
  }

  const addImageToCanvas = useCallback(
    (config = {}) => {
      const { scale = 1, straighten = 0 } = config;
      let newCanvas = state.canvas;

      if (!state.canvas) {
        newCanvas = new fabric.Canvas('myCanvas', {
          preserveObjectStacking: true,
        });
      } else {
        state.canvas.clear().renderAll();
      }

      let reader = new FileReader();
      let img, imgInstance;
      reader.onload = function(event) {
        img = new Image();
        img.onload = function() {
          
          let {scaleX, scaleY, instanceWidth, instanceHeight, left, top} = 
            ImgScalingData(newCanvas, img);
          scaleX = scaleX + (scale - 1);
          scaleY = scaleY + (scale - 1);

          imgInstance = new fabric.Image(img, {
            id: "mainImage",
            width: instanceWidth,
            height: instanceHeight,
            angle: straighten,
            top: top,
            left: left,
            scaleX: scaleX,
            scaleY: scaleY,
            hasControls: false,
            hasBorders: false,
          });

          newCanvas.add(imgInstance);
          newCanvas.renderAll();

          //bind move function
          newCanvas.observe("object:moving", checkObjectMove);

          setState({
            ...state,
            canvas: newCanvas,
            reader: reader,
            img: img,
            imgInstance: imgInstance,
          });
        };

        img.src = event.target.result;
      };

      reader.readAsDataURL(props.srcFile);
    },
    [props.srcFile, state],
  );

  useEffect(() => {
    const config = {
      straighten: props.straighten,
      scale: 1 + props.scale / 100,
    };
    addImageToCanvas(config);
    if(state.canvas){
      state.canvas.setWidth(editorWidth);
    }
  }, [srcFile, scale, straighten, editorWidth]);

  return (
    <div className={classes.customContainer}>
      <canvas
        id="myCanvas"
        width={`${editorWidth}px`}
        height={`${editorHeight}px`}
      />
      <span className={classes.toolTip}>Drag to reposition photo</span>
    </div>
  );
});

export default ImageEditor;
