import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import 'date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { CssBaseline, MuiThemeProvider } from '@material-ui/core'
import 'intersection-observer'
import { history, AuthContextProvider, authRoutes } from 'packages/core'
import { theme, Loader, NotificationsProvider } from 'packages/eid-ui'
import { Switch, Route } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import AppRoutes from 'containers/AppRoutes'
import './i18n'
import 'packages/eid-ui/globalStyles.css'
import config from 'config'
import { ReactQueryConfigProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query-devtools'
import MomentUtils from '@date-io/moment'
import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/es'
import './index.css'

const reactQueryConfig = {
    queries: {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    },
}

const MOUNT_NODE = document.getElementById('root')

const MainApp = (
    <React.StrictMode>
        <Router history={history}>
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <ReactQueryConfigProvider config={reactQueryConfig}>
                        <AuthContextProvider
                            config={{
                                settings: {
                                    authority: config.AUTHORITY_URL,
                                    client_id: config.CLIENT_ID,
                                    scope: config.SCOPE,
                                    response_type:
                                        config.RESPONSE_TYPE ?? 'code',
                                },
                                initialValue: {
                                    displayName: '',
                                    currentUserId: '',
                                },
                                extractAuthData: async (token) => {
                                    const rawResponse = await fetch(
                                        `${config.API_URL}/api/v1/me/user`,
                                        {
                                            headers: {
                                                Authorization: `Bearer ${token}`,
                                            },
                                        },
                                    )
                                    const response = await rawResponse.json()
                                    return {
                                        displayName: response.displayName,
                                        currentUserId: response.id,
                                    }
                                },
                            }}
                        >
                            <NotificationsProvider>
                                <Switch>
                                    {authRoutes.map((ar) => (
                                        <Route
                                            key={ar.path}
                                            exact
                                            path={ar.path}
                                            render={(props) => (
                                                <ar.component
                                                    {...props}
                                                    render={() => <Loader />}
                                                />
                                            )}
                                        />
                                    ))}
                                    <AppRoutes />
                                </Switch>
                            </NotificationsProvider>
                        </AuthContextProvider>
                    </ReactQueryConfigProvider>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </Router>
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
    </React.StrictMode>
)

ReactDOM.render(MainApp, MOUNT_NODE)

serviceWorker.unregister()
