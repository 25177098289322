import { useTheme, useMediaQuery } from '@material-ui/core'

/**
 * if the screen is less than or equal to 959.px
 *
 * @returns (bool)
 */
const useIsSmallScreen = () => {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
    return isSmallScreen
}

export default useIsSmallScreen
