import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withRouter } from 'react-router'
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Box,
    Avatar,
    Typography,
    styled,
    makeStyles,
    Link,
} from '@material-ui/core'
import appConfig from 'config'
import { TextFormatter } from 'components'
import { MIDPathToImage } from 'packages/core/helpers'
import { useTranslation } from 'react-i18next'

import * as DynamicComponents from 'components/DynamicComponents'

const colors = {
    primary: '#307fc1',
    grey: '#b4b4b4',
    border: '#f2f2f3',
}

const MyCard = styled(Card)({
    margin: 'auto',
    padding: '0px 10px 5px 10px',
})

const headerContentStyles = makeStyles((theme) => ({
    root: {
        flexDirection: 'column',
        padding: '10px 8px 10px 8px',
        borderBottom: '1px solid ' + colors.border,
    },

    avatar: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    content: {
        padding: '0px 35px 0px 35px',
        width: '100%',
        '& > span': {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '20px',
        },
        '@media (max-width:800px)': {
            padding: '0px 0px 0px 10px',
        },
    },
}))

const useStyles = makeStyles((theme) => ({
    header: {
        padding: '10px 8px 10px 8px',
        borderBottom: '1px solid ' + colors.border,
    },
    hr: {
        width: '92%',
        opacity: '0.4',
        borderColor: '#f5f5f5',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    content: {
        padding: '7px 0px',
        borderBottom: '1px solid ' + colors.border,
    },
    actions: {
        paddingBottom: '12px',
    },
    noBorder: {
        border: 'none',
    },
    avatar: {
        width: '54px',
        height: '54px',
    },

    table: {
        '& th': {
            color: colors.grey,
            fontWeight: 'normal',
            padding: '0px 5px',
        },
        '& td': {
            padding: '0px 5px',
        },
        tableLayout: 'fixed',
        width: '100%',
    },
    name: {
        fontSize: '15px',
        textAlign: 'left',
    },
    blueBold: {
        fontWeight: 'bold',
        color: '#307fc1',
        lineHeight: '1',
    },
    blue: {
        color: '#307fc1',
    },
    fieldsPadding: {
        padding: '0 7px',
    },
    fieldBlock: {
        width: '50%',
        '& div': {
            textAlign: 'left',
        },
        '&:first-child': {
            paddingRight: '10px',
            '& div': {
                float: 'right',
            },
        },
        '&:last-child': {
            paddingLeft: '10px',
        },
    },
}))

function DelegationCard({ data }) {
    const {
        delegateePathToImage,
        approvalFlowStepFriendlyName,
        delegateeFriendlyName,
        activeOnlyIfOutOfOffice,
    } = data
    const classes = useStyles()
    const { t } = useTranslation()
    const headerContentClass = headerContentStyles()

    function getDetailsTable(dataMap) {
        return (
            <table className={classes.table}>
                <thead>
                    <tr>
                        {Object.keys(dataMap).map((k, index) => {
                            return (
                                <th
                                    key={'dm-h-k' + index}
                                    style={{ textAlign: 'left' }}
                                >
                                    <Typography
                                        style={{ textTransform: 'uppercase' }}
                                        variant="subtitle1"
                                    >
                                        {k}
                                    </Typography>
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {Object.keys(dataMap).map((k, index) => {
                            return (
                                <td
                                    key={'dm-v-k' + index}
                                    style={{ textAlign: 'left' }}
                                    className={dataMap[k].class}
                                >
                                    <Typography
                                        className={dataMap[k].class}
                                        variant="body2"
                                    >
                                        {dataMap[k].value}
                                    </Typography>
                                </td>
                            )
                        })}
                    </tr>
                </tbody>
            </table>
        )
    }

    const delegationCard = (
        <>
            <CardHeader
                avatar={
                    <div style={{ position: 'relative' }}>
                        <Avatar
                            src={
                                delegateePathToImage &&
                                MIDPathToImage(
                                    appConfig.BASE_EID_URL,
                                    delegateePathToImage,
                                )
                            }
                            aria-label="recipe"
                            className={classes.avatar}
                        />
                    </div>
                }
                title={
                    <>
                        <Box className={classes.fieldBlock}>
                            <Box>
                                <Typography
                                    style={{
                                        textTransform: 'uppercase',
                                    }}
                                    variant="subtitle1"
                                >
                                    {t('MyIdentity_PersonName')}
                                </Typography>
                                <Typography variant="body2">
                                    <Link
                                        className={clsx(
                                            classes.blueBold,
                                            classes.name,
                                        )}
                                        component="button"
                                    >
                                        <TextFormatter
                                            value={delegateeFriendlyName}
                                            maxTextWidth={110}
                                        />
                                    </Link>
                                </Typography>
                            </Box>
                        </Box>
                        <Box className={classes.fieldBlock}>
                            <Box>
                                <Typography
                                    style={{
                                        textTransform: 'uppercase',
                                    }}
                                    variant="subtitle1"
                                >
                                    {t('MyIdentity_ApprovalStep')}
                                </Typography>
                                <Typography
                                    className={clsx(classes.blue, classes.name)}
                                    variant="body2"
                                >
                                    <TextFormatter
                                        value={approvalFlowStepFriendlyName}
                                        maxTextWidth={110}
                                    />
                                </Typography>
                            </Box>
                        </Box>
                    </>
                }
                classes={headerContentClass}
            />
            <CardContent className={classes.content}>
                {getDetailsTable({
                    [t('MyIdentity_StartDate')]: {
                        value: (
                            <DynamicComponents.CellDateTime
                                data={data}
                                attribute={{ name: 'startDateUtc' }}
                            />
                        ),
                        class: '',
                    },
                    [t('MyIdentity_EndDate')]: {
                        value: (
                            <DynamicComponents.CellDateTime
                                data={data}
                                attribute={{ name: 'endDateUtc' }}
                            />
                        ),
                        class: '',
                    },
                    [t('MyIdentity_OnlyWhenOutOfOffice')]: {
                        value: (
                            <TextFormatter
                                value={activeOnlyIfOutOfOffice}
                                maxTextWidth={110}
                            />
                        ),
                        class: '',
                    },
                })}
            </CardContent>

            <CardActions disableSpacing className={classes.actions}>
                <div
                    style={{
                        textAlign: 'center',
                        width: '100%',
                        padding: '5px 5px',
                        display: 'flex',
                        justifyContent: 'space-around',
                    }}
                >
                    <DynamicComponents.EditDelegateButton data={data} />

                    <DynamicComponents.DeleteDelegateButton data={data} />
                </div>
            </CardActions>
        </>
    )

    return <MyCard className={classes.card}>{delegationCard}</MyCard>
}

DelegationCard.propTypes = {
    data: PropTypes.object.isRequired,
}

export default withRouter(DelegationCard)
