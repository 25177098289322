import React from 'react'
import { Box } from '@material-ui/core'
import { Button, Modal, NewDelegationForm } from 'components'
import { useTranslation } from 'react-i18next'
import { useModalState } from 'packages/core'

const styles = {
    newDelegationButton: {
        cursor: 'pointer',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        minWidth: '140px',
    },
}

const CreateNewDelegateButton = () => {
    const { t } = useTranslation()
    const [modalOpen, openModal, closeModal] = useModalState()
    return (
        <>
            {modalOpen && (
                <Modal
                    title={t('MyIdentity_CreateNewDelegation')}
                    open={modalOpen}
                    onClose={closeModal}
                >
                    <NewDelegationForm
                        onSave={closeModal}
                        onCancel={closeModal}
                    />
                </Modal>
            )}
            <Box style={styles.newDelegationButton}>
                <Button.NewDelegation onClick={openModal}>
                    {t('MyIdentity_NewDelegation')}
                </Button.NewDelegation>
            </Box>
        </>
    )
}

export default CreateNewDelegateButton
