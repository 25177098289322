import React from 'react'
import { makeStyles, Fab } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'

const useStyles = makeStyles((theme) => ({
    root: (props) => ({
        margin: theme.spacing(1),
        textTransform: 'capitalize',
        fontSize: '14px',
        height: '31px !important',
        color: '#ffffff !important',
        backgroundColor: '#307fc1 !important',
        border: '1px solid #ffffff',
        margin: 0,
        boxShadow: 'none',
        minWidth: '65px !important',

        '&:hover': {
            color: '#307fc1 !important',
            backgroundColor: '#ffffff !important',
            borderColor: '#307fc1 !important',

            '& svg': {
                color: '#307fc1 !important',
            },
        },
        ...props.styles,
    }),
    icon: {
        color: '#ffffff',
        marginLeft: 6,
    },
}))
const NewDelegationButton = ({ color, styles, children, ...rest }) => {
    const classes = useStyles({ color, styles })
    return (
        <Fab variant="extended" size="small" className={classes.root} {...rest}>
            {children}
            <Icon name="SmallArrow" className={classes.icon}></Icon>
        </Fab>
    )
}

export default NewDelegationButton
