import React from 'react'
import { makeStyles, IconButton as MuiIconButton } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}))

const IconButton = (props) => <MuiIconButton classes={useStyles()} {...props} />

export default IconButton
