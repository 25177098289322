import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Button as MuiButton, withStyles } from '@material-ui/core'

const themes = {
    primary: {
        background: '#453fbb',
        highlight: '#453fbb',
        color: '#fff',
    },
    danger: {
        background: '#ff5e65',
        highlight: '#ff5e65',
        color: '#fff',
    },
}

const styles = {
    button: {
        '& svg:first-child': {
            marginRight: 10,
        },
        borderRadius: '5px',
        backgroundColor: (props) => themes[props.themeColor].background,
        '&:hover': {
            backgroundColor: (props) => themes[props.themeColor].highlight,
        },
        color: (props) => themes[props.themeColor].color,
    },

    loader: {
        marginRight: 10,
    },
}

const Button = withStyles(styles)(
    ({
        classes,
        loading,
        icon,
        size,
        children,
        className,
        themeColor,
        ...rest
    }) => {
        return (
            <MuiButton
                size={size}
                disabled={loading}
                {...rest}
                className={clsx(
                    !loading ? classes.button : undefined,
                    className,
                )}
            >
                {loading && <></>}
                {!loading && icon}
                {children}
            </MuiButton>
        )
    },
)

Button.propTypes = {
    ...MuiButton.propTypes,
    loading: PropTypes.bool.isRequired,
    icon: PropTypes.any,
    themeColor: PropTypes.string,
}

Button.defaultProps = {
    ...MuiButton.defaultProps,
    loading: false,
    size: 'medium',
}

export default Button
export { default as Fab } from './Fab'
export { default as ExtendedFab } from './ExtendedFab'
