import React from 'react'
import TreeItem from '@material-ui/lab/TreeItem'
import { makeStyles, Box, Typography, Radio, styled } from '@material-ui/core'
import { Icon } from '../../eid-icons'

const StyledRadio = styled(Radio)({
    padding: '2px',
    maxHeight: '20px',
    '&:hover': {
        backgroundColor: '#d2d2d9',
    },
})

const useStyles = makeStyles({
    root: {
        '&:focus > $content': {
            backgroundColor: 'inherit',
        },
        zIndex: 2000,
        '&:hover': {
            backgroundColor: 'inherit',
        },
    },
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        paddingLeft: '12px',
        marginLeft: 12,
        borderLeft: `1px dotted #eef0f4`,
    },
    content: {
        '&:hover': {
            backgroundColor: 'inherit',
        },
    },
    label: {
        width: 'max-content',
        borderRadius: '5px',
        padding: '5px',
        '&:hover': {
            backgroundColor: '#f1f1f4',
        },
        '& .search-highlight': {
            color: '#01745f',
            fontWeight: 600,
            textDecoration: 'underline',
        },
    },
})

const highlight = (text, searchKey) => {
    searchKey = new RegExp('(' + searchKey + ')', 'gi')
    return text.split(searchKey).map((o, i) =>
        i % 2 === 1 ? (
            <span className="search-highlight" key={i}>
                {o}
            </span>
        ) : (
            o
        ),
    )
}

const MainTreeItem = ({
    index,
    label,
    count,
    highlightText,
    selected,
    onClick,
    ...rest
}) => {
    const classes = useStyles()

    let labelToShow

    if (highlightText && highlightText.length) {
        labelToShow = highlight(label, highlightText)
    } else {
        labelToShow = label
    }

    return (
        <TreeItem
            classes={classes}
            {...rest}
            label={
                <Box display="flex" height="100%" alignItems="center">
                    <StyledRadio
                        checkedIcon={<Icon name="RadioFilled" />}
                        icon={<Icon name="Radio" />}
                        checked={selected ?? false}
                        onClick={onClick}
                    />
                    <Typography
                        variant="body2"
                        style={{
                            fontWeight: 'bold',
                            wordBreak: 'break-all',
                            paddingLeft: '12px',
                        }}
                    >
                        {labelToShow}
                    </Typography>
                    {count !== undefined && (
                        <Typography
                            variant="body2"
                            style={{ marginLeft: '5px', color: '#919193' }}
                        >
                            ({count})
                        </Typography>
                    )}
                </Box>
            }
        />
    )
}

export default MainTreeItem
