import React from 'react'
import { makeStyles, useTheme, MobileStepper } from '@material-ui/core'
import { KeyboardArrow } from 'packages/eid-ui'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
    root: {
        background: 'transparent',
        border: '0 !important',
        boxShadow: 'none !important',
    },
    dot: {
        opacity: 0.3,
        backgroundColor: '#8b909a',
        margin: '0 5px',
    },
    dotActive: {
        opacity: 1,
        backgroundColor: '#453fbb',
        width: '20px',
        borderRadius: '6px',
    },
})

function Stepper({ steps, children }) {
    const theme = useTheme()
    const classes = useStyles()

    const [activeStep, setActiveStep] = React.useState(0)

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const stepperSteps = (
        <MobileStepper
            variant="dots"
            steps={steps}
            position="static"
            activeStep={activeStep}
            classes={classes}
        />
    )

    const previousButton = (
        <KeyboardArrow
            color="#453fbb"
            direction={theme.direction === 'rtl' ? 'right' : 'left'}
            onClick={handleBack}
            style={{ display: activeStep === 0 ? 'none' : 'block' }}
        />
    )
    const nextButton = (
        <KeyboardArrow
            color="#453fbb"
            direction={theme.direction === 'rtl' ? 'left' : 'right'}
            onClick={handleNext}
            style={{ display: activeStep === steps - 1 ? 'none' : 'block' }}
        />
    )

    return (
        <>
            {children(
                stepperSteps,
                previousButton,
                nextButton,
                activeStep,
                handleNext,
                handleBack,
            )}
        </>
    )
}

const PaginationStepper = ({ children, countPerPage, data }) => {
    const totalSteps = Math.ceil(data.length / countPerPage)

    const paginate = (pageIndex) => {
        return data.slice(
            pageIndex * countPerPage,
            pageIndex * countPerPage + countPerPage,
        )
    }

    return (
        <Stepper steps={totalSteps}>
            {(stepperSteps, previousButton, nextButton, activeStep) =>
                children(
                    stepperSteps,
                    previousButton,
                    nextButton,
                    paginate(activeStep),
                )
            }
        </Stepper>
    )
}

Stepper.propTypes = {
    steps: PropTypes.number.isRequired,
    children: PropTypes.func.isRequired,
}

export { Stepper }
export { PaginationStepper }
