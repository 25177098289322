import React, { useState } from 'react'
import {
    withStyles,
    makeStyles,
    InputBase,
    InputLabel,
    FormControl,
} from '@material-ui/core'
import classNames from 'classnames'

const StyledInput = withStyles((theme) => ({
    root: {
        width: '100% !important',
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#ffffff !important',
        border: 'solid 1px #ebebed',

        fontSize: 16,
        padding: '10px 12px',
        '&:focus': {
            boxShadow: ` 0 2px 4px 0 rgba(48, 127, 193, 0.15)`,
            borderColor: '#307fc1',
            backgroundColor: theme.palette.common.white,
        },
        '&:-ms-input-placeholder': {
            opacity: `${1} !important`,
            color: '#b4b4b4 !important',
        },
    },

    focused: {
        backgroundColor: theme.palette.common.white,
    },

}))(InputBase)

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
    },
    margin: {},
    validationError: {
        '& input': {
            border: 'solid 1px red !important',
        },
        '& label': {
            color: 'red !important',
        },
    },
}))

const TextInput = ({
    label,
    placeholder,
    value,
    handleChange,
    validationError = null,
}) => {
    const classes = useStyles()
    const [showErrors, setShowErrors] = useState(false)
    return (
        <FormControl
            className={classNames(classes.root, {
                [classes.validationError]: showErrors && validationError,
            })}
        >
            {label && (
                <InputLabel
                    shrink
                    style={{
                        textTransform: 'uppercase',
                        color: '#b4b4b4',
                        paddingLeft: '15px',
                    }}
                >
                    {label}
                </InputLabel>
            )}
            <StyledInput
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                onBlur={() => {
                    if (!value) {
                        setShowErrors(true)
                    } else {
                        setShowErrors(false)
                    }
                }}
            />
        </FormControl>
    )
}

export default TextInput
