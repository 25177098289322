import { Fragment, useEffect } from 'react'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import { useHistory } from 'react-router'
import { useConfiguration } from 'appConfiguration/configurations'
import { AppContextProvider } from 'appContext'
import { Listing, IdentityDetails } from 'components'
import { OrgChart } from 'containers/OrgChart'
import { Loader } from 'packages/eid-ui'
import appConfig from 'config'

const WhitePages = () => {
    const history = useHistory()
    let { path, url } = useRouteMatch()
    const { dropDownOptions } = useConfiguration()

    const currentOption = dropDownOptions.find((o) => o.route === path)

    const RedirectToFirstTab = () => {
        useEffect(() => {
            if (currentOption?.tabbedOptions?.length > 0) {
                history.push(
                    `${currentOption.route}${currentOption.tabbedOptions[0].route}`,
                )
            } else {
                history.push('/forbidden')
            }
        }, [])

        return <Loader />
    }

    return (
        <Fragment>
            <Switch>
                <Route exact path={`${path}/`} component={RedirectToFirstTab} />
                <Route
                    exact
                    path={currentOption.route + '/orgChart/:personGuid'}
                    component={(props) => {
                        const { history, match } = props
                        const personGuid = match.params.personGuid

                        const validGuidRegex = new RegExp(
                            /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
                        )
                        if (!validGuidRegex.test(personGuid)) {
                            history.push(`${appConfig.APP_SUBPATH}/NotFound`)
                        }

                        return (
                            <AppContextProvider>
                                <OrgChart {...props} personGuid={personGuid} />
                            </AppContextProvider>
                        )
                    }}
                />

                {currentOption?.tabbedOptions.map((a) => (
                    <Route
                        key={a.name}
                        exact
                        path={currentOption.route + a.route}
                        component={(props) => (
                            <AppContextProvider>
                                <Listing {...a} {...props} />
                            </AppContextProvider>
                        )}
                    />
                ))}
                {currentOption?.tabbedOptions.map((a) => (
                    <Route
                        key={`${a.name}_details`}
                        exact
                        path={currentOption.route + `${a.route}/:personGuid`}
                        component={(props) => {
                            const { history, match } = props
                            const personGuid = match.params.personGuid

                            const validGuidRegex = new RegExp(
                                /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
                            )
                            if (!validGuidRegex.test(personGuid)) {
                                history.push(
                                    `${appConfig.APP_SUBPATH}/NotFound`,
                                )
                            }

                            return (
                                <AppContextProvider>
                                    <IdentityDetails
                                        {...props}
                                        personGuid={personGuid}
                                    />
                                </AppContextProvider>
                            )
                        }}
                    />
                ))}
            </Switch>
        </Fragment>
    )
}
export default WhitePages
