import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
        type: 'light',
        primary: {
            light: '#6dadff',
            main: '#2F7ECE',
            dark: '#00529c',
        },
        secondary: {
            light: '#ffe97d',
            main: '#ffb74d',
            dark: '#c88719',
        },
        background: {
            paper: '#ffffff',
            default: '#eef0f4',
        },
    },
    typography: {
        fontFamily: [
            'Rubik',
            'sans-serif',
            'Source Sans Pro',
            'Muli',
            'Roboto',
            'Helvetica',
            'Arial',
        ].join(','),
        useNextVariants: true,
        h2: {
            fontSize: '18px',
        },
        h5: {
            fontSize: '24px',
        },
        h6: {
            fontSize: '18px',
        },
        subtitle1: {
            fontSize: '10px',
            color: '#b4b4b4',
            fontWeight: 500,
        },
        body1: {
            fontSize: '16px',
            fontWeight: 500,
        },
        subtitle2: {
            fontSize: '10px',
            color: '#b4b4b4',
            fontWeight: 500,
        },
        body2: { fontSize: '14px' },
    },
    shape: {
        borderRadius: 4,
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '13px',
                color: '#ffffff',
                backgroundColor: '#307fc1',
            },
        },
    },
    props: {
        MuiTable: {
            style: {
                boxShadow: '0 5px 8px 0 rgba(0, 0, 0, 0.05)',

                border: 'solid 1px #efeff1',
            },
        },
        MuiPaper: {
            style: {
                boxShadow: '0 5px 8px 0 rgba(0, 0, 0, 0.05)',
                // border: 'solid 1px #efeff1', // be specific to wherever you want to apply this
            },
        },
        MuiButtonBase: {
            disableRipple: true,
        },
        MuiAppBar: {
            style: {
                boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
            },
        },
        MuiCard: {
            style: {
                // boxShadow: '0 5px 8px 0 rgba(0, 0, 0, 0.05)',
                boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.1)',
                borderRadius: '5px',
                // border: 'solid 1px #ebebed',
                border: 'none',
            },
        },
        MuiInputBase: {
            style: {
                fontSize: '12px',
            },
        },
        MuiFormControl: {
            style: {
                marginTop: '8px',
            },
        },
        MuiInputLabel: {
            style: {
                fontSize: '12px',
            },
        },
    },
    mixins: {
        toolbar: {
            minHeight: 111,
            '@media (min-width:0px) and (orientation: landscape)': {
                minHeight: 111,
            },
            '@media (min-width:960px)': {
                minHeight: 70,
            },
        },
        navBar: {
            // Custom mixin
            minHeight: 65,
            '@media (min-width:0px) and (orientation: landscape)': {
                minHeight: 65,
            },
            'media (min-width:600px)': {
                minHeight: 65,
            },
        },
        header: {
            // Custom mixin
            minHeight: 111,
            '@media (min-width:0px) and (orientation: landscape)': {
                minHeight: 111,
            },
            '@media (min-width:960px)': {
                minHeight: 70,
            },
        },
        headerTop: {
            // Custom mixin
            top: 111,
            '@media (min-width:0px) and (orientation: landscape)': {
                top: 111,
            },
            '@media (min-width:960px)': {
                top: 70,
            },
        },
    },
})

export default theme
