import React from 'react'
import { Icon, EidLogoIcon } from 'packages/eid-icons'
import { Header, Tooltip } from 'packages/eid-ui'
import { styled, Typography, Box } from '@material-ui/core'
import { useSwrApiGet } from 'packages/core'
import LanguageMenu from './LanguageMenu'
import AccountDropdown from './AccountDropdown'
import { useTranslation } from 'react-i18next'

const StyledBox = styled(Box)({
    '@media (max-width:1159px)': {
        display: 'none',
    },
})

const StyledChip = styled('div')({
    position: 'absolute',
    top: '11px',
    left: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ff6100 !important',
    color: '#ffffff',
    height: '13px',
    maxWidth: '48px',
    minWidth: '24px',
    transform: 'skew(-20deg)',
    borderRadius: '2px',
    '@media (max-width:1159px)': {
        left: '2px',
    },
})

const DesktopHeader = ({ handleSearch, config }) => {
    const { t } = useTranslation()
    const { data: environmentData } = useSwrApiGet(
        () =>
            config.showEnvironment ? '/api/Application/EnvironmentName' : null,
        {
            dedupingInterval: 3600000,
        },
    )

    return (
        <Header bgColor={config.bgColor}>
            <Header.IconLink
                logo
                href={config.showEidLogo ? '/' : undefined}
                rightDivider={config.showEidLogo}
            >
                {config.showEidLogo && <EidLogoIcon />}
            </Header.IconLink>
            <Header.IconLink logo href="/">
                {config.appLogo.type === 'icon' ? (
                    <Icon name={config.appLogo.value} />
                ) : (
                    <img
                        style={config.appLogo.style}
                        src={config.appLogo.value}
                        alt={config.appLogo.name}
                    />
                )}
            </Header.IconLink>
            <StyledBox
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                {config.appTitle.type === 'text' ? (
                    <Header.Title>{config.appTitle.value}</Header.Title>
                ) : (
                    <Header.IconLink logo href="/">
                        <img
                            style={config.appTitle.style}
                            src={config.appTitle.value}
                            alt={config.appTitle.name}
                        />
                    </Header.IconLink>
                )}
            </StyledBox>
            {config.showEnvironment && environmentData?.data && (
                <Box position="relative" width="32px">
                    <Tooltip title={environmentData.data}>
                        <StyledChip>
                            <Typography
                                noWrap
                                style={{
                                    fontSize: '8px',
                                    padding: '0px 1.5px',
                                }}
                            >
                                {environmentData.data}
                            </Typography>
                        </StyledChip>
                    </Tooltip>
                </Box>
            )}

            {config.search.show && handleSearch && (
                <div
                    style={{
                        height: '100%',
                        display: 'flex',
                        flex: 1,
                        minWidth: '140px',
                        alignItems: 'center',
                        marginLeft: '20px',
                        width: '700px',
                        paddingLeft: '10px',
                    }}
                >
                    <Header.Search
                        styleData={config.search.style}
                        placeholder={t('MyIdentity_Search')}
                        handleSearch={handleSearch}
                    />
                </div>
            )}
            <Header.RightSection>
                <Header.Item style={{ minWidth: '32px', padding: '0px 6px' }}>
                    <LanguageMenu anchorPosition={{
                        right: '-32px', top: '54px',
                    }}/>
                </Header.Item>
                <Header.Item
                    style={{ minWidth: '32px', padding: '0px 48px 0px 16px' }}
                >
                    <AccountDropdown />
                </Header.Item>
            </Header.RightSection>
        </Header>
    )
}
export default DesktopHeader
