import React from 'react'
import { Box, Link, Collapse, Typography, makeStyles } from '@material-ui/core'
import { CheckedIcon } from '../../eid-icons'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
    container: {
        paddingRight: '19px',
        marginBottom: '15px',
    },
    label: {
        fontSize: '14px',
        marginLeft: '10px',
        color: '#000000',
    },
    labelButton: {
        fontSize: '14px',
        textDecoration: 'underline',
    },
    gradient: (props) => ({
        '-webkit-mask-image':
            !props.open &&
            '-webkit-gradient(linear, left top, left bottom,  from(rgba(0,0,0,1)), to(rgba(255,255,255,0)))',
    }),
})

const CollapsibleList = ({ list, visibleItems, getLabel, showAllText }) => {
    const [open, setOpen] = React.useState(false)
    const classes = useStyles({ open })

    const handleClick = () => {
        setOpen(!open)
    }

    if (list.length < visibleItems) {
        return (
            <>
                <Box className={classes.container}>
                    {list.map((item, index) => (
                        <Box marginBottom="10px" key={index}>
                            <Box display="flex" alignItems="center">
                                <CheckedIcon />
                                <Typography className={classes.label}>
                                    {getLabel(item)}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </>
        )
    }

    return (
        <Box className={classes.container}>
            {list.slice(0, visibleItems - 1).map((item, index) => (
                <Box marginBottom="10px" key={index}>
                    <Box display="flex" alignItems="center">
                        <CheckedIcon />
                        <Typography className={classes.label}>
                            {getLabel(item)}
                        </Typography>
                    </Box>
                </Box>
            ))}
            <Box className={classes.gradient}>
                <Box marginBottom="10px">
                    <Box display="flex" alignItems="center">
                        <CheckedIcon />
                        <Typography className={classes.label}>
                            {getLabel(list[2])}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Collapse in={open} timeout="auto" unmountOnExit>
                {list.slice(3).map((item, index) => (
                    <Box marginBottom="10px" key={index}>
                        <Box display="flex" alignItems="center">
                            <CheckedIcon />
                            <Typography className={classes.label}>
                                {getLabel(item)}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Collapse>

            {!open && (
                <Box marginTop="15px">
                    <Link component="button" onClick={handleClick}>
                        <Typography className={classes.labelButton}>
                            {showAllText}
                        </Typography>
                    </Link>
                </Box>
            )}
        </Box>
    )
}

CollapsibleList.propTypes = {
    /**
     * List of items to render out in the list
     */
    list: PropTypes.array,

    /**
     * Number of items to show when collapsed
     */
    visibleItems: PropTypes.number,

    /**
     * Text to show as a prompt for "Show All Items"
     */
    showAllText: PropTypes.string,
}

CollapsibleList.defaultProps = {
    list: [],
    visibleItems: 3,
    showAllText: 'Show All',
}

export default CollapsibleList
