import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const TextFormatter = ({ value, maxTextWidth }) => {
    const { t } = useTranslation()
    const tooltipRef = useRef()
    const [disableTooltip, setDisableTooltip] = useState(true)

    useEffect(() => {
        if (tooltipRef.current) {
            if (tooltipRef.current.scrollWidth > maxTextWidth) {
                setDisableTooltip(false)
            }
        }
    }, [])

    const renderValue = (value, styles = {}) => {
        if (typeof value === 'boolean') {
            return (
                <span style={{ ...styles }}>
                    {typeof value === 'boolean' && value === true ? (
                        <>{t('MyIdentity_Yes')}</>
                    ) : (
                        <>{t('MyIdentity_No')}</>
                    )}
                </span>
            )
        } else {
            return (
                <span style={{ ...styles }}>
                    {typeof value !== 'boolean' && value ? (
                        value
                    ) : (
                        <span style={{ color: 'rgb(180, 180, 180)' }}>-</span>
                    )}
                </span>
            )
        }
    }

    return (
        <>
            <Tooltip
                disableHoverListener={disableTooltip}
                title={value ? value.toString() : ''}
                ref={tooltipRef}
            >
                {renderValue(value, {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    display: 'block',
                    width: maxTextWidth,
                })}
            </Tooltip>
        </>
    )
}

TextFormatter.propTypes = {
    value: PropTypes.any,
}

TextFormatter.defaultProps = {
    maxTextWidth: 90,
}

export default TextFormatter
