/* eslint-disable default-case, no-param-reassign */

const reducer = (state, { type, payload }) => {
    switch (type) {
        case 'SEARCH_IDENTITIES':
            return { ...state, searchKey: payload }
        case 'CLEAR_SEARCH_IDENTITIES':
            return { ...state, searchKey: '' }

        case 'SORT_IDENTITIES':
            return {
                ...state,
                sortBy: payload.sortBy,
                sortOrder: payload.sortOrder,
            }

        case 'SET_MULTIPLE_PROPS':
            return {
                ...state,
                ...payload,
            }

        case 'SET_PROP':
            return {
                ...state,
                [payload.key]: payload.value,
            }

        default:
            return state
    }
}

export default reducer
