import React, { useEffect, useState, useRef } from 'react'
import {
    Box,
    Modal,
    makeStyles,
    Typography,
    Link,
    Grid,
    Tooltip,
    Avatar,
    withStyles,
} from '@material-ui/core'
import { TextIconButtonV2 as TextIconButton } from 'components'
import { ReactComponent as CloseIconSvg } from 'icons/close-icon.svg'
import CustomButton from 'components/CustomButton'
import DefaultIconSvg from 'icons/default.svg'
import CropIconSvg from 'icons/crop.svg'
import CropIconSvgActive from 'icons/crop-active.svg'
import ImageEditor from './ImageEditor'
import appConfig from 'config'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    modal: {
        overflowY: 'hidden',
        position: 'absolute',
        maxHeight: '96vh',
        [theme.breakpoints.up('sm')]: {
            top: `${50}%`,
            left: `${50}%`,
            transform: `translate(-${50}%, -${50}%)`,
            width: 600,
        },

        [theme.breakpoints.up('md')]: {
            top: `${50}%`,
            left: `${50}%`,
            transform: `translate(-${50}%, -${50}%)`,
            width: 800,
        },

        [theme.breakpoints.down('xs')]: {
            maxWidth: 400,
            margin: '10px auto',
            left: 0,
            right: 0,
        },

        backgroundColor: '#f7f8fa',
        borderRadius: '8px',
        boxShadow: '0 0 13px 0 rgba(0, 0, 0, 0.15)',
        padding: '0',
        '&:focus': {
            outline: 'none',
        },
    },
    modalHeader: {
        display: 'flex',
        backgroundColor: '#fff',
        justifyContent: 'space-between',
        padding: '15px 20px ',
        height: '10%',
        minHeight: '55px',
        borderBottom: '1px solid #e2dddd',
    },
    modalFooter: {
        borderTop: '1px solid #e2dddd',
        display: 'flex',
        backgroundColor: '#fff',
        width: '100%',
        height: '16%',
        padding: '15px 20px ',
        overflowY: 'auto',
        minHeight: '67px',
        '@media (max-width:350px)': {
            padding: '15px 10px',
        },
    },
    leftActionGridItem: {
        textAlign: 'right',
    },
    openEditorLeftActionGridItem: {
        textAlign: 'start',
    },
    openEditorRightActionGridItem: {
        textAlign: 'end',
        '& button': {
            '&:not(:last-child)': {
                marginRight: '20px',
            },
        },
        '@media (max-width:600px)': {
            textAlign: 'center',
            '& button': {
                top: '-10px',
            },
        },
        '@media (max-width:380px)': {
            '& button': {
                '&:not(:last-child)': {
                    marginRight: '5px !important',
                },
            },
        },
    },
    headerTypo: {
        // fontWeight: 'Bold',
    },
    modalContent: {
        display: 'block',
        margin: '0',
        maxHeight: '65vh',
        overflowY: 'auto',
        backgroundColor: '#fbfbfd',
        textAlign: 'center',
    },
    editorModalContent: {
        display: 'flex',
        height: '60%',
        backgroundColor: 'black',
        margin: 0,
        textAlign: 'center',
        padding: 0,
        [theme.breakpoints.down('xs')]: {
            height: '300px',
        },
    },
    editorBox: {
        height: '14%',
        position: 'relative',
        top: '6px',
        padding: '5px 30px',
        '& button': {
            '&:not(:last-child)': {
                marginRight: '30px',
            },
        },
        '@media (max-width:600px)': {
            '& > div': {
                marginTop: '-5px',
            },
        },
    },
    sliderEditor: {
        position: 'absolute',
        bottom: '8px',
        height: '45px',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        '& span': {
            color: '#fff',
        },
        '@media (max-width:600px)': {
            bottom: '9px',
            height: '82px',
        },
    },
    deleteButton: {
        cursor: 'pointer',
        position: 'relative',
        display: 'block',
        marginBottom: '4px',
        top: '10px',
        '@media (max-width:600px)': {
            textAlign: 'center',
            top: 0,
        },
    },
    descTypo: {
        fontSize: '14px',
    },
    contentBox: {
        width: '100%',
        height: '100%',
        display: 'flex',
        margin: 0,
    },
    editorContentBox: {
        width: '100%',
        height: '100%',
        padding: 0,
        margin: 0,
        position: 'relative',
    },
    bigAvatar: {
        width: '159.8px',
        height: '160.7px',
    },
    editAvatar: {
        height: '100%',
        '@media (max-width:600px)': {
            height: '150px',
        },
    },
    sliderRail: {
        backgroundColor: '#307fc1',
    },
    imageEditorRoot: {
        height: '100%',
        position: 'absolute',
        top: 0,
    },
    imageEditorImage: {
        height: '100%',
    },
}))

const MessageTypography = withStyles({
    root: {
        fontSize: '14px',
        fontWeight: 'normal',
        padding: '0px 10px 20px',
    },
    body2: {
        color: '#ff0000',
    },
})(Typography)

const ProfilePictureModal = ({
    open,
    onClose,
    header,
    fields,
    loading,
    data,
    updateData,
    actions,
    response,
}) => {
    const classes = useStyles()
    const [state, setState] = useState({
        data: {},
        scale: 0,
        straighten: 0,
    })
    const childRef = useRef()
    const fileInput = useRef()

    useEffect(() => {
        //get data
        setState({
            data: data,
        })
    }, [data])

    const handleFileUpload = (e) => {
        const file = e.target.files[0]
        if (!file) return
        // allowed MIME types
        const mime_types = ['image/jpeg', 'image/png']

        // validate MIME
        if (mime_types.indexOf(file.type) === -1) {
            alert('Error : Incorrect file type')
            return
        }

        // validate file size
        if (file.size > 2 * 1024 * 1024) {
            alert('Error : Exceeded size 2MB')
            return
        }

        const _PREVIEW_URL = URL.createObjectURL(file)

        setState({
            ...state,
            avatarPreviewUrl: _PREVIEW_URL,
            openEditor: true,
            selectedFile: file,
            scale: 0,
            straighten: 0,
        })
    }

    const setEditedImageLocal = (imageURI) => {
        setState({
            ...state,
            openEditor: false,
            avatar_uri: imageURI,
        })
    }

    const uploadEditedImageToServer = () => {
        const { avatar_uri } = state
        const uploadParams = {
            type: 'image/png',
            dataURI: avatar_uri,
        }

        if (!avatar_uri) {
            console.log('Picture not updated')
        } else {
            console.log('==>Save Edited Image to Server : ')
            console.log('==>uploadParams :', uploadParams, '\n')
        }

        const avatar = state.avatar_uri ? state.avatar_uri : state.data.avatar

        updateData({
            ...state.data,
            avatar,
            avatarPreviewUrl: state.avatarPreviewUrl,
        })
    }

    const deletePicture = (e) => {
        setState({
            ...state,
            data: {
                ...data,
            },
            avatar_uri: null,
            openEditor: false,
        })
    }
    const { t } = useTranslation()
    return (
        <div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={open}
                onClose={onClose}
            >
                <Box className={classes.modal}>
                    <Box className={classes.modalHeader}>
                        <Typography variant="h6" className={classes.headerTypo}>
                            {state.openEditor
                                ? t('MyIdentity_EditPhoto')
                                : header}
                        </Typography>
                        <Box>
                            <Link component="button" onClick={onClose}>
                                <CloseIconSvg></CloseIconSvg>
                            </Link>
                        </Box>
                    </Box>

                    <Box
                        className={
                            state.openEditor
                                ? classes.editorModalContent
                                : classes.modalContent
                        }
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        m={1}
                        p={1}
                    >
                        {fields.map((item, index) => {
                            switch (item.type) {
                                case 'AvatarImage':
                                    return (
                                        <Box
                                            key={'edit-item-' + index}
                                            className={
                                                state.openEditor
                                                    ? classes.editorContentBox
                                                    : classes.contentBox
                                            }
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            m={1}
                                            p={1}
                                        >
                                            {!state.openEditor && (
                                                <Typography variant="h5">
                                                    {item.label}
                                                </Typography>
                                            )}

                                            {state.openEditor ? (
                                                <>
                                                    {
                                                        <ImageEditor
                                                            classes={{
                                                                root: classes.imageEditorRoot,
                                                                image: classes.imageEditorImage,
                                                            }}
                                                            srcFile={
                                                                state[
                                                                    'selectedFile'
                                                                ]
                                                            }
                                                            scale={
                                                                state[
                                                                    'scale'
                                                                ] || 0
                                                            }
                                                            straighten={
                                                                state[
                                                                    'straighten'
                                                                ] || 0
                                                            }
                                                            showState={true}
                                                            ref={childRef}
                                                        />
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    <br />
                                                    <br />
                                                    <Avatar
                                                        alt="Basic Info Avatar"
                                                        src={
                                                            state.avatar_uri ||
                                                            `${appConfig.BASE_EID_URL}${data.pathToImage}` ||
                                                            state.data.avatar
                                                        }
                                                        className={
                                                            classes.bigAvatar
                                                        }
                                                    />
                                                </>
                                            )}
                                            {!state.openEditor && (
                                                <>
                                                    <br />
                                                    <br />
                                                    <Typography
                                                        className={
                                                            classes.descTypo
                                                        }
                                                    >
                                                        {item.description || ''}
                                                    </Typography>
                                                    <MessageTypography
                                                        variant={
                                                            response &&
                                                            !response.success &&
                                                            'body2'
                                                        }
                                                    >
                                                        {response &&
                                                            response.message}
                                                    </MessageTypography>
                                                </>
                                            )}
                                        </Box>
                                    )
                                default:
                                    return <></>
                            }
                        })}
                    </Box>

                    {state.openEditor && (
                        <Box className={classes.editorBox}>
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={11}
                                    className={
                                        classes.openEditorLeftActionGridItem
                                    }
                                    style={{}}
                                >
                                    <TextIconButton
                                        text="Crop"
                                        iconActive={CropIconSvgActive}
                                        icon={CropIconSvg}
                                        active={true}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    className={
                                        classes.openEditorRightActionGridItem
                                    }
                                >
                                    <Tooltip
                                        enterTouchDelay={0}
                                        placement="top"
                                        title={t('MyIdentity_UndoAllChanges')}
                                    >
                                        <img
                                            src={DefaultIconSvg}
                                            alt="crop"
                                            style={{
                                                position: 'relative',
                                                top: '7px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                childRef.current.resetImage()
                                            }}
                                        />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Box>
                    )}

                    <Box
                        className={classes.modalFooter}
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                    >
                        {state.openEditor ? (
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={3}
                                    className={
                                        classes.openEditorLeftActionGridItem
                                    }
                                    style={{}}
                                >
                                    <span
                                        onClick={deletePicture}
                                        className={classes.deleteButton}
                                    >
                                        {t('MyIdentity_DeletePhoto')}
                                    </span>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={9}
                                    className={
                                        classes.openEditorRightActionGridItem
                                    }
                                >
                                    <input
                                        ref={fileInput}
                                        type="file"
                                        hidden
                                        onChange={handleFileUpload}
                                    />
                                    <CustomButton
                                        label={
                                            actions &&
                                            actions[0] &&
                                            actions[0].label
                                                ? actions[0].label
                                                : t('MyIdentity_SaveChanges')
                                        }
                                        onClick={() => {
                                            fileInput.current.click()
                                        }}
                                        width={150}
                                        m={5}
                                        type={'file'}
                                        active={
                                            actions &&
                                            actions[0] &&
                                            actions[0].active
                                        }
                                    ></CustomButton>
                                    <CustomButton
                                        label={
                                            actions &&
                                            actions[1] &&
                                            actions[1].label
                                                ? actions[1].label
                                                : t('MyIdentity_DiscardChanges')
                                        }
                                        width={150}
                                        p={5}
                                        onClick={() => {
                                            const editedImage =
                                                childRef.current.getEdittedImage()
                                            return setEditedImageLocal(
                                                editedImage,
                                            )
                                        }}
                                        active={
                                            actions &&
                                            actions[1] &&
                                            actions[1].active
                                        }
                                    ></CustomButton>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    xs={6}
                                    className={classes.leftActionGridItem}
                                >
                                    <input
                                        ref={fileInput}
                                        type="file"
                                        hidden
                                        onChange={handleFileUpload}
                                    />
                                    <CustomButton
                                        label={
                                            actions &&
                                            actions[0] &&
                                            actions[0].label
                                                ? actions[0].label
                                                : t('MyIdentity_ChangePhoto')
                                        }
                                        onClick={() => {
                                            fileInput.current.click()
                                        }}
                                        width={150}
                                        m={5}
                                        type={'file'}
                                        disabled={loading}
                                        active={
                                            actions &&
                                            actions[0] &&
                                            actions[0].active
                                        }
                                    ></CustomButton>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                    className={classes.rightActionGridItem}
                                >
                                    <CustomButton
                                        label={
                                            actions &&
                                            actions[1] &&
                                            actions[1].label
                                                ? actions[1].label
                                                : t('MyIdentity_SaveChanges')
                                        }
                                        width={150}
                                        p={5}
                                        disabled={loading}
                                        loading={loading}
                                        onClick={() => {
                                            uploadEditedImageToServer()
                                        }}
                                        active={
                                            actions &&
                                            actions[1] &&
                                            actions[1].active
                                        }
                                    ></CustomButton>
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default ProfilePictureModal
