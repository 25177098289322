import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDebounce, useAxios } from 'packages/core'
import { Autocomplete } from 'packages/eid-ui'
import optionComponents from './optionComponents'
import { useQuery } from 'react-query'

const useData = (url, data, enabled) => {
    const callApi = useAxios()
    return useQuery(
        ['SEARCH', url, ...Object.values(data)],
        () =>
            callApi({
                url,
                method: 'POST',
                data,
            }).then((data) => data.data),
        {
            enabled,
        },
    )
}

const EidAutocomplete = ({
    url,
    queryParams = {},
    dedupingInterval = 2000,
    debounceInterval = 300,
    onChange,
    shouldTriggerApiCall = true,
    supportsServerSideSearch = true,
    advancedSearchColumns = [],
    minCharactersReqForSearch = 3,
    excludeOptions = [],
    ...rest
}) => {
    const [callEndpoint, setCallEndpoint] = useState(false)
    const [options, setOptions] = useState([])

    const [searchKey, setSearchKey] = useState('')
    const debouncedValue = useDebounce(searchKey, debounceInterval)

    const apiData = { ...queryParams }
    if (supportsServerSideSearch && debouncedValue) {
        apiData.searchTerm = debouncedValue
    }

    if (advancedSearchColumns.length > 0 && debouncedValue) {
        apiData.advancedSearch = advancedSearchColumns.map((asc) => ({
            name: asc.name,
            type: asc.type,
            value: debouncedValue,
        }))
    }

    const { data } = useData(url, apiData, callEndpoint)

    useEffect(() => {
        if (data) {
            let options = data

            if (excludeOptions.length > 0) {
                options = data.filter((d) => !excludeOptions.includes(d.id))
            }
            setOptions(options)
        }
    }, [data])

    useEffect(() => {
        if (
            minCharactersReqForSearch > 0 &&
            Boolean(debouncedValue) &&
            debouncedValue.length >= minCharactersReqForSearch
        ) {
            setCallEndpoint(true)
        }
    }, [minCharactersReqForSearch, debouncedValue])

    return (
        <Autocomplete
            options={options}
            loading={callEndpoint && shouldTriggerApiCall && !data}
            onInputChange={(_, value) => setSearchKey(value)}
            onKeyDown={(e) => {
                if (e.keyCode === 13) {
                    setCallEndpoint(true)
                } else {
                    setCallEndpoint(false)
                }
            }}
            onChange={onChange}
            onOpen={() => {
                if (
                    minCharactersReqForSearch === 0 ||
                    (minCharactersReqForSearch > 0 &&
                        Boolean(debouncedValue) &&
                        debouncedValue.length >= minCharactersReqForSearch)
                ) {
                    setCallEndpoint(true)
                }
            }}
            onClose={() => setCallEndpoint(false)}
            {...rest}
        />
    )
}

EidAutocomplete.getOptionComponent = ({ type, ...rest }) => {
    const OptionComponent = optionComponents[type]

    return ({ option, optionState }) => (
        <OptionComponent option={option} optionState={optionState} {...rest} />
    )
}

EidAutocomplete.propTypes = {
    /**
     * URL where to fetch the data from.
     */
    url: PropTypes.string,

    /**
     * Callback when there is a change in the value.
     */
    onChange: PropTypes.func,

    /**
     * Request deduplication interval (ms) for requests with the same key.
     * Default is set to 2000.
     */
    dedupingInterval: PropTypes.number,

    /**
     * Request debounce interval (ms).
     * Default is set to 300.
     */
    debounceInterval: PropTypes.number,

    /**
     * If server-side search is supported or not.
     */
    supportsSearch: PropTypes.bool,
}

export default EidAutocomplete
