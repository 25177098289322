import React from 'react'
import { styled, Box, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Tooltip } from 'packages/eid-ui'
import { VerticalSquare } from 'images'
import clsx from 'clsx'

export const SwitchContainer = styled(({ assignedToMe, ...rest }) => (
    <Box {...rest} />
))(({ assignedToMe }) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: '13px',
    marginLeft: '4px',
    '& >p': {
        fontSize: '14px',
    },
    '& .Label': {
        color: assignedToMe ? '#8b909a' : '#000000',
    },
    '& .SubLabel': {
        color: assignedToMe ? '#000000' : '#8b909a',
    },
    '& .MuiFormControlLabel-root': {
        marginLeft: '10px',
    },
}))

const maxCharacters = 80

const tooltipStyles = {
    boxShadow: '0 2px 16px 0 rgba(0, 0, 0, 0.11)',
    padding: '8px',
    maxWidth: '500px',
    backgroundColor: '#307fc1',
}

const textStyles = {
    display: 'block',
    wordBreak: 'break-word',
    wordWrap: 'break-word',

    maxHeight: '200px',
    minWidth: '7.5rem',
    overflow: 'auto',
}

export const TextFormatter = ({ attribute, item }) => {
    const { t } = useTranslation()

    var getValue = (ci, attribute) => {
        let value = ci[attribute.name]
        if (typeof value === 'boolean') {
            return (
                <>
                    {typeof value === 'boolean' && value === true ? (
                        <>{t('MyIdentity_Yes')}</>
                    ) : (
                        <>{t('MyIdentity_No')}</>
                    )}
                </>
            )
        } else {
            return ci[attribute.name] ?? ''
        }
    }

    let wrappedCellContent

    const cellContent = (
        <Box style={textStyles}>
            {getValue(item, attribute).length > maxCharacters
                ? `${getValue(item, attribute).substring(0, maxCharacters)}...`
                : getValue(item, attribute)}
        </Box>
    )

    wrappedCellContent =
        getValue(item, attribute).length > maxCharacters ? (
            <Tooltip
                title={getValue(item, attribute)}
                color="#5D6870"
                fontColor="#ffffff"
                enterDelay={500}
                enterNextDelay={500}
                interactive={true}
                tooltipStyles={tooltipStyles}
                placement="bottom-start"
                arrow={false}
            >
                {cellContent}
            </Tooltip>
        ) : (
            cellContent
        )

    return <>{wrappedCellContent}</>
}

export const colors = {
    primary: '#307fc1',
    grey: '#7d7c7c',
}

export const styles = {
    headGrey: {
        color: '#7d7c7c !important',
    },
    tableHeaderContent: {
        display: 'inline-flex',
        alignItems: 'center',
        flexDirection: 'inherit',
        justifyContent: 'flex-start',
    },
    green: {
        color: '#4caf50',
    },
    black: {
        color: '#424242',
    },
    blue: {
        color: colors.primary,
    },
    boldBlue: {
        color: colors.primary,
        fontWeight: 'bold',
    },
    underline: {
        textDecoration: 'underline',
    },
    tableSortLabel: {
        color: '#7d7c7c !important',
        fill: '#7d7c7c !important',
        '& svg': {
            color: `${colors.grey} !important`,
        },
    },
    greyColor: {
        color: 'grey !important',
    },
    table: {
        backgroundColor: '#ffffff',
        '& tr:hover': {
            backgroundColor: '#f7f8fa',
        },
    },
    tableRow: {
        border: ' solid 1px #efeff1',
    },
    highlightedRow: {
        border: ' solid 1px  #307fc1',
        boxShadow: '4px 4px 15px 0 rgba(48, 127, 193, 0.15)',
    },

    tableCell: {
        padding: '15px 8px',
        fontSize: '13px',
        borderBottom: '0',
        '&:first-child': {
            padding: '15px 8px',
        },
    },
    tableCellHighlighted: {
        borderTop: ' solid 1px  #307fc1',
        padding: '15px 8x',
        fontSize: '13px',
        borderBottom: '0',
        '&:first-child': {
            padding: '15px 8px',
        },
    },
    hilightFont: {
        fontSize: '15px',
    },
    tableHeaderCell: {
        fontSize: '13px !important',
        backgroundColor: '#fbfbfd',
    },
    rowSelect: {
        borderBottom: '1px solid grey',
        borderRadius: '0',
    },
    tablePagination: {
        color: 'grey !important',
    },
    bigAvatar: {
        marginLeft: '4px',
        width: 50,
        height: 50,
    },
    loadButton: {
        color: colors.primary,
        width: '100%',
        textTransform: 'inherit',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    iconRegular: {
        width: '20px',
        height: '20px',
        objectFit: 'contain',
    },
    iconSpacing: {
        marginRight: '7px',
    },
}

export const useSortLabelClasses = makeStyles({
    root: {
        color: '#7d7c7c !important',
        '& svg': {
            color: `#D2D2D9 !important`,
            height: '14px',
            width: '14px',
            marginBottom: '2px !important',
        },
        '&:hover': {
            color: `${colors.primary} !important`,

            '& svg': {
                color: `${colors.primary} !important`,
            },
        },
    },

    active: {
        '& svg': {
            color: `${colors.primary} !important`,
        },
    },
    icon: {
        opacity: `${1} !important`,
    },
})
export const renderIcon = (heading, classes) => {
    return (
        heading.icons && (
            <span className={classes.blue}>
                {heading.icons.map((i, index) => (
                    <img
                        className={clsx(
                            classes.iconRegular,
                            classes.iconSpacing,
                        )}
                        key={`icon-image-${heading.name}-${index}`}
                        src={i}
                        alt={''}
                    />
                ))}
            </span>
        )
    )
}

export const config = {
    permanentDelegations: {
        name: 'PermanentDelegations',
        title: 'MyIdentity_PermanentDelegations',
        route: '/permanentDelegations',
        requireAccess: {
            page: 'MyIdentity-PermanentDelegations-Page',
        },
        order: 7,
        dataSource: {
            type: 'MS_DS',
            url: '/api/v1/Delegation/Delegator',
        },
        subHeaderButton: {
            name: 'CreateNewDelegateButton',
            requireAccess: {
                control:
                    'MyIdentity-PermanentDelegationsCreatePermanentDelegation-Control',
            },
        },
        showFilters: true,
        filters: [
            {
                dynamic: true,
                name: 'AdvancedSearch',
                title: 'MyIdentity_AdvancedSearch',
                icon: {
                    name: 'AdvancedSearch',
                    color: '#959598',
                },
                requireAccess: {
                    control:
                        'MyIdentity-PermanentDelegationsAdvancedSearch-Control',
                },

                type: 'advancedSearch',
                forms: {
                    colorCode: {
                        primary: '#2920ad',
                        secondary: '#ebeaf7',
                    },
                    filters: [
                        {
                            name: 'personName',
                            type: 'autocomplete',
                            label: 'MyIdentity_PersonName',
                            placeholder: 'MyIdentity_PersonName',
                            optionConfig: {
                                type: 'person',
                                labelProp: 'friendlyName',
                                imageUrlProp: 'imageUrl',
                            },
                            url: '/api/v1/Delegation/approvers',
                            queryParams: {
                                take: 10,
                            },
                            debounceInterval: 500,
                            dedupingInterval: 60000,
                            contextProp: 'advancedSearch.forms.delegatee',
                            queryParam: {
                                name: 'PersonNameId',
                                valueProp: 'id',
                            },
                        },
                        {
                            name: 'approvalStep',
                            type: 'autocomplete',
                            label: 'MyIdentity_ApprovalStep',
                            supportsServerSideSearch: false,
                            placeholder: 'MyIdentity_ApprovalStep',
                            contextProp: 'advancedSearch.forms.delegationType',
                            optionConfig: {
                                type: 'label',
                                labelProp: 'friendlyName',
                                imageUrlProp: 'imageUrl',
                            },
                            url: '/api/v1/Delegation/types',
                            queryParams: {
                                take: 10,
                            },
                            debounceInterval: 500,
                            dedupingInterval: 60000,
                            queryParam: {
                                name: 'Name',
                                type: 'string',
                            },
                        },
                        {
                            name: 'startDate',
                            type: 'startDate',
                            label: 'MyIdentity_StartDate',
                            title: 'MyIdentity_StartDate',
                            placeholder: 'MyIdentity_StartDate',
                            contextProp: 'advancedSearch.forms.startDate',
                            queryParam: {
                                name: 'Name',
                                type: 'string',
                            },
                        },
                        {
                            name: 'endDate',
                            type: 'endDate',
                            label: 'MyIdentity_EndDate',
                            title: 'MyIdentity_EndDate',
                            placeholder: 'MyIdentity_EndDate',
                            contextProp: 'advancedSearch.forms.endDate',
                            queryParam: {
                                name: 'Name',
                                type: 'string',
                            },
                        },
                    ],
                },
            },
        ],

        attributes: [
            {
                name: 'delegateePathToImage',
                hideValue: true,
                avatar: true,
                src: 'delegateePathToImage',
                label: '',
                align: 'center',
                sort: false,
                styles: {
                    width: '70px',
                },
            },
            {
                name: 'delegateeFriendlyName',
                label: 'MyIdentity_PersonName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: true,
                styles: {
                    fontSize: '15px',
                    color: '#307fc1',
                },
            },

            {
                name: 'approvalFlowStepFriendlyName',
                label: 'MyIdentity_ApprovalStep',
                icon: 'normal',
                align: 'left',
                sort: true,
                asc: true,
            },
            {
                name: 'startDateUtc',
                columnName: 'startDate',
                label: 'MyIdentity_StartDate',
                icon: VerticalSquare,
                align: 'left',
                hideValue: true,
                component: {
                    name: 'CellDateTime',
                },
                sort: true,
                asc: true,
                styles: { minWidth: '80px' },
            },
            {
                name: 'endDateUtc',
                columnName: 'endDate',
                label: 'MyIdentity_EndDate',
                icon: VerticalSquare,
                align: 'left',
                hideValue: true,
                component: {
                    name: 'CellDateTime',
                },
                sort: true,
                asc: true,
                styles: { minWidth: '80px' },
            },
            {
                name: 'activeOnlyIfOutOfOffice',
                requireAccess: {
                    control:
                        'MyIdentity-PermanentDelegationsOutOfOfficePermanentDelegation-Control',
                },
                label: 'MyIdentity_OnlyWhenOutOfOffice',
                align: 'left',
                sort: false,
                styles: { maxWidth: '100px' },
            },
            {
                name: 'editButton',
                requireAccess: {
                    control:
                        'MyIdentity-PermanentDelegationsEditPermanentDelegation-Control',
                },
                label: '',
                icon: '',
                align: 'center',
                sort: false,
                hideValue: true,
                component: {
                    name: 'EditDelegateButton',
                },
                styles: { width: '100px' },
            },
            {
                name: 'deleteButton',
                requireAccess: {
                    control:
                        'MyIdentity-PermanentDelegationsDeletePermanentDelegation-Control',
                },
                label: '',
                icon: '',
                align: 'center',
                sort: false,
                hideValue: true,
                component: {
                    name: 'DeleteDelegateButton',
                },
                styles: { width: '100px' },
            },
        ],
    },

    permanentAssignments: {
        name: 'PermanentAssignments',
        title: 'MyIdentity_PermanentAssignments',
        route: '/permanentAssignments',
        requireAccess: {
            page: 'MyIdentity-PermanentAssignments-Page',
        },
        order: 8,
        dataSource: {
            type: 'MS_DS',
            url: '/api/v1/Delegation/Delegatee',
        },
        showFilters: true,
        filters: [
            {
                dynamic: true,
                name: 'AdvancedSearch',
                title: 'MyIdentity_AdvancedSearch',
                icon: {
                    name: 'AdvancedSearch',
                    color: '#959598',
                },
                requireAccess: {
                    control:
                        'MyIdentity-PermanentAssignmentsAdvancedSearch-Control',
                },

                type: 'advancedSearch',
                forms: {
                    colorCode: {
                        primary: '#2920ad',
                        secondary: '#ebeaf7',
                    },
                    filters: [
                        {
                            name: 'personName',
                            type: 'autocomplete',
                            label: 'MyIdentity_PersonName',
                            placeholder: 'MyIdentity_PersonName',
                            optionConfig: {
                                type: 'person',
                                labelProp: 'friendlyName',
                                imageUrlProp: 'imageUrl',
                            },
                            url: '/api/v1/Delegation/approvers',
                            queryParams: {
                                take: 10,
                            },
                            debounceInterval: 500,
                            dedupingInterval: 60000,
                            contextProp: 'advancedSearch.forms.delegator',
                            queryParam: {
                                name: 'PersonNameId',
                                valueProp: 'id',
                            },
                        },
                        {
                            name: 'approvalStep',
                            type: 'autocomplete',
                            label: 'MyIdentity_ApprovalStep',
                            supportsServerSideSearch: false,
                            placeholder: 'MyIdentity_ApprovalStep',
                            contextProp: 'advancedSearch.forms.delegationType',
                            optionConfig: {
                                type: 'label',
                                labelProp: 'friendlyName',
                                imageUrlProp: 'imageUrl',
                            },
                            url: '/api/v1/Delegation/types',
                            queryParams: {
                                take: 10,
                            },
                            debounceInterval: 500,
                            dedupingInterval: 60000,
                            queryParam: {
                                name: 'Name',
                                type: 'string',
                            },
                        },
                        {
                            name: 'startDate',
                            type: 'startDate',
                            label: 'MyIdentity_StartDate',
                            title: 'MyIdentity_StartDate',
                            placeholder: 'MyIdentity_StartDate',
                            contextProp: 'advancedSearch.forms.startDate',
                            queryParam: {
                                name: 'Name',
                                type: 'string',
                            },
                        },
                        {
                            name: 'endDate',
                            type: 'endDate',
                            label: 'MyIdentity_EndDate',
                            title: 'MyIdentity_EndDate',
                            placeholder: 'MyIdentity_EndDate',
                            contextProp: 'advancedSearch.forms.endDate',
                            queryParam: {
                                name: 'Name',
                                type: 'string',
                            },
                        },
                    ],
                },
            },
        ],

        attributes: [
            {
                name: 'delegatorPathToImage',
                hideValue: true,
                avatar: true,
                src: 'delegatorPathToImage',
                label: '',
                align: 'center',
                sort: false,
                styles: {
                    width: '100px',
                },
            },
            {
                name: 'delegatorFriendlyName',
                label: 'MyIdentity_PersonName',
                icon: VerticalSquare,
                align: 'left',
                sort: true,
                asc: true,
                styles: { fontSize: '15px', color: '#307fc1' },
            },

            {
                name: 'approvalFlowStepFriendlyName',
                label: 'MyIdentity_ApprovalStep',
                icon: 'normal',
                align: 'left',
                sort: true,
                asc: true,
            },
            {
                name: 'startDateUtc',
                columnName: 'startDate',
                label: 'MyIdentity_StartDate',
                icon: VerticalSquare,
                align: 'left',
                hideValue: true,
                component: {
                    name: 'CellDateTime',
                },
                sort: true,
                asc: true,
            },
            {
                name: 'endDateUtc',
                columnName: 'endDate',
                label: 'MyIdentity_EndDate',
                icon: VerticalSquare,
                align: 'left',
                hideValue: true,
                component: {
                    name: 'CellDateTime',
                },
                sort: true,
                asc: true,
            },
            {
                name: 'activeOnlyIfOutOfOffice',
                label: 'MyIdentity_OnlyWhenOutOfOffice',
                align: 'left',
                sort: false,
            },
        ],
    },
}
