import { Box } from '@material-ui/core'
import './Pickers.css'

const NativeDateTimePicker = ({
    handleChange,
    children,
    value,
    minDate = '',
    maxDate = '',
    disabled = false,
}) => {
    return (
        <Box flex="1" className="textfield">
            {children}
            <input
                className="picker"
                type="datetime-local"
                value={value}
                onChange={(event) => {
                    handleChange(event.target.value)
                }}
                min={minDate}
                max={maxDate}
                disabled={disabled}
            ></input>
        </Box>
    )
}

export default NativeDateTimePicker
