import { useCallback } from 'react'
import { useQuery, useMutation } from 'react-query'
import config from 'config'
import { useAuthState, useAxios, encodeQueryParams } from 'packages/core'
import useApi from './useApi'
import { useApiGetWithInfiniteScroll } from 'packages/core'
import { useAppState } from 'appContext'
import { useTranslation } from 'react-i18next'
import { useNotification } from 'packages/eid-ui'

export const useListingData = (url) => {
    const [state] = useAppState()
    const { searchKey, sortBy, sortOrder } = state

    let queryParams = {}

    if (state['advancedSearch.forms.startDate']) {
        queryParams.startDate = state['advancedSearch.forms.startDate']
    }
    if (state['advancedSearch.forms.endDate']) {
        queryParams.endDate = state['advancedSearch.forms.endDate']
    }
    if (state['advancedSearch.forms.delegationType']) {
        queryParams.delegationTypeId =
            state['advancedSearch.forms.delegationType'].id
    }

    if (state['advancedSearch.forms.delegatee']) {
        queryParams.delegateeId = state['advancedSearch.forms.delegatee'].id
    }
    if (state['advancedSearch.forms.delegator']) {
        queryParams.delegatorId = state['advancedSearch.forms.delegator'].id
    }

    let queryString = encodeQueryParams({
        searchTerm: searchKey,
        sortBy: sortBy ? sortBy : '',
        descending: sortBy ? `${sortOrder === 'asc' ? false : true}` : '',
        startDate: queryParams.startDate?.toISOString(),
        endDate: queryParams.endDate?.toISOString(),
        delegateeId: queryParams?.delegateeId,
        delegatorId: queryParams?.delegatorId,
        delegationTypeId: queryParams?.delegationTypeId,
    })

    return useApiGetWithInfiniteScroll(url, queryString)
}
export const useDelegationTypes = () => {
    const callApi = useAxios()
    return useQuery(
        ['Delegation_Types'],

        () =>
            callApi({
                method: 'GET',
                url: `/api/v1/Delegation/types`,
            }).then((data) => data.data),
    )
}

export const useCreateDelegation = () => {
    const callApi = useAxios()

    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    const { revalidate: revalidateListing } = useListingData(
        '/api/v1/Delegation/Delegator',
    )

    return useMutation(
        (newDelegation) =>
            callApi({
                method: 'POST',
                url: '/api/v1/Delegation/Create',
                data: newDelegation,
            }),
        {
            onSuccess: (data) => {
                showSuccessMessage(
                    t('MyIdentity_DelegationCreatedSuccessfully'),
                )
                revalidateListing()
            },
            onError: () => {
                showWarningMessage(t('MIdentity_Error'))
            },
        },
    )
}

export const useEditDelegation = () => {
    const callApi = useAxios()
    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    const { revalidate: revalidateListing } = useListingData(
        '/api/v1/Delegation/Delegator',
    )

    return useMutation(
        (data) =>
            callApi({
                method: 'PUT',
                url: '/api/v1/Delegation/Edit',
                data,
            }),
        {
            onSuccess: (data) => {
                showSuccessMessage(t('MyIdentity_DelegationEditedSuccessfully'))
                revalidateListing()
            },
            onError: () => {
                showWarningMessage(t('MyIdentity_Error'))
            },
        },
    )
}

export const useDeleteDelegation = () => {
    const callApi = useAxios()

    const { showSuccessMessage, showWarningMessage } = useNotification()
    const { t } = useTranslation()

    const { revalidate: revalidateListing } = useListingData(
        '/api/v1/Delegation/Delegator',
    )

    return useMutation(
        (id) =>
            callApi({
                method: 'DELETE',
                url: `/api/v1/Delegation/Delete/${id}`,
            }),
        {
            onSuccess: (data) => {
                showSuccessMessage(
                    t('MyIdentity_DelegationDeletedSuccessfully'),
                )
                revalidateListing()
            },
            onError: () => {
                showWarningMessage(t('MyIdentity_Error'))
            },
        },
    )
}

export const useIdentity = (id) => {
    const callApi = useAxios()
    return useQuery(
        ['IDENTITY', id],
        () =>
            callApi({
                method: 'GET',
                url: `/api/v1/people/identities/${id}`,
            }),
        {
            enabled: Boolean(id),
        },
    )
}

export const useApiGetOld = (url) => {
    const [{ accessToken }] = useAuthState()
    return useApi(
        useCallback(
            () => ({
                baseURL: config.API_URL,
                method: 'GET',
                url,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }),
            [accessToken, url],
        ),
    )
}

export const useApiPost = (url) => {
    const [{ accessToken }] = useAuthState()
    return useApi(
        useCallback(
            (data) => ({
                baseURL: config.API_URL,
                method: 'POST',
                url,
                data,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }),
            [accessToken, url],
        ),
    )
}

export const useApiPut = (url, callback) => {
    const [{ accessToken }] = useAuthState()
    return useApi(
        useCallback(
            (data) => ({
                baseURL: config.API_URL,
                method: 'PUT',
                url,
                data,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }),
            [accessToken, url],
        ),
        callback,
    )
}

export * from './applicationSettingsHooks'
export * from './authorizationHooks'
export * from './localizationHooks'
export * from './personHooks'
export * from './notificationHooks'
