import React from 'react'
import { Button } from 'components'
import { useTranslation } from 'react-i18next'
import { useDeleteDelegation } from 'hooks'
import { WarningModal } from 'packages/eid-ui'
import { useModalState } from 'packages/core'

const DeleteDelegateButton = ({ data }) => {
    const { t } = useTranslation()

    const [modalOpen, openModal, closeModal] = useModalState()

    const [deleteDelegation, { isLoading: isDeleting }] = useDeleteDelegation()

    return (
        <>
            <WarningModal
                title={t('MyIdentity_Confirm')}
                description={t('MyIdentity_DeleteDelegationConfirmation', {
                    type: data.approvalFlowStepFriendlyName,
                    name: data.delegateeFriendlyName,
                })}
                yesLabel={t('MyIdentity_Yes')}
                noLabel={t('MyIdentity_No')}
                open={modalOpen}
                cancel={closeModal}
                proceeding={isDeleting}
                proceed={() => {
                    deleteDelegation(data.id)
                    closeModal()
                }}
            />

            <Button.Delete onClick={openModal}>
                {t('MyIdentity_Delete')}
            </Button.Delete>
        </>
    )
}
export default DeleteDelegateButton
