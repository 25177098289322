import React from 'react'
import { Icon } from '@material-ui/core'
import { formatDate } from 'packages/core/helpers'
import { Divider } from 'packages/eid-ui'
import { SlideShowIcon } from 'packages/eid-icons'
import GenericCard from './Cards/GenericCard'
import GenericCardHeader from './Cards/GenericCardHeader'
import CardField from './Cards/CardField'
import CardFieldGroup from './Cards/CardFieldGroup'
import GenericCardContent from './Cards/GenericCardContent'
import { useTranslation } from 'react-i18next'

const OrganizationCard = ({ organizationInfo, handleModalOpen, config }) => {
    const { t } = useTranslation()

    let date = ''
    if (organizationInfo.endOfContract) {
        date = new Date(organizationInfo.endOfContract)
        date = formatDate(date, 'MM/dd/yyyy')
    }
    return (
        <>
            <GenericCard
                onClick={config.editable ? handleModalOpen : undefined}
                editable={config.editable}
            >
                <GenericCardHeader
                    backgroundColor="#fbfbfd"
                    color="#b4b4b4"
                    icon={
                        <Icon style={{ margin: '0.75em' }}>
                            <SlideShowIcon />
                        </Icon>
                    }
                    heading={t('MyIdentity_Organization')}
                    height={60}
                />

                <GenericCardContent>
                    <CardFieldGroup>
                        <CardField
                            label={t('MyIdentity_Company')}
                            value={organizationInfo.company}
                            bold
                        />
                        <Divider vertical width={23} />
                        <CardField
                            label={t('MyIdentity_JobTitle')}
                            value={organizationInfo.jobTitle}
                        />
                    </CardFieldGroup>
                    <CardFieldGroup borderBelow>
                        <CardField
                            label={t('MyIdentity_Department')}
                            value={organizationInfo.department}
                        />

                        <Divider vertical width={23} />

                        <CardField
                            label={t('MyIdentity_Login')}
                            value={organizationInfo.login}
                            color={'#307fc1'}
                            wordWrap='anywhere'
                        />
                    </CardFieldGroup>

                    <CardFieldGroup>
                        <CardField
                            label={t('MyIdentity_Manager')}
                            value={organizationInfo.managerName}
                        />
                    </CardFieldGroup>
                </GenericCardContent>
            </GenericCard>
        </>
    )
}

export default OrganizationCard
