function parseJSON(response) {
    if (response.status === 204 || response.status === 205) {
        return null
    }
    return response.json()
}

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response
    }

    const error = new Error(response.statusText)
    error.response = response
    throw error
}

const defaultConfig = {
    headers: {
        'Content-Type': 'application/json',
    },
}

async function createRequest(url, method, options = {}) {
    const { data, token } = options
    if (token) {
        defaultConfig.headers['Authorization'] = `Bearer ${token}`
    }
    const config = {
        method,
        ...defaultConfig,
        body: data,
    }
    const fetchResult = await fetch(url, config)
    const response = await checkStatus(fetchResult)
    return parseJSON(response)
}
const Request = {}
Request.get = (url, options) => createRequest(url, 'GET', options)
Request.post = (url, data, options) =>
    createRequest(url, 'POST', { ...options, data })
Request.put = (url, data, options) =>
    createRequest(url, 'PUT', { ...options, data })
Request.delete = (url, data, options) =>
    createRequest(url, 'DELETE', { ...options, data }) // we don't want data here :(

export default Request
