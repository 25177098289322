import { styled } from '@material-ui/core'
import { ReactComponent as ClockSvg } from './clock.svg'

const ClockIcon = styled(ClockSvg)((props) => ({
    '& path': {
        fill: props.color,
    },
}))

ClockIcon.defaultProps = {
    color: '#000000',
}

export default ClockIcon
