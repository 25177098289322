import React from 'react'
import { EditDelegationForm, Button, Modal } from 'components'
import { useTranslation } from 'react-i18next'
import { useModalState } from 'packages/core'

const EditDelegateButton = ({ data }) => {
    const { t } = useTranslation()
    const [modalOpen, openModal, closeModal] = useModalState()

    return (
        <>
            {modalOpen && (
                <Modal
                    title={t('MyIdentity_TypeDelegationToPerson', {
                        type: data.approvalFlowStepFriendlyName,
                        name: data.delegateeFriendlyName,
                    })}
                    open={modalOpen}
                    onClose={closeModal}
                >
                    <EditDelegationForm
                        delegatee={data}
                        onSave={closeModal}
                        onCancel={closeModal}
                    />
                </Modal>
            )}
            <Button.Edit onClick={openModal}>
                {t('MyIdentity_Edit')}
            </Button.Edit>
        </>
    )
}

export default EditDelegateButton
