import { useEffect, useState } from 'react'
import { useQuery } from 'packages/core'
import { Loader } from 'packages/eid-ui'
import { useAnonymousTranslations } from 'hooks'
import { MaintenancePage } from 'packages/eid-ui'
import moment from 'moment'
import i18n from 'i18next'
import config from 'config'

const useLoadTranslations = () => {
    const query = useQuery()
    const debugLocalization = query.get('debugLocalization') === 'true'

    const { data, isLoading } = useAnonymousTranslations()

    const locale = config.FALLBACK_LOCALE

    useEffect(() => {
        i18n.changeLanguage(locale)
        moment.locale(locale)
    }, [locale])

    const [resourceBundle, setResourceBundle] = useState(null)
    useEffect(() => {
        if (data) {
            const localeData = data.reduce((current, r) => {
                if (debugLocalization) {
                    current[r.key] = r.key
                } else {
                    current[r.key] = r.translatedValue
                }

                return current
            }, {})

            i18n.addResourceBundle(locale, 'public', localeData)
            setResourceBundle(localeData)
        }
    }, [data, locale, debugLocalization])

    return { isReady: Boolean(data), isLoading }
}

const withRequiredLocaleData = (ChildComponent) => (props) => {
    const { isLoading, isReady } = useLoadTranslations()

    if (isLoading) {
        return <Loader />
    }

    if (!isReady) {
        return <MaintenancePage
            title='Under Maintenance '
            description='Making things better, please stand by.'
            linkText='Take me to the home page'
        />
    }

    return <ChildComponent {...props} />
}

export default withRequiredLocaleData
