import React, { forwardRef } from 'react'
import { MenuItem, makeStyles } from '@material-ui/core'
import { Icon } from 'packages/eid-icons'
import { Avatar } from 'packages/eid-ui'

const useStyles = makeStyles({
    root: {
        minWidth: '160px',
        color: '#534e52',
        fontSize: '13px',
        position: 'relative',

        '& img': {
            opacity: 0.5,
            filter: 'grayscale(100%) ',
            width: '24px',
            height: '24px',
        },
        '&:hover': {
            backgroundColor: 'transparent !important',

            '& img': {
                opacity: 1,
                filter: 'grayscale(0%)',
            },
        },
    },
    selected: {
        backgroundColor: 'transparent !important',
        '& img': {
            opacity: 1,
            filter: 'grayscale(0%) ',
        },
    },
})

const LanguageMenuItem = forwardRef(
    ({ displayName, avatarSrc, selected, onClick }, ref) => {
        const classes = useStyles()

        return (
            <MenuItem onClick={onClick} classes={classes} selected={selected}>
                <Avatar size="small" src={avatarSrc} />
                <span style={{ paddingLeft: '12px' }}>{displayName}</span>

                {selected && (
                    <Icon
                        name="Check"
                        width="8px"
                        height="6px"
                        color="#307fc1"
                        style={{
                            position: 'absolute',
                            right: '8px',
                            top: 'calc(50% - 3px)',
                        }}
                    />
                )}
            </MenuItem>
        )
    },
)

export default LanguageMenuItem
