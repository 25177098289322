import React from 'react'
import PropTypes from 'prop-types'
import { Box, Link, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles({
    root: (props) => ({
        ...props.style,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: props.style.fontSize ? props.style.fontSize : '12px',
        padding: props.style.padding ? props.style.padding : '5px 10px',
        border: props.style.border
            ? props.style.border
            : '1px solid transparent',
    }),

    icon: (props) => ({
        marginLeft: '10px',
        cursor: 'pointer',
    }),
})

const FilterChip = ({
    label,
    onClick,
    onClose,
    style,
    colorCodes,
    icon,
    ...rest
}) => {
    const classes = useStyles({ style, colorCodes, rest })
    return (
        <Link component="span" underline="none" onClick={onClick} title={label}>
            <Box className={classes.root}>
                <Typography
                    noWrap
                    overflow="hidden"
                    style={{
                        fontSize: style.fontSize ? style.fontSize : '12px',
                    }}
                >
                    {label}
                </Typography>

                {icon && (
                    <Link className={classes.icon} onClick={onClose}>
                        <Box display="flex" alignItems="center">
                            {icon}
                        </Box>
                    </Link>
                )}
            </Box>
        </Link>
    )
}

FilterChip.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
    closeIcon: PropTypes.bool,
    style: PropTypes.object,
}

FilterChip.defaultProps = {
    style: {},
}

export default FilterChip
