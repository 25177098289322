import React from 'react'
import { makeStyles, Backdrop } from '@material-ui/core'
import { Loader } from 'packages/eid-ui'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        backdropFilter: 'blur(8px)',
    },
}))

const FullScreenLoader = () => {
    const classes = useStyles()

    return (
        <Backdrop className={classes.backdrop} open={true}>
            <Loader />
        </Backdrop>
    )
}

export default FullScreenLoader
