import * as R from 'ramda'

const cleanUpOrgChart = R.pipe(
    //@ts-ignore
    R.groupBy(R.prop('id')),
    R.map(R.uniq),
    R.values,
    R.flatten,
    R.sortBy(R.prop('auxChartLevel')),
)

const verticalGap = 360
const horizontalGap = 356

export const transformOrgChart = (data: any) => {
    const orgChartData: any = cleanUpOrgChart(data)

    const flowElements: any[] = []

    for (let i = 0; i < orgChartData.length; i++) {
        const person = orgChartData[i]

        const node = {
            id: person.id,
            type: 'person',
            data: person,
        }

        flowElements.push(node)

        if (person.parentId) {
            const source = person.parentId
            const target = person.id
            flowElements.push({
                id: `${person.parentId}_${person.id}`,
                type: 'step',
                source,
                target,
            })
        }
    }

    calculatePositions(flowElements.filter((fe) => fe.type === 'person'))

    return flowElements
}

const calculatePositions = (nodes: any[]) => {
    for (let i = 0; i < nodes.length; i++) {
        const currentNode = nodes[i]

        if (currentNode.position) continue

        const nodesOnSameLevel = nodes.filter(
            (o: any) => o.data.auxChartLevel === currentNode.data.auxChartLevel,
        )

        let parentX

        if (currentNode.data.parentId) {
            const respectedParent = nodes.find(
                (n: any) => n.id === currentNode.data.parentId,
            )
            if (respectedParent) {
                parentX = respectedParent.position.x
            } else {
                parentX = 0
            }
        } else {
            parentX = 0
        }

        if (nodesOnSameLevel.length === 1) {
            currentNode.position = {
                x: calculateX(parentX, i, nodesOnSameLevel.length),
                y: calculateY(currentNode.data.auxChartLevel + 1),
            }
        } else {
            for (let j = 0; j < nodesOnSameLevel.length; j++) {
                if (nodesOnSameLevel[j].position) continue

                nodesOnSameLevel[j].position = {
                    x: calculateX(parentX, j, nodesOnSameLevel.length),
                    y: calculateY(currentNode.data.auxChartLevel + 1),
                }
            }
        }
    }
}

const calculateX = (
    parentX: number,
    index: number,
    noOfNodesOnSameLevel: number,
) => {
    if (noOfNodesOnSameLevel === 1) {
        return parentX
    }

    const oneSideNodes = parseInt((noOfNodesOnSameLevel / 2).toString())

    if (index === oneSideNodes) {
        return parentX
    } else if (index < oneSideNodes) {
        return parentX - horizontalGap * (index + 1)
    } else {
        return parentX + horizontalGap * (index - oneSideNodes)
    }
}

const calculateY = (level: number) => {
    if (level === 0) {
        return 25
    }
    return verticalGap * level
}
